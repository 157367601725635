import { Backdrop } from "@mui/material";
import Lottie from "lottie-react";
import { useState } from "react";
import { Box } from "../../common/components";
import AnimatedLogo from "./animatedJameoLogo.json";
import { AppLoadingContext } from "./AppLoadingContext";

interface Props {
  children: JSX.Element;
}

export const AppLoadingProvider = ({ children }: Props) => {
  const [isAppLoading, setIsAppLoading] = useState(false);

  return (
    <AppLoadingContext.Provider value={{ isAppLoading, setIsAppLoading }}>
      <Backdrop
        sx={(theme) => ({
          color: "#fff",
          zIndex: theme.zIndex.drawer + 1,
          background: "rgba(18, 33, 59, 0.20)",
          alignItems: "start",
          paddingTop: "314px",
        })}
        open={isAppLoading}
      >
        <Box
          sx={{
            top: "314px",
            "& svg": {
              maxHeight: "40px",
            },
          }}
        >
          <Lottie animationData={AnimatedLogo} loop={true} />
        </Box>
      </Backdrop>
      {children}
    </AppLoadingContext.Provider>
  );
};
