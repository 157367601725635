import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { useTranslation } from "react-i18next";
import { FullWidthTabs } from "./components/FullWidthTabs";

import DialogTitleWithCloseButton from "../DialogTitleWithCloseButton/DialogTitleWithCloseButton";

import { makeStyles } from "@mui/styles";
import { useSelector } from "../../common/hooks/useSelector";
import { DesignPricing, Size, SpecsProductPricing } from "../../types";
import { useThemeBreakpoints } from "../../utils/themeUtils";

interface Props {
  open: boolean;
  onClose: () => void;
  designId: string;
  productName: string;
  designPricing: DesignPricing;
  pricingDef: SpecsProductPricing;
  sizesOption: Size[];
}

export const CalculatePriceModal = ({
  open,
  onClose,
  designId,
  productName,
  designPricing,
  pricingDef,
  sizesOption,
}: Props) => {
  const { isMobile } = useThemeBreakpoints();
  const userInfo = useSelector((state) => state.userInfo);
  const handleClickClose = () => {
    onClose();
  };

  const useStyles = makeStyles((theme) => ({
    dialogPaper: {
      minHeight: isMobile ? "100vh" : "90vh",
      maxHeight: isMobile ? "100vh" : "90vh",
      minWidth: isMobile ? "100vw" : "800px",
      paddingTop: "32px",
      paddingBottom: "32px",
    },
  }));

  const classes = useStyles();

  const { t } = useTranslation();

  return (
    <Dialog
      classes={{ paper: classes.dialogPaper }}
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullScreen={isMobile}
    >
      <DialogTitleWithCloseButton
        id="customized-dialog-title"
        onClose={handleClickClose}
      >
        {t("pricing.calculate_price")}
      </DialogTitleWithCloseButton>

      <DialogContent sx={{ px: isMobile ? 0 : "40px !important" }}>
        {productName && designPricing && (
          <FullWidthTabs
            designId={designId}
            productName={productName}
            sizesOption={sizesOption}
            designPricing={designPricing}
            pricingDef={pricingDef}
            userInfo={userInfo}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};
