import { Typography } from "@mui/material";
import { t } from "i18next";
import { memo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Icon, MenuControlled } from "../../../common/components";
import { MenuOption } from "../../../common/components/Menu/Menu";
import { useSelector } from "../../../common/hooks/useSelector";
import { Styles, palette } from "../../../styles/theme";
import { useCurrency } from "../../../utils/Currencies";
import {
  isEnglish,
  isFrench,
  setEnglish,
  setFrench,
} from "../../../utils/LocaleUtil";

const styles: Styles = {
  container: {
    cursor: "pointer",
    py: "14px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "4px",
    px: 1,
    "&:hover": {
      background: palette.blue.paleEffect,
    },
  },
  menuItem: {
    display: "flex",
    justifyContent: "space-between",
    fontSize: 14,
    fontWeight: 400,
    lineHeight: "18px",
  },
};

const LocaleSelector = () => {
  const languageSelectorRef = useRef<HTMLDivElement>();
  const [isLanguageSelectorOpen, setIsLanguageSelectorOpen] = useState(false);
  const userInfos = useSelector((state) => state.userInfo);
  const { i18n } = useTranslation();

  const { setUSD, setCAD, isCAD, isUSD } = useCurrency();

  const options: MenuOption[] = [
    {
      labelKey: "profile.french",
      onClick: () => setFrench(userInfos),
      isChecked: isFrench,
    },
    {
      labelKey: "profile.english",
      onClick: () => setEnglish(userInfos),
      isChecked: isEnglish,
    },
    { isDivider: true },
    {
      labelKey: "general.currency.cad",
      onClick: setCAD,
      isChecked: isCAD,
    },
    {
      labelKey: "general.currency.usd",
      onClick: setUSD,
      isChecked: isUSD,
    },
  ];

  return (
    <>
      <Box
        ref={languageSelectorRef}
        onClick={() => setIsLanguageSelectorOpen((prev) => !prev)}
        sx={styles.container}
        data-test="localeSelector"
      >
        <Icon icon="language" size={22} />
        <Typography
          variant="body2"
          fontSize={12}
          color={palette.grey.nearBlack}
          lineHeight={"22px"}
        >
          {t(
            isEnglish(i18n)
              ? "profile.abbreviation_english"
              : "profile.abbreviation_french"
          )}
        </Typography>
        <Icon icon="circle" size={2} color={palette.grey.darkGrey} filled />
        <Typography
          variant="body2"
          fontSize={12}
          color={palette.grey.nearBlack}
          lineHeight={"22px"}
        >
          {t(isCAD() ? "profile.abbreviation_cad" : "profile.abbreviation_usd")}
        </Typography>
        <Icon icon={"expand_more"} size={16} color={palette.grey.nearBlack} />
      </Box>
      <MenuControlled
        options={options}
        isOpen={isLanguageSelectorOpen}
        onClose={() => setIsLanguageSelectorOpen(false)}
        target={languageSelectorRef.current}
      />
    </>
  );
};

export default memo(LocaleSelector);
