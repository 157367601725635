import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { object, string } from "yup";
import {
  Alert,
  Box,
  Button,
  CenteredColBox,
  Form,
  Input,
} from "../../../common/components";
import { useForm } from "../../../common/hooks/useForm";
import { spacing } from "../../../styles/theme";
import { testEmail } from "../../../utils/FormValidationsUtils";
import { OnboardingTitle } from "../Component/OnboardingTitle";

interface Props {
  onNextStep: (email: string) => void;
  email?: string;
}

const validationSchema = object({
  email: string().test(testEmail),
});

export const EmailOnboard = (props: Props) => {
  const { onNextStep, email } = props;
  const location = useLocation();
  const locationState = location.state;
  const { t } = useTranslation();
  const formik = useForm({
    initialValues: { email: email ?? "" },
    onSubmit: (values) => {
      onNextStep(values.email);
    },
    validationSchema: validationSchema,
  });

  // useEffect(() => {
  //   const logout = async () => {
  //     await AuthService.logOut();
  //   };
  //   authToken && logout();

  //   return () => {
  //     window.history.replaceState({}, "");
  //   };
  // }, []);

  return (
    <Box>
      <OnboardingTitle title={t("onboard.welcome")} />
      {locationState?.fromResetPassword && (
        <Alert
          message={t("forgot_password.update_password.success")}
          type="success"
          icon="check_circle"
          boxProps={{
            mb: { xs: spacing.regularMobile, sm: spacing.regularDesktop },
          }}
        />
      )}
      {locationState?.inviteLinkExpired && (
        <Alert
          message={t("invite.linkExpired")}
          icon="warning"
          type="error"
          boxProps={{
            mb: { xs: spacing.regularMobile, sm: spacing.regularDesktop },
          }}
        />
      )}
      <Form formik={formik}>
        <CenteredColBox
          gap={{ xs: spacing.regularMobile, sm: spacing.regularDesktop }}
          fullWidth
        >
          <Input
            autoComplete="username"
            id="email"
            name="email"
            label={t("general.email")}
            autoFocus
            fullWidth
            data-test="emailOnboard.email"
          />
          <Box display="flex" justifyContent="center" fullWidth>
            <Button
              type="submit"
              fullWidth
              data-test="emailOnboard.submitButton"
            >
              {t("sign_in.continue")}
            </Button>
          </Box>
        </CenteredColBox>
      </Form>
    </Box>
  );
};
