import { useCallback } from "react";
import { useStore } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useSelector } from "../common/hooks/useSelector";
import { CART_ACTION_ENUM } from "../common/reducers/cart";
import CartService from "../service/CartService";
import DesignStateService from "../service/DesignStateService";
import { CartItem, Currency, Size } from "../types";
import { ROUTES } from "./Constant";
import { useCurrency } from "./Currencies";
/**
 * Ability to add, edit or remove cart items
 * @param {*} props
 */
export const useCart = () => {
  const navigate = useNavigate();
  const { dispatch } = useStore();
  const { getCurrency } = useCurrency();
  const userInfos = useSelector((state) => state.userInfo);

  const fetchCartItemsAndUpdateStore = useCallback(
    async (currency: Currency = getCurrency()) => {
      dispatch({
        type: CART_ACTION_ENUM.UPDATE_LOADING,
        payload: { loading: true },
      });

      const pricingCartData =
        await DesignStateService.getDesignAndPricingCartData(currency);

      //Sorting so in UI it's always same
      pricingCartData.items = pricingCartData.items.sort((a, b) => {
        if (a.id > b.id) {
          return 1;
        } else if (a.id < b.id) {
          return -1;
        } else {
          return 0;
        }
      });

      dispatch({
        type: CART_ACTION_ENUM.UPDATE_CART,
        payload: { pricingCartData },
      });
    },
    [dispatch, getCurrency]
  );

  /**
   * Add to cart and redirect to cart
   */
  const addToCart = useCallback(
    async (
      designId: string,
      sizesOption: Size[],
      currency: Currency,
      productName: string,
      updateCartStore: boolean = true,
      redirectToCartPage: boolean = false
    ) => {
      const payload = {
        designId,
        sizes: sizesOption.map((size) => ({
          name: size.name,
          qty: 0,
        })),
      };

      await CartService.addToCart(payload);

      window.analytics.track("Product Added", {
        cart_id: userInfos.id,
        product_id: productName, // product ID from Specs
        sku: designId, // individual design ID
        name: productName, // Hoodie, Hoodie Zip, etc. from Specs, not the name given by the user
      });

      if (updateCartStore) {
        fetchCartItemsAndUpdateStore(currency);
      }

      if (redirectToCartPage) {
        navigate(ROUTES.CART);
      }
    },
    [fetchCartItemsAndUpdateStore, navigate, userInfos.id]
  );

  /**
   * Update to cart sizes qty
   */
  const updateCartSizes = useCallback(
    async (
      cartItems: CartItem[],
      currency: Currency,
      updateCartStore: boolean = true
    ) => {
      const payload = cartItems.map((item) => ({
        id: item.id,
        sizes: item.sizes,
      }));

      await CartService.updateCartSizes(payload);

      if (updateCartStore) {
        fetchCartItemsAndUpdateStore(currency);
      }
    },
    [fetchCartItemsAndUpdateStore]
  );

  /**
   * Remove an item from the cart
   */
  const removeFromCart = useCallback(
    async (
      designId: string,
      currency: Currency,
      productName: string,
      updateCartStore: boolean = true
    ) => {
      await CartService.removeCartItem(designId);

      window.analytics.track("Product Removed", {
        cart_id: userInfos.id,
        product_id: productName, // product ID from Specs
        sku: designId, // individual design ID
        name: productName,
      });

      if (updateCartStore) {
        await fetchCartItemsAndUpdateStore(currency);
      }
    },
    [fetchCartItemsAndUpdateStore, userInfos]
  );

  return {
    addToCart,
    updateCartSizes,
    removeFromCart,
    fetchCartItemsAndUpdateStore,
  };
};
