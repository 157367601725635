import { Typography } from "@mui/material";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import { Box, ClickableText } from "../../../common/components";
import { spacing } from "../../../styles/theme";
import { CartItemPricing, DesignPricing } from "../../../types";
import { useThemeBreakpoints } from "../../../utils/themeUtils";
import CartItemPricingInfo from "../Components/CartItemPricingInfo";

interface Props {
  deprecatedDesign: boolean;
  designName: string;
  pricing: CartItemPricing;
  productName: string;
  isBundle?: boolean;
  designId: string;
  designPricing: DesignPricing;
  onRemove: () => void;
}

const CartItemNameAndAction = ({
  deprecatedDesign,
  designName,
  pricing,
  productName,
  isBundle,
  designId,
  designPricing,
  onRemove,
}: Props) => {
  const { t } = useTranslation();

  const { isMobile } = useThemeBreakpoints();

  return (
    <Box
      display={"flex"}
      flexDirection={{ xs: "column", lg: "row" }}
      gap={{ xs: spacing.subtitleMobile, lg: 0 }}
    >
      <Typography data-test={designName} variant="h2" flexGrow={1}>
        {designName || t("design.untitled_design")}
      </Typography>
      {isMobile && !isBundle && (
        <CartItemPricingInfo
          forCartItem
          deprecatedDesign={deprecatedDesign}
          pricing={pricing}
          productName={productName}
          designId={designId}
          designPricing={designPricing}
        />
      )}
      <ClickableText
        variant="textSm"
        onClick={() => onRemove()}
        maxWidth={"fit-content"}
      >
        {t("cart.remove")}
      </ClickableText>
    </Box>
  );
};

export default memo(CartItemNameAndAction);
