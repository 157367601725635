import { useCurrency } from "../../../utils/Currencies";

import Button from "@mui/material/Button";
import { Trans, useTranslation } from "react-i18next";

import Typography from "@mui/material/Typography";

import { makeStyles } from "@mui/styles";

import Box from "@mui/material/Box";

import Container from "@mui/material/Container";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TableRow from "@mui/material/TableRow";
import { Currency, Size } from "../../../types";
import { useCart } from "../../../utils/CartHooks";
import { CalculatePriceModalTabDataType } from "./FullWidthTabs";
import { TabPanel } from "./TabPanel";

/**
 * Pricing Tab Panel
 * The text will depend on the props
 *
 */

interface Props {
  designId: string;
  productName: string;
  sizesOption: Size[];
  selectedIndex: number;
  index: number;
  value: number;
  tabData: CalculatePriceModalTabDataType;
}

export const PricingTabPanel = (props: Props) => {
  const {
    tabData,
    selectedIndex,
    index,
    designId,
    productName,
    sizesOption,
    ...other
  } = props;

  /**
   * Add to cart and redirect to cart
   */
  const { addToCart } = useCart();
  const onAddToCart = async (currency: Currency) => {
    addToCart(designId, sizesOption, currency, productName, undefined, true);
  };

  const useStyles = makeStyles({
    table: {},
    rowcell: {
      borderBottom: "none",
    },
    footercell: {
      fontSize: "1.0rem",
      fontWeight: "bold",
      color: "black",
    },
    list: {
      listStyleType: "disc",
    },
    bottomText: {
      fontStyle: "italic",
      textAlign: "left",
    },
    addToCartButton: {
      marginTop: 10,
      marginBottom: 10,
    },
    creditsBox: {
      minHeight: "25vh",
      maxHeight: "25vh",
      overflow: "auto",
      paddingTop: "16px",
      paddingBottom: "16px",
    },
    currencyLink: {
      cursor: "pointer",
      color: "#3e6bad",
      fontSize: "0.75rem",
      textDecoration: "underline",
    },
  });

  const { t } = useTranslation();

  const classes = useStyles();

  const {
    FullCurrencyFormatter,
    NarrowCurrencyFormatter,
    getCurrencySymbol,
    isCAD,
    isUSD,
    setUSD,
    setCAD,
    getCurrency,
  } = useCurrency();

  const setUSDCurrency = () => {
    setUSD();
  };

  const setCADCurrency = () => {
    setCAD();
  };

  return (
    <TabPanel selectedIndex={selectedIndex} index={index} {...other}>
      <TableContainer component={Container}>
        <Table className={classes.table} aria-label="simple table">
          <TableBody>
            <TableRow key="item">
              <TableCell className={classes.rowcell} align="left">
                {tabData.name}
              </TableCell>
              <TableCell className={classes.rowcell} align="right">
                {NarrowCurrencyFormatter?.format(tabData.unit_price)}
              </TableCell>
            </TableRow>

            <TableRow key="customization">
              <TableCell className={classes.rowcell} align="left">
                {t("pricing.customization")}
              </TableCell>
              <TableCell className={classes.rowcell} align="right">
                {NarrowCurrencyFormatter?.format(
                  tabData.customizationTotalPrice
                )}
              </TableCell>
            </TableRow>
          </TableBody>
          <TableFooter>
            <TableRow key="total">
              <TableCell align="left" className={classes.footercell}>
                <span>{t("pricing.total")}</span>
                <span>{" (" + getCurrencySymbol() + ") "}</span>
                {isUSD() && (
                  <span
                    className={classes.currencyLink}
                    onClick={setCADCurrency}
                  >
                    {t("pricing.change_currency_cad")}
                  </span>
                )}
                {isCAD() && (
                  <span
                    className={classes.currencyLink}
                    onClick={setUSDCurrency}
                  >
                    {t("pricing.change_currency_usd")}
                  </span>
                )}
              </TableCell>
              <TableCell align="right" className={classes.footercell}>
                {NarrowCurrencyFormatter?.format(tabData.total)}
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      <Box border={1} p={4} className={classes.creditsBox}>
        <Typography paragraph>
          {tabData.freeCreditsOfDesign > 100 ? (
            <Trans i18nKey="pricing.credits_unlimited"></Trans>
          ) : (
            <Trans i18nKey="pricing.credits_general_info">
              {/*TODO: make this icon a real span with role img with aria-label*/}
              This item comes with ✨{" "}
              {{ creditsFreeCount: tabData.freeCreditsOfDesign }} free credits.
              Extra credits are{" "}
              {{
                creditPrice: FullCurrencyFormatter?.format(
                  tabData.singleCreditPrice
                ),
              }}{" "}
              each.{" "}
              <a
                href="https://www.jameo.com/faq"
                target="_blank"
                rel="noopener noreferrer"
              >
                credits list
              </a>
              .
            </Trans>
          )}
        </Typography>
        <Typography paragraph>
          {t("pricing.credits_used_design", {
            creditsUsed: tabData.creditsUsedInDesign,
          })}
          {/*TODO Make the translation plurial and singular check https://react.i18next.com/latest/trans-component#using-with-react-components*/}
        </Typography>

        <ul className={classes.list}>
          {tabData.customizationsListWithCredits.map((item, i) => (
            <li key={`item-${item.id}-${i}`}>
              {t("pricing.credits_used_feature", {
                customizationName: item.displayLabels
                  .map((d, i) => {
                    //Bad hack here, aligned with specs pricing displayLabels for decorations, but it's because the name of a decoration can be changed by a user and shoudn't be translated
                    if (item.type === "ARTWORK") {
                      if (i === 2) {
                        return t(`positions.${d}`);
                      } else if (i > 0) {
                        return `${d}`;
                      } else {
                        return t(`specs.decoration_types.${d}.title`);
                      }
                    } else {
                      if (item.type === "First color") {
                        return (
                          t("pricing.first_color") +
                          " (" +
                          t(`colors.${d}`) +
                          ") " +
                          t("pricing.in_fabric") +
                          " " +
                          t(`fabric.${item.fabric}`)
                        );
                      } else if (item.type === "Extra color") {
                        return (
                          t("pricing.extra_color") +
                          " (" +
                          t(`colors.${d}`) +
                          ") " +
                          t("pricing.in_fabric") +
                          " " +
                          t(`fabric.${item.fabric}`)
                        );
                      }
                    }
                    return t(`specs.${productName}.${d}`);
                  })
                  .join(" - "),
                creditsCount: item.credits,
              })}
            </li>
          ))}
        </ul>
      </Box>

      <Button
        onClick={() => onAddToCart(getCurrency())}
        className={classes.addToCartButton}
        fullWidth
      >
        {t("pricing.add_to_cart")}
      </Button>

      <Typography paragraph className={classes.bottomText}>
        {t("pricing.change_later")}
      </Typography>
    </TabPanel>
  );
};
