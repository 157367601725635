import { useMemo } from "react";

import React from "react";
import { generatePath } from "react-router-dom";
import { useSelector } from "../../../common/hooks/useSelector";
import { ROUTES } from "../../../utils/Constant";
import { sortByUpdatedDate } from "../../../utils/SortingUtils";
import { TeamProject } from "./TeamProject/TeamProject";

//props here contains RBAC info like canAddProject
interface Props {
  canAddProject: boolean;
}

export const TeamProjects = (props: Props) => {
  const projectsState = useSelector((state) => state.projects);
  const projects = useMemo(() => {
    return projectsState?.allProjects;
  }, [projectsState.allProjects]);

  const teamsState = useSelector((state) => state.myTeams);

  const teams = useMemo(() => {
    return teamsState?.teams;
  }, [teamsState]);

  const teamProjects = useMemo(() => {
    return teams
      ?.map((team) => ({
        key: team.id,
        id: team.id,
        name: team.name,
        updatedAt: team.updated_at,
        projects: projects
          .filter((project) => project.team_id === team.id)
          .sort(sortByUpdatedDate),
        teamUrl: generatePath(ROUTES.TEAM_PROJECTS, {
          teamId: team.id,
        }),
        url: (projectId: string) => generatePath(ROUTES.DESIGNS, { projectId }),
      }))
      .sort(sortByUpdatedDate);
  }, [projects, teams]);

  return (
    <React.Fragment>
      {teamProjects?.map((team) => (
        <TeamProject {...props} {...team} key={team.key} />
      ))}
    </React.Fragment>
  );
};
