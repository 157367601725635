import { Typography } from "@mui/material";
import { memo, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Box } from "../../../common/components";
import Message from "../../../components/Message/Message";
import config from "../../../config";
import { palette, spacing, Styles } from "../../../styles/theme";
import { CartItem } from "../../../types";
import { useThemeBreakpoints } from "../../../utils/themeUtils";
import CartItemPricingInfo from "../Components/CartItemPricingInfo";
import CartItemSizeSelectorContent from "./CartItemSizeSelectorContent";

const styles: Styles = {
  container: {
    backgroundColor: "white",
    border: `1px solid ${palette.grey.lightGrey}`,
    borderRadius: "8px",
  },
  bundleHeader: {
    p: spacing.groupingMobile,
    borderRadius: "4px 4px 0 0",
    background: palette.grey.lightBackground,
  },
};

interface Props {
  item: CartItem;
  skipBundle?: boolean;
  onChange: () => void;
  onRemove: (id: string, productName: string) => void;
}

const CartItemSizeSelector = (props: Props) => {
  const { t } = useTranslation();

  const { isMobile } = useThemeBreakpoints();

  const { onChange, onRemove, item } = props;

  const isBundle = !!item.bundle?.length;

  const deprecatedDesign = useMemo(() => {
    return (
      (item.deprecatedDesign && config.blockPaymentWhenDesignIsDeprecated) ||
      false
    );
  }, [item.deprecatedDesign]);

  //Bundle desktop
  if (!isMobile && isBundle) {
    return (
      <Box
        display={"flex"}
        gap={spacing.mediumDesktop}
        flexDirection={"column"}
      >
        {item.bundle?.map((item, index) => (
          <CartItemSizeSelector
            key={`${item.id}-${index}`}
            item={item}
            onChange={onChange}
            onRemove={onRemove}
            skipBundle
          />
        ))}
      </Box>
    );
  }

  return (
    <Box sx={styles.container}>
      {deprecatedDesign && (
        <Message
          message={t("generator.warnings.styleOrColorNoLongerAvailable")}
          messageIcon="warning"
          fullWidth={false}
        />
      )}
      {isMobile && isBundle && (
        <Box sx={styles.bundleHeader}>
          <Typography variant="textRegularBold">{item.designName}</Typography>
        </Box>
      )}
      {isMobile && isBundle && (
        <Box
          padding={{ xs: spacing.wrapperMobile, lg: spacing.wrapperDesktop }}
          mb={spacing.regularMobile}
        >
          <CartItemPricingInfo
            deprecatedDesign={deprecatedDesign}
            pricing={item.pricing}
            productName={item.productName}
            designId={item.designId}
            designPricing={item.designState.customizationPricing}
            forCartItem
          />
        </Box>
      )}
      {/* //Bundle mobile */}
      {isBundle ? (
        <>
          {item.bundle?.map((item, index) => (
            <CartItemSizeSelectorContent
              key={`${item.id}-${index}`}
              item={item}
              onChange={onChange}
              onRemove={onRemove}
              isBundle
            />
          ))}
        </>
      ) : (
        <CartItemSizeSelectorContent
          item={item}
          onChange={onChange}
          onRemove={onRemove}
        />
      )}
    </Box>
  );
};

export default memo(CartItemSizeSelector);
