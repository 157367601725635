import MaterialToolbar from "@mui/material/Toolbar";
import React, { memo, useContext } from "react";
import { JameoLogo } from "../JameoLogo";
import "./dashboardToolbar.scss";

import { Box, Icon } from "../../common/components";
import { AppToolbarContext } from "../../context/AppToolbar";
import { Styles, palette, spacing } from "../../styles/theme";
import { useGlobalLoading } from "../../utils/ContextHooks";
import { useThemeBreakpoints } from "../../utils/themeUtils";
import DashboardToolbarContentSkeleton from "./AppToolbarContentSkeleton";
import { Cart } from "./Cart";
import { LocaleSelector } from "./LocaleSelector";
import ProfileMenu from "./ProfileMenu/ProfileMenu";

export const DashboardToolbarDesktopHeight = "48px";
export const DashboardToolbarMobileHeight = "48px";

const styles: Styles = {
  iconButtonRoot: {
    color: "black !important",
    paddingTop: "0px !important",
    paddingBottom: "0px !important",
    paddingLeft: "2px !important",
    paddingRight: "2px !important",

    "&:focus": {
      outline: 0,
    },

    "&:disabled": {
      color: "black !important",
      opacity: "0.26 !important",
      cursor: "default !important",
    },
  },

  leftSection: {
    display: "flex",
    alignItems: "center",
    "& svg": {
      maxWidth: { xs: "60px", lg: "83px" },
    },
  },

  rightSection: {
    display: "flex",
    alignItems: "center",
    height: "100%",
    "&>div": {
      height: "100%",
      cursor: "pointer",
      "&:hover": {
        background: palette.blue.paleEffect,
      },
    },
  },
  skeleton: {
    width: 100,
  },
};

export const AppToolbar = () => {
  const { toggleNavigationDrawerOpen } = useContext(AppToolbarContext);
  const { isGloballyLoading } = useGlobalLoading();
  const { isMobile } = useThemeBreakpoints();

  return (
    <React.Fragment>
      <MaterialToolbar
        className="app-toolbar"
        disableGutters
        sx={{
          pl: { xs: spacing.mediumMobile, lg: "20px" },
          pr: { xs: spacing.mediumMobile, lg: spacing.mediumDesktop },
          borderBottom: !isMobile
            ? `1px solid ${palette.grey.lightGrey}`
            : undefined,
          userSelect: "none",
        }}
      >
        {isGloballyLoading ? (
          <DashboardToolbarContentSkeleton />
        ) : (
          <>
            {isMobile && (
              <Icon
                icon="menu"
                size={24}
                color={palette.grey.nearBlack}
                onClick={toggleNavigationDrawerOpen}
                data-test="mobileLeftNavigation.toggle"
              />
            )}
            <Box sx={styles.leftSection}>
              <JameoLogo />
            </Box>

            <Box className={"navbar-right"} sx={styles.rightSection}>
              {!isMobile && <LocaleSelector />}

              <Cart />

              {!isMobile && <ProfileMenu />}
            </Box>
          </>
        )}
      </MaterialToolbar>
    </React.Fragment>
  );
};
export default memo(AppToolbar);
