import { memo, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useOutlet } from "react-router-dom";
import { Form } from "../../common/components";
import { useForm } from "../../common/hooks/useForm";
import { usePageTitle } from "../../common/hooks/usePageTitle";
import { useSelector } from "../../common/hooks/useSelector";
import { SpecsContext } from "../../context";
import { AddressFormType } from "../../types";
import { useCart } from "../../utils/CartHooks";
import { useGlobalLoading } from "../../utils/ContextHooks";
import { useCurrency } from "../../utils/Currencies";
import CartPreview from "./CartPreview/CartPreview";
import { addressInitialValue, CartSchema } from "./CartSchema";
import { CheckoutTemplate } from "./CheckoutTemplate";
import EmptyCart from "./EmptyCart";
import { CheckoutTemplateSkeleton } from "./Skeleton/CheckoutTemplateSkeleton";

// !!! IMPORTANT IF YOU WANT TO BRING BACK STRIPE !!!
// FIXME: See commit e71a4964daa11de3ec441d308b396182da198f6a to see how the
//  stripe checkout was managed before being removed.

export type CheckoutForm = {
  shippingAddress: AddressFormType;
  billingAddress: AddressFormType;
  sameAsShippingAddress: boolean;
  termsAndConditionsAccepted: boolean;
  finalDesignValidated: boolean;
};

const Cart = () => {
  const formik = useForm({
    initialValues: {
      shippingAddress: addressInitialValue,
      billingAddress: addressInitialValue,
      sameAsShippingAddress: true,
      termsAndConditionsAccepted: false,
      finalDesignValidated: false,
    },
    onSubmit: () => undefined,
    validationSchema: CartSchema,
    validateOnChange: true,
    validateOnMount: true,
  });
  const { t } = useTranslation();
  const outlet = useOutlet();
  const { loading: specsLoading } = useContext(SpecsContext);
  usePageTitle(t("pages.cart.title"));

  const { getCurrency } = useCurrency();
  const { isGloballyLoading } = useGlobalLoading();

  const { fetchCartItemsAndUpdateStore } = useCart();
  const { pricingCartData, loading: cartLoading } = useSelector(
    (state) => state.cart
  );

  //At load, fetch the cart to get the latest collected sizes information
  useEffect(() => {
    const currency = getCurrency();
    if (currency) {
      fetchCartItemsAndUpdateStore(getCurrency()); //Because deleting a Project and it's designs can impact the cart
    }
  }, [fetchCartItemsAndUpdateStore, getCurrency]);

  if (!outlet) {
    return null;
  }

  if (
    (!pricingCartData.items.length && cartLoading) ||
    specsLoading ||
    isGloballyLoading
  ) {
    return <CheckoutTemplateSkeleton />;
  }

  if (!pricingCartData.items.length) {
    return <EmptyCart />;
  }

  return (
    <Form formik={formik} formProps={{ style: { width: "100%" } }}>
      <CheckoutTemplate leftPanel={outlet} rightPanel={<CartPreview />} />
    </Form>
  );
};

export default memo(Cart);
