import { createContext } from "react";

type AppToolbarContextType = {
  isNavigationDrawerOpen: boolean;
  toggleNavigationDrawerOpen?: () => void;
};

export const AppToolbarContext = createContext<AppToolbarContextType>({
  isNavigationDrawerOpen: false,
  toggleNavigationDrawerOpen: undefined,
});
