import { Typography } from "@mui/material";
import parsePhoneNumber from "libphonenumber-js";
import { useTranslation } from "react-i18next";
import { FlexColBox } from "../../common/components";
import { palette, spacing } from "../../styles/theme";
import { AddressType } from "../../types";

interface Props {
  address: AddressType;
  title?: string;
}

const AddressBox = ({ address, title }: Props) => {
  const { t } = useTranslation();

  let formattedPhone = address.phone;
  if (address.phone) {
    const parsedPhoneNumber = parsePhoneNumber(
      address.phone,
      address?.address?.country || "CA"
    );

    if (parsedPhoneNumber) {
      formattedPhone = parsedPhoneNumber.formatNational();
    }
  }

  return (
    <FlexColBox gap={{ xs: spacing.smallMobile, lg: spacing.smallDesktop }}>
      {title && (
        <Typography variant="textSm" color={palette.grey.primary}>
          {title}
        </Typography>
      )}
      {address && (
        <FlexColBox gap={{ xs: spacing.xsMobile, lg: spacing.xsDesktop }}>
          {[
            address.organization,
            address.name,
            address.address.line1,
            address.address.line2,
          ]
            .filter((x) => !!x)
            .map((x, index) => (
              <Typography key={`${x}-${index}`} variant="textRegular">
                {x}
              </Typography>
            ))}
          <Typography variant="textRegular">
            {[
              address.address.city,
              address.address.state,
              address.address.postal_code,
            ]
              .filter((x) => !!x)
              .join(", ")}
          </Typography>
          <Typography variant="textRegular">
            {t(`countryCodes.${address.address.country}`)}
          </Typography>
          {[formattedPhone, address.email]
            .filter((x) => !!x)
            .map((x, index) => (
              <Typography key={`${x}-${index}`} variant="textRegular">
                {x}
              </Typography>
            ))}
        </FlexColBox>
      )}
    </FlexColBox>
  );
};

export default AddressBox;
