import { createContext } from "react";
import { Specs } from "../../types";

type SpecsContextType = {
  specs?: Specs;
  loading: boolean;
};

export const SpecsContext = createContext<SpecsContextType>({
  specs: undefined,
  loading: true,
});
