import { createContext } from "react";

type OnboardingContextType = {
  errorMsg?: string;
  tokenInfo?: any;
};

export const OnboardingContext = createContext<OnboardingContextType>({
  errorMsg: undefined,
});
