import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { FlexColBox } from "../../common/components";
import { palette, spacing } from "../../styles/theme";
import { User } from "../../types";

interface Props {
  userDetails: User;
}

const YourContactBox = ({ userDetails }: Props) => {
  const { t } = useTranslation();
  return (
    <FlexColBox gap={{ xs: spacing.smallMobile, lg: spacing.smallDesktop }}>
      <Typography variant="textSm" color={palette.grey.primary}>
        {t("order.contact")}
      </Typography>
      <FlexColBox gap={{ xs: spacing.xsMobile, lg: spacing.xsDesktop }}>
        <Typography variant="textRegular">
          {userDetails && `${userDetails.first_name} ${userDetails.last_name}`}
        </Typography>
        <Typography variant="textRegular">
          {userDetails && `${userDetails.email}`}
        </Typography>
      </FlexColBox>
    </FlexColBox>
  );
};

export default YourContactBox;
