import {
  CheckboxProps,
  FormControlLabel,
  Checkbox as MuiCheckbox,
  Typography,
} from "@mui/material";
import { Icon, Link } from "..";

interface Props extends CheckboxProps {
  label?: string;
  textUrl?: string;
  linkUrl?: string;
}

const Checkbox = ({ label, textUrl, linkUrl, ...props }: Props) => {
  const title = (
    <Typography display={"inline-block"} alignItems={"center"} variant="textSm">
      {linkUrl && textUrl ? (
        <>
          {label}{" "}
          <Link to={linkUrl} smallFont target="_blank">
            {textUrl}
          </Link>
        </>
      ) : (
        label
      )}
    </Typography>
  );
  return (
    <FormControlLabel
      sx={{ m: 0 }}
      control={
        <MuiCheckbox
          {...props}
          disableFocusRipple
          disableRipple
          disableTouchRipple
          sx={{ padding: 0, mr: "4px" }}
          icon={<Icon icon="check_box_outline_blank" color="#222222" />}
        />
      }
      label={title}
    />
  );
};

export default Checkbox;
