import { Typography } from "@mui/material";
import { Fragment, memo } from "react";
import { useTranslation } from "react-i18next";
import { matchPath, useLocation, useNavigate } from "react-router-dom";
import { palette, Styles } from "../../../styles/theme";
import { Box } from "../Box";
import { Icon } from "../Icon";
import { MaterialSymbolType } from "../Icon/IconConstant";

export type BreadcrumbStep = {
  labelKey: string;
  path: string;
  icon?: MaterialSymbolType;
};

const styles: Styles = {
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    width: "100%",
    gap: "2px",
  },
  step: {
    display: "flex",
    gap: "4px",
    alignItems: "center",
  },
};

interface Props {
  steps: BreadcrumbStep[];
  firstStepAsBackButton?: boolean;
}

const Breadcrumbs = (props: Props) => {
  const { t } = useTranslation();
  const { steps, firstStepAsBackButton } = props;
  const location = useLocation();
  const navigate = useNavigate();

  const onClick = (stepPath: string, isActive: boolean) => {
    isActive && navigate(stepPath);
  };

  const isPathMatch = (path: string, exact?: boolean) => {
    if (exact) {
      return path === location.pathname;
    }
    return (
      location.pathname.includes(path) ||
      !!matchPath(
        { path: location.pathname, caseSensitive: false, end: true },
        path
      )
    );
  };

  return (
    <Box sx={styles.container}>
      {steps.map((step, index) => {
        const isCurrentStep = isPathMatch(step.path, true);

        const isActive =
          (!!firstStepAsBackButton && index === 0) ||
          (isPathMatch(step.path) && !isCurrentStep);

        if (!isActive && !isPathMatch(step.path)) {
          return null;
        }

        return (
          <Fragment key={step.labelKey}>
            <Box
              sx={{ ...styles.step, cursor: isActive ? "pointer" : "default" }}
              onClick={() => onClick(step.path, isActive)}
            >
              {step.icon && (
                <Icon
                  icon={step.icon}
                  size={22}
                  color={
                    isActive ? palette.blue.secondary : palette.grey.primary
                  }
                />
              )}
              <Typography
                color={isActive ? palette.blue.secondary : palette.grey.primary}
                variant="actionSmallIcon"
                sx={{
                  ":hover": {
                    textDecoration: isActive ? "underline" : undefined,
                  },
                }}
              >
                {t(step.labelKey)}
              </Typography>
            </Box>
            {index !== steps.length - 1 && isActive && (
              <Icon
                icon="chevron_right"
                size={16}
                color={palette.grey.primary}
              />
            )}
          </Fragment>
        );
      })}
    </Box>
  );
};

export default memo(Breadcrumbs);
