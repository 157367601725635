import React, { useState } from "react";

import { CircularProgress } from "@mui/material";
import RCUpload from "rc-upload";
import { useTranslation } from "react-i18next";
import Message from "../../Message/Message";
import UploadSVGImage from "./upload.svg";
import styles from "./UploadImageDropZone.module.scss";

const UploadImageDropZone = (props) => {
  const { t, i18n } = useTranslation();
  const {
    onStart,
    onUpload,
    onSuccess,
    errorCode,
    acceptedFilesTitle,
    acceptedFileExtensions = [".eps, .svg"],
  } = props;
  //TODO, we need another state also that said "Upload successfull", instead of just putting back the button
  //Or make the loading state a string with various enums...
  const [loading, setLoading] = useState(false);

  const acceptedFileExtensionsString = acceptedFileExtensions.join(", ");

  const rcUploadProps = {
    multiple: false,
    accept: acceptedFileExtensionsString,
    onStart: async (file) => {
      if (onStart) {
        await onStart(file);
      }

      setLoading(true);
    },
    onSuccess: async (res, file) => {
      if (onSuccess) {
        await onSuccess(res, file);
      }
      setLoading(false);
    },
    onError: (err) => {
      setLoading(false);
    },
    customRequest: onUpload,
    component: "div",
    className: styles.rcUpload,
  };

  return (
    <React.Fragment>
      {loading ? (
        <div className={styles.dropZone}>
          <div className={styles.centerContent}>
            <span>{t("general.loading")}</span>
            <div className={styles.loadingProgress}>
              <CircularProgress />
            </div>
          </div>
        </div>
      ) : (
        <React.Fragment>
          <span className={styles.supported_file}>{acceptedFilesTitle}</span>
          {errorCode && (
            <Message
              title={t("assetManager.errors." + errorCode + ".title")}
              message={t("assetManager.errors." + errorCode + ".message")}
              linkText={
                i18n.exists("assetManager.errors." + errorCode + ".linkText") &&
                t("assetManager.errors." + errorCode + ".linkText")
              }
              linkUrl={
                i18n.exists("assetManager.errors." + errorCode + ".linkUrl") &&
                t("assetManager.errors." + errorCode + ".linkUrl")
              }
            />
          )}
          <RCUpload {...rcUploadProps}>
            <div className={styles.dropZone}>
              <div className={styles.centerContent}>
                <img alt="iconUrl" src={UploadSVGImage} />
                <span className={styles.spanLink}>
                  {t("techpack.upload_file_select")}
                </span>
                <span className={styles.smallText}>
                  {t("techpack.upload_file_drag")}
                </span>
              </div>
            </div>
          </RCUpload>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default UploadImageDropZone;
