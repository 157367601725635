import { matchPath, useLocation } from "react-router-dom";

export const usePathActive = (to: string) => {
  const location = useLocation();
  const match = matchPath(
    {
      path: to,
    },
    location.pathname
  );
  return !!match;
};
