import { Tooltip, Typography } from "@mui/material";
import { memo, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Box, Icon } from "../../../../common/components";
import Avatar from "../../../../common/components/Avatar/Avatar";
import DeleteModal from "../../../../components/DeleteModal/DeleteModal";
import TeamService from "../../../../service/TeamService";
import { palette, spacing, Styles } from "../../../../styles/theme";
import { Role } from "../../../../types";
import { ROUTES } from "../../../../utils/Constant";
import { routeFormatter } from "../../../../utils/Formatter";
import { useProjectFetcher } from "../../../../utils/ProjectsFetcherHooks";
import { useTeamFetcher } from "../../../../utils/TeamsFetcherHooks";

const styles: Styles = {
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
};

interface Props {
  userId: string;
  canDelete: boolean;
  isCurrentUser: boolean;
  first_name: string;
  last_name: string;
  email: string;
  teamId: string;
  teammateUserId: string;
  imageUrl?: string;
  roles?: Role[];
}

const Teammate = (props: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    userId,
    canDelete,
    isCurrentUser,
    first_name,
    last_name,
    email,
    teamId,
    teammateUserId,
    imageUrl,
    roles,
  } = props;

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const { fetchMyTeamsAndUpdateStore } = useTeamFetcher();
  const { fetchMyProjectsAndUpdateStore } = useProjectFetcher();

  const onDeleteClickClose = () => {
    setShowDeleteModal(false);
  };

  const onDelete = useCallback(async () => {
    try {
      await TeamService.removeTeammate({
        teamId: teamId,
        teammateId: teammateUserId,
      });
      //If we just removed ourselves from a team, then we must redirect to general page
      if (isCurrentUser) {
        navigate(routeFormatter(ROUTES.HOME));
      }

      //Need to wait for update of teams, in case the team that was removed is on top of the teams array
      //(because redirecting to TEAMS will redirect to the first team of the array...)
      await fetchMyTeamsAndUpdateStore();
      await fetchMyProjectsAndUpdateStore();
    } catch (e) {
      console.error(e);
    } finally {
      setShowDeleteModal(false);
    }
  }, [
    teamId,
    teammateUserId,
    fetchMyTeamsAndUpdateStore,
    fetchMyProjectsAndUpdateStore,
    navigate,
    isCurrentUser,
  ]);

  const initials = useMemo(
    () => `${first_name[0]}${last_name[0]}`.toLocaleUpperCase(),
    [first_name, last_name]
  );

  const isInternalMember = useMemo(() => {
    return roles?.some(
      (role) => role.role === "admin" || role.role === "designer"
    );
  }, [roles]);

  return (
    <Box sx={styles.container}>
      <Box display="flex" alignItems="center" gap={1.25} flexGrow={1}>
        <Avatar
          initials={initials}
          userId={userId}
          imageUrl={imageUrl}
          size="lg"
          noBorder
        />

        <Box display="flex" flexDirection="column" maxWidth={"205px"}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: spacing.xxsDesktop,
            }}
          >
            <Typography
              variant="textRegular"
              textOverflow={"ellipsis"}
              whiteSpace={"nowrap"}
              overflow={"hidden"}
              display={"block"}
            >
              {`${first_name} ${last_name}`}
            </Typography>
            {isInternalMember && (
              <Tooltip title={t("team.jameoTeam")} placement="top" arrow>
                <Box>
                  <Icon
                    icon="new_releases"
                    color={palette.blue.secondary}
                    size={12}
                    filled
                  />
                </Box>
              </Tooltip>
            )}
          </Box>
          <Typography
            variant="textSm"
            textOverflow={"ellipsis"}
            whiteSpace={"nowrap"}
            overflow={"hidden"}
            display={"block"}
            color={palette.grey.primary}
          >
            {email}
          </Typography>
        </Box>
      </Box>
      <Box>
        {canDelete && (
          <Icon
            icon="close"
            size={20}
            onClick={() => {
              setShowDeleteModal(true);
            }}
          />
        )}
      </Box>
      <DeleteModal
        open={showDeleteModal}
        onDelete={onDelete}
        title={t("project.remove_teammate_title")}
        message={t("project.remove_teammate_modal", { first_name, last_name })}
        ctaText={t("general.remove")}
        onClose={onDeleteClickClose}
      />
    </Box>
  );
};

export default memo(Teammate);
