import { Skeleton, Typography } from "@mui/material";
import { Box, ContentRepeater } from "../../../common/components";
import { palette, spacing, Styles } from "../../../styles/theme";
import { ROUTES } from "../../../utils/Constant";
import { usePathActive } from "../../../utils/RouterUtils";
import { useThemeBreakpoints } from "../../../utils/themeUtils";
import { CartPreviewSkeleton } from "./CartPreviewSkeleton";
import { ShippingAddressSkeleton } from "./ShippingAddressSkeleton";

const useStyles = (showRightHideLeft: boolean): Styles => ({
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    overflow: "hidden",
  },
  centerContainer: {
    display: "flex",
    flexDirection: "row",
    flex: "1",
    overflow: "auto",
    height: "100%",
  },
  leftPanel: {
    flex: "1 1",
    display: showRightHideLeft ? "none" : "flex",
    flexDirection: "column",
    width: "100%",
    padding: {
      xs: `${spacing.wrapperMobile} ${spacing.wrapperMobile} ${spacing.xlMobile} ${spacing.wrapperMobile}`,
      lg: `${spacing.wrapperDesktop} ${spacing.largeDesktop} ${spacing.xlDesktop} ${spacing.wrapperDesktop}`,
    },
    paddingTop: { xs: 0, lg: "20px" },
    backgroundColor: { xs: "white", lg: palette.grey.lightBackground },
    overflow: "initial",
    alignItems: "flex-end",
    minHeight: "100vh",
  },
  leftPanelContent: {
    display: "flex",
    flexDirection: "column",
    flex: "1 1 auto",
    minWidth: { xs: undefined, lg: "540px" },
    maxWidth: { xs: undefined, lg: "840px" },
    width: "100%",
    mt: { xs: spacing.regularMobile, lg: spacing.regularDesktop },
  },
  rightPanel: {
    display: { xs: showRightHideLeft ? "block" : "none", lg: "block" },
    padding: {
      xs: `0 ${spacing.wrapperMobile} ${spacing.xlMobile} ${spacing.wrapperMobile}`,
      lg: `${spacing.largeDesktop} ${spacing.wrapperDesktop} ${spacing.xlDesktop} ${spacing.wrapperDesktop}`,
    },
    minWidth: { xs: "100%", lg: "400px" },
    maxWidth: { xs: "100%", lg: "40%" },
    flex: "1 1 auto",
    backgroundColor: "white",
    borderLeft: `1px solid ${palette.grey.lightGrey}`,
  },
  leftPanelSkeleton: {
    backgroundColor: { xs: undefined, lg: "white" },
  },
});

export const CheckoutTemplateSkeleton = () => {
  const { isMobile } = useThemeBreakpoints();
  const isOnShippingStep = usePathActive(ROUTES.SHIPPING_ADDRESS);
  const showRightHideLeft = isOnShippingStep && isMobile;
  const styles = useStyles(showRightHideLeft);

  return (
    <Box sx={styles.container}>
      <Box sx={styles.centerContainer}>
        <Box sx={styles.leftPanel}>
          <Box sx={styles.leftPanelContent}>
            {isOnShippingStep ? (
              <ShippingAddressSkeleton />
            ) : (
              <CartPreviewSkeleton />
            )}
          </Box>
        </Box>
        <Box sx={styles.rightPanel}>
          <Box
            display={"flex"}
            gap={{ xs: spacing.mediumMobile, lg: spacing.mediumDesktop }}
            flexDirection={"column"}
          >
            <ContentRepeater repeatCount={5}>
              <Box display={"flex"} gap={"12px"} alignItems={"center"}>
                <Skeleton variant="rectangular" width={64} height={64} />
                <Typography variant="textRegularBold" width={50}>
                  <Skeleton variant="text" />
                </Typography>
                <Typography variant="textSm" width={65}>
                  <Skeleton variant="text" />
                </Typography>
              </Box>
            </ContentRepeater>
          </Box>
          <Box mt={"48px"}>
            <Skeleton variant="text" width={85} />
            <Skeleton variant="text" width={75} />
            <Skeleton variant="text" width={150} />
            <Skeleton variant="text" width={100} />
          </Box>
          <Box my={spacing.regularMobile}>
            {isMobile && <ShippingAddressSkeleton />}
          </Box>
          <Skeleton
            variant="rectangular"
            width={"100%"}
            height={40}
            sx={{
              mt: { xs: spacing.groupingMobile, lg: spacing.groupingDesktop },
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};
