import { Typography } from "@mui/material";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { object, string } from "yup";
import {
  Box,
  Button,
  CenteredColBox,
  ErrorLabel,
  Form,
  GradientBorderedBox,
  Link,
} from "../../../common/components";
import { useForm } from "../../../common/hooks/useForm";
import { OnboardingContext } from "../../../context";
import { spacing } from "../../../styles/theme";
import { UserOnboardingInfo } from "../../../types";
import { PROJECT_SIZES } from "../../../utils/Constant";
import { OnboardingTitle } from "../Component/OnboardingTitle";

interface Props {
  onPreviousStep: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  setOnboardingInfo: React.Dispatch<React.SetStateAction<UserOnboardingInfo>>;
  onNextStep: (values: UserOnboardingInfo) => void;
  onboardingInfo: UserOnboardingInfo;
}

const validationSchema = object({
  projectSize: string().required("general.errors.required.listItem"),
});

export const ProjectSize = ({
  onPreviousStep,
  setOnboardingInfo,
  onNextStep,
  onboardingInfo,
}: Props) => {
  const { t } = useTranslation();
  const { errorMsg } = useContext(OnboardingContext);
  const formik = useForm({
    initialValues: onboardingInfo,
    onSubmit: (values) => {
      setOnboardingInfo(values);
      onNextStep(values);
    },
    validationSchema,
  });
  return (
    <Form formik={formik}>
      <Box fullWidth mt={{ xs: spacing.xlMobile, lg: 0 }}>
        <OnboardingTitle title={t("onboard.project_size.title")} />
        <CenteredColBox
          gap={{ xs: spacing.smallMobile, lg: spacing.smallDesktop }}
        >
          {Object.values(PROJECT_SIZES).map((size) => (
            <GradientBorderedBox
              key={size}
              display="flex"
              alignItems="center"
              gap={1}
              showGradient={formik.values.projectSize === size}
              maxWidth={400}
              fullWidth
              onClick={() => formik.setFieldValue("projectSize", size)}
              data-test={`onboard.projectSize.${size}`}
            >
              <Typography variant="body2" lineHeight="24px">
                {t(`onboard.project_size.${size}`)}
              </Typography>
            </GradientBorderedBox>
          ))}
          <ErrorLabel error={errorMsg || formik.errors.projectSize} />
        </CenteredColBox>
        <CenteredColBox
          gap={{ xs: spacing.ctaSpacingMobile, lg: spacing.ctaSpacingDesktop }}
          mt={{ xs: spacing.regularMobile, lg: spacing.regularDesktop }}
          fullWidth
        >
          <Button
            type="submit"
            fullWidth
            data-test="onboard.projectSize.submitButton"
          >
            {t("onboard.lets_jam")}
          </Button>
          <Link to="" onClick={(e) => onPreviousStep(e)}>
            {t("onboard.back")}
          </Link>
        </CenteredColBox>
      </Box>
    </Form>
  );
};
