import { Typography } from "@mui/material";
import classNames from "classnames";
import React, { PropsWithChildren } from "react";
import { Box, Icon, Link } from "../../common/components";
import { MaterialSymbolType } from "../../common/components/Icon/IconConstant";
import { spacing } from "../../styles/theme";
import cssStyles from "./message.module.scss";

export enum MessageTypes {
  SUCCESS = "success",
  WARNING = "warning",
  ALERT = "alert",
  PLAIN = "plain",
}

interface Props extends PropsWithChildren {
  title?: string;
  message?: string;
  linkText?: string;
  linkUrl?: string;
  titleIcon?: MaterialSymbolType;
  messageIcon?: MaterialSymbolType;
  fullWidth?: boolean;
  type?: MessageTypes;
}

export const Message = (props: Props) => {
  const {
    title,
    message,
    linkText,
    linkUrl,
    titleIcon = null,
    messageIcon = null,
    fullWidth = true,
    type = MessageTypes.WARNING,
  } = props;

  return (
    <React.Fragment>
      <div
        className={classNames(
          cssStyles.Message,
          fullWidth ? cssStyles.FullWidth : null,
          type === MessageTypes.WARNING ? cssStyles.MessageTypeWarning : null,
          type === MessageTypes.SUCCESS ? cssStyles.MessageTypeSuccess : null,
          type === MessageTypes.ALERT ? cssStyles.MessageTypeAlert : null,
          type === MessageTypes.PLAIN ? cssStyles.MessageTypePlain : null
        )}
      >
        {title && (
          <Box
            display={"flex"}
            alignItems={"center"}
            gap={{ xs: spacing.xsDesktop, lg: spacing.smallDesktop }}
          >
            {titleIcon && <Icon icon={titleIcon} size={24} />}
            <Typography variant="textRegular">{title}</Typography>
          </Box>
        )}
        <Box>
          {message && (
            <Box
              display={"flex"}
              alignItems={"center"}
              gap={{ xs: spacing.xsDesktop, lg: spacing.smallDesktop }}
            >
              {messageIcon && <Icon icon={messageIcon} size={24} />}
              <Typography variant="textRegular">{message}</Typography>
            </Box>
          )}
          {linkUrl && (
            <Link to={linkUrl} target={"_self"} rel="noreferrer">
              {linkText || linkUrl}
            </Link>
          )}
        </Box>
        {props.children}
      </div>
    </React.Fragment>
  );
};

export default Message;
