import { MenuItem } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Box } from "../../../common/components";
import { Input } from "../../../common/components/Input";
import { Select } from "../../../common/components/Select/Select";
import { spacing, Styles } from "../../../styles/theme";
import { AddressFormType } from "../../../types";
import CAProvinces from "../provinces.json";
import USStates from "../states.json";

const styles: Styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    gap: { xs: spacing.groupingMobile, lg: spacing.groupingDesktop },
  },
  sharedRow: {
    display: "flex",
    alignItems: "center",
    gap: "12px",
    width: "100%",
    "&>div": {
      maxWidth: "unset",
    },
  },
};

interface Props {
  address: AddressFormType;
  onAddressFieldChange: (e: any, field: keyof AddressFormType) => void;
  onAddressCountryChange: (e: any) => void;
}

const AddressForm = (props: Props) => {
  const { t } = useTranslation();
  const { address, onAddressFieldChange, onAddressCountryChange } = props;

  return (
    <Box sx={styles.container}>
      <Box sx={styles.sharedRow}>
        <Input
          name="firstName"
          value={address.firstName}
          onChange={(e) => onAddressFieldChange(e, "firstName")}
          label={t("checkout.first_name")}
          autoComplete="given-name"
          required
        />
        <Input
          name="lastName"
          value={address.lastName}
          onChange={(e) => onAddressFieldChange(e, "lastName")}
          label={t("checkout.last_name")}
          autoComplete="family-name"
          required
        />
      </Box>
      <Box sx={styles.sharedRow}>
        <Input
          name="email"
          value={address.email}
          onChange={(e) => onAddressFieldChange(e, "email")}
          label={t("checkout.email")}
          autoComplete="email"
          type={"email"}
          required
        />
        <Input
          key="phone"
          value={address.phone}
          onChange={(e) => onAddressFieldChange(e, "phone")}
          label={t("general.phone")}
          autoComplete="phone"
          required
        />
      </Box>
      <Input
        name="organization"
        value={address.organization}
        onChange={(e) => onAddressFieldChange(e, "organization")}
        label={t("checkout.organization")}
        autoComplete="organization"
        required
        fullWidth
      />
      <Select
        key="country"
        name="country"
        onChange={(e: any) => onAddressCountryChange(e)}
        value={address.country}
        label={t("checkout.country")}
        autoComplete="country"
        fullWidth
        controlled
      >
        {["CA", "US"].map((option) => (
          <MenuItem value={option} key={option}>
            {t(`countryCodes.${option}`)}
          </MenuItem>
        ))}
      </Select>
      <Input
        name="address"
        value={address.address}
        onChange={(e) => onAddressFieldChange(e, "address")}
        label={t("checkout.addressLine1")}
        autoComplete="street-address"
        required
        fullWidth
      />
      <Input
        name="appartment"
        value={address.appartment}
        onChange={(e) => onAddressFieldChange(e, "appartment")}
        label={t("checkout.addressLine2")}
        fullWidth
      />
      <Input
        name="city"
        value={address.city}
        onChange={(e) => onAddressFieldChange(e, "city")}
        label={t("checkout.city")}
        autoComplete="address-level2"
        required
        fullWidth
      />
      <Box sx={styles.sharedRow}>
        <Select
          key="state"
          value={address.state}
          onChange={(e: any) => onAddressFieldChange(e, "state")}
          variant="outlined"
          controlled
          label={
            address.country === "US"
              ? t("checkout.state")
              : t("checkout.province_region")
          }
          autoComplete="address-level1"
        >
          {(address.country === "US" ? USStates : CAProvinces).map((option) => (
            <MenuItem value={option} key={option}>
              {t(`alphaCodes.${option}`)}
            </MenuItem>
          ))}
        </Select>
        <Input
          name="zipcode"
          value={address.zipcode}
          onChange={(e) => onAddressFieldChange(e, "zipcode")}
          label={
            address.country === "US"
              ? t("checkout.zip_code")
              : t("checkout.postal_code")
          }
          autoComplete="postal-code"
          required
        />
      </Box>
    </Box>
  );
};

export default AddressForm;
