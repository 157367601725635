import { PropsWithChildren, memo, useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import AppToolbar from "../../../components/AppToolbar/AppToolbar";
import {
  LeftNavigation,
  LeftNavigationMobile,
} from "../../../containers/LeftNavigation";
import { AppToolbarContext } from "../../../context/AppToolbar";
import { Styles } from "../../../styles/theme";
import { useThemeBreakpoints } from "../../../utils/themeUtils";
import { Box } from "../Box";

const styles: Styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    overflow: "hidden",
  },
  mainContainer: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "white",
    height: "100%",
  },
};

interface Props extends PropsWithChildren {}

const AppContainer = ({ children }: Props) => {
  const { designId } = useParams();
  const { isMobile } = useThemeBreakpoints();

  const [isNavigationDrawerOpen, setIsNavigationDrawerOpen] = useState(false);

  const toggleNavigationDrawerOpen = useCallback(() => {
    setIsNavigationDrawerOpen((prev) => !prev);
  }, [setIsNavigationDrawerOpen]);

  //prevent render when the user is in studio
  if (designId) {
    return children;
  }

  return (
    <AppToolbarContext.Provider
      value={{
        isNavigationDrawerOpen,
        toggleNavigationDrawerOpen,
      }}
    >
      <Box sx={styles.container}>
        <AppToolbar />
        <Box sx={styles.mainContainer}>
          {!isMobile && <LeftNavigation />}
          {children}
        </Box>
        {isMobile && <LeftNavigationMobile />}
      </Box>
    </AppToolbarContext.Provider>
  );
};

export default memo(AppContainer);
