import config from "../config";
import {
  Cart,
  CartItem,
  CollectSizeOrder,
  CollectSizeOrderGrouped,
} from "../types";
import { UserInfo } from "../types/index";

// FIXME: This needs to be revisited when we work on the collect size page.
// Right now we check the timestamp to see if we should display on the same line
// or on different lines. For all items received within 1 sec for the same designId,
// we assume it should be displayed on the same line.
export const groupedCollectedSizesBySameTimestamp = (
  collectedSizes: CollectSizeOrder[]
): CollectSizeOrderGrouped[] => {
  return collectedSizes.reduce(
    (prev, curr) => {
      // Here, we check if the item should be displayed on the same line as a previous one.
      const lineToAdjust = prev.find(
        (c) =>
          c.email === curr.email &&
          c.first_name === curr.first_name &&
          c.last_name === curr.last_name &&
          c.designId === curr.designId &&
          Math.abs(
            new Date(c.created_at).getTime() -
              new Date(curr.created_at).getTime()
          ) < 1000
      ); // Sent within 1 sec

      if (lineToAdjust) {
        // When we get here, the item in question should be merged to an existing one.
        const quantityToAdjust = lineToAdjust.sizes.find(
          (s) => s.sizeName === curr.size
        );
        if (quantityToAdjust) {
          // If the user set twice the same size, adjust the quantity.
          quantityToAdjust.quantity += curr.quantity;
        } else {
          // Else add the new size to the list.
          lineToAdjust.sizes.push({
            sizeName: curr.size,
            quantity: curr.quantity,
          });
        }
      } else {
        // Different submission = new line
        const entry = {
          email: curr.email,
          first_name: curr.first_name,
          last_name: curr.last_name,
          designId: curr.designId,
          sizes: [{ sizeName: curr.size, quantity: curr.quantity }],
          created_at: curr.created_at,
        };
        prev.push(entry);
      }
      return prev;
    },
    [
      {
        email: collectedSizes[0]?.email,
        first_name: collectedSizes[0]?.first_name,
        last_name: collectedSizes[0]?.last_name,
        designId: collectedSizes[0]?.designId,
        sizes: [
          {
            sizeName: collectedSizes[0]?.size,
            quantity: collectedSizes[0]?.quantity,
          },
        ],
        created_at: collectedSizes[0]?.created_at,
      },
    ]
  );
};

export const getCollectedSizeTotal = (
  groupedBySameTimestamp: CollectSizeOrderGrouped[]
) => {
  return groupedBySameTimestamp.reduce<{ sizeName: string; total: number }[]>(
    (prev, curr) => {
      for (const size of curr.sizes) {
        const totalToUpdate = prev.find((s) => s.sizeName === size.sizeName);
        if (totalToUpdate) {
          totalToUpdate.total += size.quantity;
        } else {
          prev.push({ sizeName: size.sizeName, total: size.quantity });
        }
      }
      return prev;
    },
    []
  );
};

export const canContinueToPayment = (
  isSaving: boolean,
  items: CartItem[],
  pricingCartData: Cart,
  userInfos: UserInfo
) =>
  !isSaving &&
  items &&
  items.length > 0 &&
  ((pricingCartData.hasMinimum === true &&
    pricingCartData.hasMinimumPerSize === true) ||
    ((userInfos.isAdmin || userInfos.isDesigner) &&
      items.some((item) => item.sizes.some((size) => (size.qty ?? 0) > 0)))) &&
  //pricingCartData.hasReachedMaximum === false && //We allow orders above maximum
  !(
    items.map((i) => i.deprecatedDesign).some((e) => e) &&
    config.blockPaymentWhenDesignIsDeprecated
  );
