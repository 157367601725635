import {
  FormLabel,
  InputProps,
  Input as MuiInput,
  SxProps,
} from "@mui/material";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { FormContext } from "../../../context";
import { getErrorMessage } from "../../../utils/FormValidationsUtils";
import { Box } from "../Box";
import { Label } from "../Typography";
import styles from "./Input.module.scss";

interface Props extends Omit<InputProps, "error"> {
  label?: string;
  link?: JSX.Element;
  error?: string;
  name?: InputProps["name"];
  containerSx?: SxProps;
  labelSx?: SxProps;
  noMaxWidth?: boolean;
  "data-test"?: string;
}

export const Input = ({
  label,
  link,
  name = "",
  labelSx,
  containerSx,
  noMaxWidth,
  ...props
}: Props) => {
  const { t } = useTranslation();
  const { formik } = useContext(FormContext);
  const hasError = !!props.error || !!formik?.errors[name];
  const isTouched = formik?.touched[name];

  return (
    <Box
      width="100%"
      maxWidth={noMaxWidth ? undefined : "400px"}
      display="flex"
      justifyItems="center"
      flexDirection="column"
      minWidth={props.fullWidth ? "100%" : undefined}
      sx={containerSx}
    >
      {link ? (
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={0.5}
        >
          {!!label && <Label htmlFor={props.id}>{label}</Label>}
          {link}
        </Box>
      ) : (
        !!label && (
          <Label
            htmlFor={props.id}
            sx={{ mb: 0.5, textAlign: "start", ...labelSx }}
          >
            {label}
          </Label>
        )
      )}

      <MuiInput
        onChange={
          formik
            ? (e) => formik?.setFieldValue(name, e.target.value)
            : props.onChange
        }
        defaultValue={!props.value ? formik?.initialValues[name] : undefined}
        {...props}
        name={name as string}
        classes={{ root: styles.container }}
        error={!!hasError && !!isTouched}
        sx={{ ...props.sx, minWidth: props.fullWidth ? "100%" : undefined }}
        inputProps={{ "data-test": props["data-test"], ...props.inputProps }}
      />
      {!!formik?.errors[name] && !!isTouched && (
        <FormLabel sx={{ textAlign: "start" }} color="error" error>
          {getErrorMessage(t, formik?.errors[name])}
        </FormLabel>
      )}
      {!!props.error && (
        <FormLabel sx={{ textAlign: "start" }} color="error" error>
          {getErrorMessage(t, props.error)}
        </FormLabel>
      )}
    </Box>
  );
};
