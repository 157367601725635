import React from "react";
import { Project } from "../../../../types";
import { ProjectNavLink } from "../ProjectNavLink";
import { TeamNavLink } from "../TeamNavLink";

interface Props {
  canAddProject: boolean;
  id: string;
  name: string;
  projects: Project[];
  url: (projectId: string) => string;
}

export const TeamProject = (props: Props) => {
  const { projects, url } = props;

  return (
    <React.Fragment>
      <TeamNavLink {...props}>
        {projects.map((project) => (
          <ProjectNavLink
            label={project.name}
            url={url(project.id)}
            key={project.id}
          />
        ))}
      </TeamNavLink>
    </React.Fragment>
  );
};
