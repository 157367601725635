import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useTranslation } from "react-i18next";
import { IncludedSizesPerRange } from "../../../types";

interface Props {
  orderTitle: string;
  sizesTitle: string;
  passedArray: IncludedSizesPerRange[];
}

export default function BasicTable({
  orderTitle,
  sizesTitle,
  passedArray,
}: Props) {
  const { t } = useTranslation();

  const rows = passedArray.map((x) => {
    return {
      name: `${x.minQty} ${t("cart.to")} ${x.maxQty}`,
      value: `${x.fromSizeName} ${t("cart.to")} ${x.toSizeName}`,
    };
  });

  return (
    <Table aria-label="simple table" size="small">
      <TableHead>
        <TableRow>
          <TableCell sx={{ paddingLeft: "0px", paddingBottom: "16px" }}>
            <span style={{ fontWeight: "bold", fontSize: "1rem" }}>
              {orderTitle}
            </span>
          </TableCell>
          <TableCell
            align="right"
            sx={{ paddingRight: "0px", paddingBottom: "16px" }}
          >
            <span style={{ fontWeight: "bold", fontSize: "1rem" }}>
              {sizesTitle}
            </span>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((row) => (
          <TableRow key={row.name}>
            <TableCell component="th" scope="row" sx={{ paddingLeft: "0px" }}>
              {
                <span style={{ marginBottom: "0px", fontSize: "1rem" }}>
                  {row.name}
                </span>
              }
            </TableCell>
            <TableCell align="right" sx={{ paddingRight: "0px" }}>
              {
                <span style={{ marginBottom: "0px", fontSize: "1rem" }}>
                  {row.value}
                </span>
              }
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
