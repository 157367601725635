//FIXME - rename the file and move it under hooks section
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useMoment } from "../common/hooks/useMoment";

export const useTimeSince = () => {
  const { t } = useTranslation();
  const { moment, formatShortDate } = useMoment();

  const timeSince = useCallback(
    (date: string | number | Date) => {
      const now = moment(new Date());
      const momentDate = moment(date);
      const diffInMonths = now.diff(momentDate, "month");
      const diffInWeeks = now.diff(momentDate, "week");
      const diffInDays = now.diff(momentDate, "day");
      const diffInHours = now.diff(momentDate, "hour");
      const diffInMinutes = now.diff(momentDate, "minute");

      if (diffInMonths >= 3) {
        return t("general.updatedAt", {
          date: formatShortDate(date),
        });
      }
      if (diffInMonths > 0) {
        return t("time.month", { count: diffInMonths });
      }
      if (diffInWeeks < 4 && diffInWeeks > 0) {
        return t("time.week", { count: diffInWeeks });
      }
      if (diffInDays < 7 && diffInDays > 0) {
        return t("time.day", { count: diffInDays });
      }
      if (diffInHours < 24 && diffInHours > 0) {
        return t("time.hour", { count: diffInHours });
      }
      if (diffInHours < 1 && diffInMinutes > 2) {
        return t("time.min", { count: diffInMinutes });
      }
      if (diffInMinutes < 2) {
        return t("time.just_now");
      }
    },
    [formatShortDate, moment, t]
  );

  //FIXME - this will need to be remove in the future
  const dateFormatter = (s: any) => {
    let day,
      tz,
      rx = /^(\d{4}-\d\d-\d\d([tT ][\d:.]*)?)([zZ]|([+-])(\d\d):(\d\d))?$/,
      p = rx.exec(s) || [];
    if (p[1]) {
      day = p[1].split(/\D/);
      for (let i = 0, L = day.length; i < L; i++) {
        (day[i] as any) = parseInt(day[i], 10) || 0;
      }
      (day[1] as any) -= 1;
      day = new Date(Date.UTC.apply(Date, day as any));
      if (!day.getDate()) return new Date();
      if (p[5]) {
        tz = parseInt(p[5], 10) * 60;
        if (p[6]) tz += parseInt(p[6], 10);
        if (p[4] === "+") tz *= -1;
        if (tz) day.setUTCMinutes(day.getUTCMinutes() + tz);
      }
      return day;
    }
    return new Date();
  };

  return { timeSince, dateFormatter };
};
