import { CountryCode } from "libphonenumber-js";
import _ from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { isValidPhoneNumber } from "react-phone-number-input";
import { Link } from "react-router-dom";
import * as yup from "yup";
import CAProvinces from "../../../containers/ShippingAddressContainer/provinces.json";
import USStates from "../../../containers/ShippingAddressContainer/states.json";
import { AddressFormType, AddressType } from "../../../types";
import { AddressBox } from "../../AddressBox";
import styles from "../adminPreorderPriceAndShippingDetails.module.scss";

interface Props {
  initialValue: any;
  titleLabel: string;
  editLabel: string;
  saveLabel: string;
  cancelLabel: string;
  onUpdate?: (values: AddressFormType) => Promise<void>;
}

const EditableInputAddressField = ({
  initialValue,
  onUpdate,
  titleLabel,
  editLabel,
  saveLabel,
  cancelLabel,
}: Props) => {
  const { t } = useTranslation();

  const [isEditing, setEditing] = useState(false);
  const [valueState, setValueState] = useState<AddressFormType>({
    organization: "",
    firstName: "",
    lastName: "",
    address: "",
    appartment: "",
    city: "",
    country: "CA",
    state: "",
    zipcode: "",
    phone: "",
    // phoneExt: "",
    email: "",
  });

  //Since the input from the formatted preorder address is different than the input of the API to update address,
  //we need to transform the initialValue to match the API schema
  const getInitialValueFormattedForAPI = (initialValue: AddressType) => {
    return {
      organization: initialValue.organization,
      firstName: initialValue.firstName,
      lastName: initialValue.lastName,
      address: initialValue.address.line1,
      appartment: initialValue.address.line2,
      city: initialValue.address.city,
      country: initialValue.address.country,
      state: initialValue.address.state,
      zipcode: initialValue.address.postal_code,
      phone: initialValue.phone,
      phoneExt: initialValue.phoneExt,
      email: initialValue.email,
    };
  };

  //Initial loading of the address
  useEffect(() => {
    //Since the input from the formatted preorder address is different than the input of the API to update address,
    //we need to transform the initialValue to match the API schema
    const initialValueFormattedforAPI =
      getInitialValueFormattedForAPI(initialValue);

    setValueState(initialValueFormattedforAPI);
  }, [initialValue, setValueState]);

  const onClickEdit = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();

      setEditing(true);
    },
    [setEditing]
  );

  const onClickSave = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();

      onUpdate?.(valueState).then(() => {
        setEditing(false);
      });
    },
    [setEditing, onUpdate, valueState]
  );

  const onClickCancel = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();

      //Reset to original values
      const initialValueFormattedforAPI =
        getInitialValueFormattedForAPI(initialValue);

      setValueState(initialValueFormattedforAPI);

      setEditing(false);
    },
    [setValueState, setEditing, initialValue]
  );

  const onInputTextValueChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    field: keyof Omit<AddressFormType, "country">
  ) => {
    setValueState((oldValueState) => {
      const newState = _.cloneDeep(oldValueState);

      const value = e.target.value || "";

      newState[field] = value;
      return newState;
    });
  };

  const onInputCountryValueChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setValueState((oldValueState) => {
      const newState = _.cloneDeep(oldValueState);

      const newCountry = e.target.value;

      const value = newCountry || "";

      newState["country"] = value as CountryCode;

      // Update the states and select the first one
      const stateList = newCountry === "US" ? USStates : CAProvinces;
      newState["state"] = stateList[0];

      return newState;
    });
  };

  //It will work because we replace the valueState object on each address changes
  const isValid = useMemo(() => {
    const schema = yup.object().shape({
      organization: yup.string().required(),
      firstName: yup.string().required(),
      lastName: yup.string().required(),
      address: yup.string().required(),
      appartment: yup.string(),
      city: yup.string().required(),
      country: yup.string().required(),
      state: yup.string().required(),
      zipcode: yup.string().required(),
      phone: yup.string().required(),
      email: yup.string().required(),
    });
    return (
      schema.isValidSync(valueState) && isValidPhoneNumber(valueState.phone)
    );
  }, [valueState]);

  return (
    <div className={styles.editableColumnFieldContainer}>
      <div className={styles.editColumnFieldTitle} key="editColumnFieldTitle">
        <span className={styles.title}>{titleLabel} </span>
        <div className={styles.action}>
          {onUpdate && !isEditing && (
            <Link to="" onClick={(e) => onClickEdit(e)}>
              {editLabel}
            </Link>
          )}
          {isEditing && (
            <React.Fragment>
              {isValid ? (
                <Link to="" onClick={(e) => onClickSave(e)}>
                  {saveLabel}
                </Link>
              ) : (
                <span>{saveLabel}</span>
              )}{" "}
              <Link to="" onClick={(e) => onClickCancel(e)}>
                {cancelLabel}
              </Link>
            </React.Fragment>
          )}
        </div>
      </div>
      <div className={styles.editableColumnField} key="editField">
        {isEditing ? (
          <div className={styles.deliveryForm} key="form">
            <input
              key="organization"
              onChange={(e) => onInputTextValueChange(e, "organization")}
              value={valueState.organization}
              placeholder={t("checkout.organization")}
              className={styles.editableText}
              autoComplete="organization"
              required
            />
            <input
              key="firstName"
              onChange={(e) => onInputTextValueChange(e, "firstName")}
              value={valueState.firstName}
              placeholder={t("checkout.first_name")}
              className={styles.editableText}
              autoComplete="given-name"
              required
            />
            <input
              key="lastName"
              onChange={(e) => onInputTextValueChange(e, "lastName")}
              value={valueState.lastName}
              placeholder={t("checkout.last_name")}
              className={styles.editableText}
              autoComplete="family-name"
              required
            />
            <input
              key="address"
              onChange={(e) => onInputTextValueChange(e, "address")}
              value={valueState.address}
              placeholder={t("checkout.addressLine1")}
              className={styles.editableText}
              autoComplete="street-address"
              required
            />
            <input
              key="appartment"
              onChange={(e) => onInputTextValueChange(e, "appartment")}
              value={valueState.appartment}
              placeholder={t("checkout.addressLine2")}
              className={styles.editableText}
            />
            <input
              key="city"
              onChange={(e) => onInputTextValueChange(e, "city")}
              value={valueState.city}
              placeholder={t("checkout.city")}
              className={styles.editableText}
              autoComplete="address-level2"
              required
            />
            <select
              key="country"
              onChange={(e) => onInputCountryValueChange(e)}
              value={valueState.country}
              className={styles.editableSelect}
              autoComplete="country"
            >
              {["CA", "US"].map((option) => (
                <option value={option} key={option}>
                  {t(`countryCodes.${option}`)}
                </option>
              ))}
            </select>
            <select
              key="state"
              onChange={(e) => onInputTextValueChange(e, "state")}
              value={valueState.state}
              className={styles.editableSelect}
              autoComplete="address-level1"
            >
              {(valueState.country === "US" ? USStates : CAProvinces).map(
                (option) => (
                  <option value={option} key={option}>
                    {t(`alphaCodes.${option}`)}
                  </option>
                )
              )}
            </select>
            <input
              key="zipcode"
              onChange={(e) => onInputTextValueChange(e, "zipcode")}
              value={valueState.zipcode}
              placeholder={
                valueState.country === "US"
                  ? t("checkout.zip_code")
                  : t("checkout.postal_code")
              }
              className={styles.editableText}
              autoComplete="postal-code"
              required
            />
            <input
              key="phone"
              onChange={(e) => onInputTextValueChange(e, "phone")}
              value={valueState.phone}
              placeholder={t("general.phone")}
              className={styles.editableText}
              autoComplete="phone"
              required
            />
            <input
              key="email"
              onChange={(e) => onInputTextValueChange(e, "email")}
              value={valueState.email}
              placeholder={t("checkout.email")}
              className={styles.editableText}
              autoComplete="email"
              type={"email"}
              required
            />
          </div>
        ) : (
          <AddressBox address={initialValue} />
        )}
      </div>
    </div>
  );
};

export default EditableInputAddressField;
