import {
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import { Button, FlexColBox, Form, Input } from "../../../../common/components";
import { useForm } from "../../../../common/hooks/useForm";
import { useSelector } from "../../../../common/hooks/useSelector";
import DesignService from "../../../../service/DesignService";
import { spacing } from "../../../../styles/theme";
import { CreateMockupForm } from "../../../../types";
import { useThemeBreakpoints } from "../../../../utils/themeUtils";
import { DialogTitleWithCloseButton } from "../../../DialogTitleWithCloseButton";

const initialValues: CreateMockupForm = {
  company: "",
  mockupId: "",
  sku: "",
  proposalNumber: "",
  graphicDesigner: "",
};

interface Props {
  open: boolean;
  onClose: () => void;
  designId: string;
  designName: string;
}

const CreateMockupModal = ({ designId, designName, onClose, open }: Props) => {
  const { t } = useTranslation();
  const { isMobile } = useThemeBreakpoints();
  const userInfo = useSelector((state) => state.userInfo);

  const onCreate = async (values: CreateMockupForm) => {
    await DesignService.createMockup(designId, values);
    onClose();
  };

  const formik = useForm({
    initialValues: {
      company: designName,
      mockupId: "",
      sku: "",
      proposalNumber: "1.0",
      graphicDesigner: `${userInfo.firstName[0]}${userInfo.lastName[0]}`,
    },
    onSubmit: onCreate,
  });

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth={true}
      maxWidth={"md"}
      fullScreen={isMobile}
    >
      <Form formik={formik}>
        <DialogTitleWithCloseButton
          id="customized-dialog-title"
          onClose={onClose}
        >
          {t("design.createMockup.title")}
        </DialogTitleWithCloseButton>
        <DialogContent>
          <FlexColBox
            gap={{ xs: spacing.regularMobile, lg: spacing.regularDesktop }}
          >
            <FlexColBox
              gap={{ xs: spacing.groupingMobile, lg: spacing.groupingDesktop }}
              maxWidth={"calc(100% - 4px)"} //NOTE - 4px to prevent input from being cut because of box-shadow of 2px each side
            >
              <Input
                fullWidth
                name="company"
                label={t("design.createMockup.company")}
              />
              <Input
                fullWidth
                name="mockupId"
                label={t("design.createMockup.mockupId")}
              />
              <Input
                fullWidth
                name="sku"
                label={t("design.createMockup.sku")}
              />
              <Input
                fullWidth
                name="proposalNumber"
                label={t("design.createMockup.proposalNumber")}
              />
              <Input
                fullWidth
                name="graphicDesigner"
                label={t("design.createMockup.graphicDesigner")}
              />
            </FlexColBox>
            {isMobile && (
              <Button type="submit" fullWidth>
                <Typography variant="actionBoldBigIcon">
                  {t("general.create")}
                </Typography>
              </Button>
            )}
          </FlexColBox>
        </DialogContent>
        {!isMobile && (
          <DialogActions>
            <Button type="submit">
              <Typography variant="actionBoldBigIcon">
                {t("general.create")}
              </Typography>
            </Button>
          </DialogActions>
        )}
      </Form>
    </Dialog>
  );
};

export default memo(CreateMockupModal);
