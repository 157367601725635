import { Skeleton, Typography } from "@mui/material";
import { Box, ContentRepeater } from "../../../common/components";
import { palette, spacing, Styles } from "../../../styles/theme";

const styles: Styles = {
  leftPanelSkeleton: {
    backgroundColor: { xs: undefined, lg: palette.grey.lightGrey },
  },
};

export const CartPreviewSkeleton = () => {
  return (
    <Box>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        mb={"20px"}
        width={"100%"}
        alignItems={"center"}
      >
        <Typography variant="h1">
          <Skeleton variant="text" sx={styles.leftPanelSkeleton} width={100} />
        </Typography>
        <Skeleton
          variant="rounded"
          sx={styles.leftPanelSkeleton}
          width={150}
          height={35}
        />
      </Box>
      <Typography
        variant="h2"
        mb={{ xs: spacing.mediumMobile, lg: spacing.mediumDesktop }}
      >
        <Skeleton variant="text" sx={styles.leftPanelSkeleton} />
      </Typography>
      <Box
        display={"flex"}
        gap={{ xs: spacing.mediumMobile, lg: spacing.mediumDesktop }}
        flexDirection={"column"}
      >
        <ContentRepeater repeatCount={2}>
          <Skeleton
            variant="rounded"
            width={"100%"}
            height={200}
            sx={styles.leftPanelSkeleton}
          />
        </ContentRepeater>
      </Box>
    </Box>
  );
};
