import { LinearProgress } from "@mui/material";
import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { DESIGN_CHANGE_ENUM } from "../../utils/ReactHooks";
import Message from "../Message/Message";
import styles from "./ProductImage.module.scss";
import "./productImage.scss";

const ProductImage = (props) => {
  const {
    images,
    previewContainerRef,
    isUpdating,
    designWarning,
    migrationNeeded,
    colorWarning,
    onDesignChange,
    decorations,
  } = props;

  const { t } = useTranslation();

  return (
    <React.Fragment>
      <div
        className={classNames(
          styles.main,
          designWarning
            ? styles.halfVerticalPadding
            : styles.fullVerticalPadding
        )}
        ref={previewContainerRef}
      >
        {designWarning &&
          (migrationNeeded ? (
            <Message
              message={t("generator.warnings.styleOrColorNeedMigration")}
              messageIcon="warning"
              fullWidth={false}
            >
              <button
                onClick={() => onDesignChange(DESIGN_CHANGE_ENUM.REFRESH, null)}
              >
                {t("generator.warnings.refresh")}
              </button>
            </Message>
          ) : (
            <Message
              message={t("generator.warnings.styleOrColorNoLongerAvailable")}
              messageIcon="warning"
              fullWidth={false}
            />
          ))}
        {colorWarning && (
          <Message
            message={t("generator.warnings.colorBleedingWarning")}
            messageIcon="warning"
            fullWidth={false}
          />
        )}
        {decorations.some((d) => d.deprecated) && (
          <Message
            message={t("generator.warnings.deprecatedDecoration")}
            messageIcon="warning"
            fullWidth={false}
          />
        )}
        <div className={styles.imageContainer}>
          {images.map((image) => (
            <img
              src={image.url}
              alt={image.side}
              className={styles.img}
              key={image.side}
            />
          ))}
        </div>
        {isUpdating && (
          <div className={styles.updatingRendering}>
            <span>{t("loading.updating_rendering")}</span>
            <LinearProgress />
          </div>
        )}
      </div>
    </React.Fragment>
  );
};
export default ProductImage;
