import { Badge, styled } from "@mui/material";
import { memo } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Icon } from "../../../common/components";
import { useSelector } from "../../../common/hooks/useSelector";
import { palette } from "../../../styles/theme";
import { ROUTES } from "../../../utils/Constant";

const StyledBadge = styled(Badge)(() => ({
  "& .MuiBadge-badge": {
    right: "6px",
    top: "4px",
    height: 10,
    paddingRight: "3px",
    paddingLeft: "3px",
    minWidth: "fit-content",
    maxWidth: "fit-content",
  },
}));

const Cart = () => {
  const navigate = useNavigate();
  const cart = useSelector((state) => state.cart);

  const getCartItemCount = () => {
    const count = cart?.pricingCartData?.items?.length;
    if (count) {
      if (count > 100) {
        return "99+";
      } else {
        return count;
      }
    }
    return undefined;
  };

  return (
    <Box
      pl={1}
      pr={cart?.pricingCartData?.items?.length ? 2.5 : 1}
      display="flex"
      alignItems="center"
      justifyContent="center"
      onClick={() => navigate(ROUTES.CART)}
    >
      <StyledBadge badgeContent={getCartItemCount()} color="error">
        <Icon icon="shopping_cart" color={palette.grey.nearBlack} size={22} />
      </StyledBadge>
    </Box>
  );
};

export default memo(Cart);
