import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import DialogTitleWithCloseButton from "../../../components/DialogTitleWithCloseButton/DialogTitleWithCloseButton";
import { IncludedSizesPerRange } from "../../../types";
import { useCurrency } from "../../../utils/Currencies";
import styles from "./ExtraSizeModal.module.scss";
import BasicTable from "./Table";

interface Props {
  open: boolean;
  onClose: () => void;
  includedSizesPerRange: IncludedSizesPerRange[];
}

const ExtraSizeModal = ({ open, onClose, includedSizesPerRange }: Props) => {
  const { t } = useTranslation();

  const { NarrowCurrencyFormatter } = useCurrency();

  return (
    <Dialog
      open={open}
      className={styles.modal}
      onClose={onClose}
      fullWidth={false}
      maxWidth={"xs"}
      sx={{
        "& .MuiPaper-root": {
          width: "480px",
          maxWidth: "480px",
          borderRadius: "8px",
        },
      }}
    >
      <DialogTitleWithCloseButton onClose={onClose}>
        {t("cart.size_modal_title")}
      </DialogTitleWithCloseButton>
      <DialogContent
        sx={{
          padding: "0px",
        }}
      >
        <div className={styles.container}>
          {includedSizesPerRange && (
            <BasicTable
              passedArray={includedSizesPerRange}
              orderTitle={t("cart.size_modal_order")}
              sizesTitle={t("cart.size_modal_sizes")}
            ></BasicTable>
          )}
        </div>
        <div style={{ marginTop: "30px" }}>
          <span
            style={{
              justifyContent: "space-evenly",
              fontSize: "1rem",
            }}
          >
            {includedSizesPerRange &&
              t("cart.size_modal_blurb", {
                priceWithCurrencySymbol: NarrowCurrencyFormatter?.format(
                  includedSizesPerRange[0].extraSizePrice
                ),
              })}
          </span>
        </div>
      </DialogContent>
      <DialogActions></DialogActions>
    </Dialog>
  );
};

export default memo(ExtraSizeModal);
