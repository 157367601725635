import { Skeleton, Typography } from "@mui/material";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { generatePath, useNavigate } from "react-router-dom";
import { Box, GradientLink } from "../../common/components";
import GradientButton from "../../common/components/Button/GradientButton";
import { usePageTitle } from "../../common/hooks/usePageTitle";
import { useSelector } from "../../common/hooks/useSelector";
import RecentJams from "../../components/Home/RecentJams";
import ProjectService from "../../service/ProjectService";
import TeamService from "../../service/TeamService";
import { createStyles, spacing } from "../../styles/theme";
import { ROUTES } from "../../utils/Constant";
import { useGlobalLoading } from "../../utils/ContextHooks";
import { useProjectFetcher } from "../../utils/ProjectsFetcherHooks";
import { useTeamFetcher } from "../../utils/TeamsFetcherHooks";

const styles = createStyles({
  container: {
    p: {
      xs: `${spacing.wrapperMobile} ${spacing.wrapperMobile} ${spacing.xlMobile} ${spacing.wrapperMobile}`,
      lg: `${spacing.regularDesktop} ${spacing.largeDesktop} ${spacing.xlDesktop} ${spacing.largeDesktop}`,
    },
    maxHeight: { xs: "calc(100vh - 48px)", lg: "calc(100vh - 50px)" },
    overflowY: "auto",
  },
});

const Home = () => {
  const { t } = useTranslation();
  const { isGloballyLoading } = useGlobalLoading();

  const navigate = useNavigate();
  usePageTitle(t("pages.home.title"));

  const teamsState = useSelector((state) => state.myTeams);
  const teams = useMemo(() => {
    return teamsState?.teams;
  }, [teamsState]);

  const { fetchMyTeamsAndUpdateStore } = useTeamFetcher();
  const { fetchMyProjectsAndUpdateStore } = useProjectFetcher();

  const onNewTeamClick = useCallback(async () => {
    const bodyData = {
      name: `${t("team.team")} ${teams.length + 1}`,
    };

    const {
      data: {
        data: { id },
      },
    } = await TeamService.createTeam(bodyData);

    const projectBodyData = {
      name: `${t("project.project")}`,
      teamId: id,
    };

    await ProjectService.createProject(projectBodyData);

    await fetchMyTeamsAndUpdateStore();
    await fetchMyProjectsAndUpdateStore();

    navigate(generatePath(ROUTES.TEAM_PROJECTS, { teamId: id }));
  }, [
    t,
    teams.length,
    fetchMyTeamsAndUpdateStore,
    fetchMyProjectsAndUpdateStore,
    navigate,
  ]);

  return (
    // <OverlayScrollbarWrapper>
    <Box sx={styles.container} fullWidth>
      <Typography
        data-test="dashboard.title"
        variant="h1"
        mb={{ xs: spacing.groupingMobile, lg: spacing.groupingDesktop }}
      >
        {isGloballyLoading ? (
          <Skeleton variant="text" width={200} />
        ) : (
          t("HomePage.Welcome")
        )}
      </Typography>
      <Box
        display="flex"
        gap={{ xs: spacing.ctaSpacingMobile, lg: spacing.ctaSpacingDesktop }}
        mb={{ xs: spacing.regularMobile, lg: spacing.largeDesktop }}
      >
        <GradientButton
          text={t("HomePage.create_team")}
          icon={"add"}
          onClick={onNewTeamClick}
          data-test={"createTeamButton"}
        />
        <GradientLink
          text={t("HomePage.work_with_designer")}
          icon={"palette"}
          to={t("navigation.work_with_designer.linkUrl")}
          target="_blank"
        />
      </Box>
      <Box fullWidth>
        <RecentJams />
      </Box>
    </Box>
    // </OverlayScrollbarWrapper>
  );
};

export default Home;
