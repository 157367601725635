import Button from "@mui/material/Button";
import MaterialToolbar from "@mui/material/Toolbar";
import { styled } from "@mui/material/styles";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./StudioToolbar.module.scss";
import "./studioToolbar.scss";

import BasketIcon from "@mui/icons-material/AddShoppingCart";
import classNames from "classnames";
import inviteIcon from "../../assets/editor/invite.svg";
import priceTagIcon from "../../assets/editor/priceTag.svg";
import AvatarGroup from "../../common/components/AvatarGroup";
import IconButtonForButtonGroup from "../../common/components/IconButtonForButtonGroup";
import { useCart } from "../../utils/CartHooks";
import { CalculatePriceModal } from "../CalculatePriceModal/CalculatePriceModal";
import InviteTeammateModal from "../InviteTeammateModal/InviteTeammateModal";

import { generatePath, useNavigate, useParams } from "react-router-dom";
import DarkTooltip from "../../common/components/DarkTooltip";
import { ROUTES } from "../../utils/Constant";
import { useCurrency } from "../../utils/Currencies";
import DesignMenu from "../Design/DesignMenu/DesignMenu";
import DeliveryInfoMessage from "../Message/DeliveryInfoMessage/DeliveryInfoMessage";

const StudioToolbar = (props) => {
  const [inviteOpen, setInviteOpen] = useState(false);
  const [priceOpen, setPriceOpen] = useState(false);
  const { projectId, designId } = useParams();
  const { t } = useTranslation();
  const {
    users = [],
    project,
    designFromProject,
    productName,
    designPricing,
    pricingDef,
    userInfo,
    sizesOption,
    canRenameDesign,
    canDeleteDesign,
    deliveryTime,
  } = props;

  const navigate = useNavigate();

  const { getCurrency } = useCurrency();

  const avatarArray = users.map((c) => ({
    color: c.color,
    initials: c.initials,
  }));

  const onInviteClick = () => {
    setInviteOpen(true);
  };

  const onInviteClose = () => {
    setInviteOpen(false);
  };

  const onPriceClick = () => {
    setPriceOpen(true);
  };

  const onPriceClose = () => {
    setPriceOpen(false);
  };

  const { addToCart } = useCart();
  const onAddToCartClick = async () => {
    addToCart(
      designId,
      sizesOption,
      getCurrency(),
      productName,
      undefined,
      true
    );
  };

  const teamId = useMemo(() => project?.team_id, [project]);

  const designString = useMemo(() => {
    if (!designFromProject) {
      return "";
    }

    return designFromProject?.name || t("design.untitled_design");
  }, [designFromProject, t]);

  const projectString = useMemo(() => {
    if (!project) {
      return "";
    }

    return project?.name;
  }, [project]);

  const designsPageRoute = useMemo(() => {
    return generatePath(ROUTES.DESIGNS, { projectId });
  }, [projectId]);

  const ActionButton = styled(Button)({
    color: "white",
    border: "none",
    borderRadius: "3px",
    backgroundColor: "#20497A",
    borderColor: "#20497A",
    marginLeft: "10px",
    paddingTop: "2px",
    paddingBottom: "2px",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#20497A",
      borderColor: "#20497A",
      boxShadow: "none",
    },
  });

  return (
    <React.Fragment>
      <MaterialToolbar disableGutters={true} className="studio-main-header">
        <div className={styles.leftSection}>
          <div className={styles.breadcrumbs}>
            <DarkTooltip
              title={t("studio_toolbar.back_to_project")}
              followCursor
            >
              <span
                className={styles.projectTitle}
                onClick={() => {
                  navigate(designsPageRoute);
                }}
                data-test="studio.backToProject"
              >
                {projectString}
              </span>
            </DarkTooltip>
            <span>&nbsp;/&nbsp;</span>
            <span className={styles.designTitle}>{designString}</span>

            <DesignMenu
              designId={designId}
              designName={designFromProject?.name}
              projectId={project?.id}
              canDeleteDesign={canDeleteDesign}
              canRenameDesign={canRenameDesign}
            />
          </div>
        </div>

        <div className={styles.middleSection}>
          <DeliveryInfoMessage
            minWeeks={deliveryTime?.minWeeks}
            maxWeeks={deliveryTime?.maxWeeks}
            fontSize="0.8rem"
          />
        </div>

        <div className={styles.rightSection}>
          {!productName ? (
            <React.Fragment />
          ) : (
            <div className={styles.buttonGroup}>
              <IconButtonForButtonGroup
                aria-label="pricing"
                className={styles.iconButtonRoot}
                onClick={() => onPriceClick()}
              >
                <img
                  src={priceTagIcon}
                  className={styles.buttonIcon}
                  alt={"price tag"}
                />
                <h6>{t("pricing.calculate_price")}</h6>
              </IconButtonForButtonGroup>
              <IconButtonForButtonGroup
                aria-label="addToCart"
                className={styles.iconButtonRoot}
                onClick={() => onInviteClick()}
                open={inviteOpen}
                onClose={onInviteClose}
              >
                <img
                  src={inviteIcon}
                  className={styles.buttonIcon}
                  alt={"invite"}
                />
                <h6>{t("design.add_to_team")}</h6>
              </IconButtonForButtonGroup>
            </div>
          )}
          <div className={styles.avatarContainer}>
            <ul
              className={classNames(
                "right-socialbar",
                styles.rightSectionSocial
              )}
            >
              {teamId && (
                <li>
                  <AvatarGroup avatars={avatarArray} />
                </li>
              )}
            </ul>
          </div>
          <ActionButton
            aria-label="addToCart"
            onClick={() => onAddToCartClick()}
            className={styles.addToCartButton}
            variant="contained"
            disableElevation
            disableRipple
            startIcon={<BasketIcon />}
          >
            {t("pricing.add_to_cart")}
          </ActionButton>
        </div>
      </MaterialToolbar>

      {!teamId ? (
        <React.Fragment />
      ) : (
        <InviteTeammateModal
          open={inviteOpen}
          onClose={onInviteClose}
          teamId={teamId}
        />
      )}
      {!productName || !designPricing ? (
        <React.Fragment />
      ) : (
        <CalculatePriceModal
          open={priceOpen}
          onClose={onPriceClose}
          designId={designId}
          productName={productName}
          designPricing={designPricing}
          pricingDef={pricingDef}
          sizesOption={sizesOption}
        />
      )}
    </React.Fragment>
  );
};
export default StudioToolbar;
