import { Box, Breadcrumbs, BreadcrumbStep } from "../../common/components";
import { palette, spacing, Styles } from "../../styles/theme";
import { ROUTES } from "../../utils/Constant";
import { usePathActive } from "../../utils/RouterUtils";
import { useThemeBreakpoints } from "../../utils/themeUtils";

const useStyles = (showRightHideLeft: boolean): Styles => ({
  container: {
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
  },
  centerContainer: {
    display: "flex",
    flexDirection: "row",
    flex: "1",
    overflow: "auto",
    height: "100%",
  },
  leftPanel: {
    flex: "1 1",
    display: showRightHideLeft ? "none" : "flex",
    flexDirection: "column",
    width: "100%",
    padding: {
      xs: `${spacing.wrapperMobile} ${spacing.wrapperMobile} ${spacing.xlMobile} ${spacing.wrapperMobile}`,
      lg: `${spacing.wrapperDesktop} ${spacing.largeDesktop} ${spacing.xlDesktop} ${spacing.wrapperDesktop}`,
    },
    paddingTop: { xs: 0, lg: "20px" },
    backgroundColor: { xs: "white", lg: palette.grey.lightBackground },
    overflow: "initial",
    alignItems: "flex-end",
    minHeight: { xs: "fit-content", lg: "100vh" },
    maxHeight: { xs: "calc(100vh - 48px)", lg: "unset" },
    overflowY: { xs: "auto", lg: "initial" },
  },
  leftPanelContent: {
    display: "flex",
    flexDirection: "column",
    flex: "1 1 auto",
    minWidth: { xs: undefined, lg: "540px" },
    maxWidth: { xs: undefined, lg: "840px" },
    width: "100%",
    mt: { xs: spacing.wrapperMobile, lg: spacing.regularDesktop },
  },
  rightPanel: {
    display: { xs: showRightHideLeft ? "block" : "none", lg: "block" },
    padding: {
      xs: `0 ${spacing.wrapperMobile} ${spacing.xlMobile} ${spacing.wrapperMobile}`,
      lg: `${spacing.largeDesktop} ${spacing.wrapperDesktop} ${spacing.xlDesktop} ${spacing.wrapperDesktop}`,
    },
    minWidth: { xs: "100%", lg: "400px" },
    maxWidth: { xs: "100%", lg: "40%" },
    flex: "1 1 auto",
    backgroundColor: "white",
    borderLeft: `1px solid ${palette.grey.lightGrey}`,
    ...(showRightHideLeft
      ? {
          maxHeight: { xs: "calc(100vh - 48px)", lg: "unset" },
          overflowY: "auto",
          overflowX: "hidden",
        }
      : {}),
  },
});

export const CheckoutBreadcrumbsSteps: BreadcrumbStep[] = [
  {
    labelKey: "checkout.breadcrumb.home",
    path: ROUTES.HOME,
    icon: "home",
  },
  {
    labelKey: "checkout.breadcrumb.cart",
    path: ROUTES.CART,
  },
  {
    labelKey: "checkout.breadcrumb.delivery",
    path: ROUTES.SHIPPING_ADDRESS,
  },
];

interface Props {
  leftPanel: JSX.Element;
  rightPanel: JSX.Element;
}

export const CheckoutTemplate = ({ leftPanel, rightPanel }: Props) => {
  const { isMobile } = useThemeBreakpoints();
  const showRightHideLeft = usePathActive(ROUTES.SHIPPING_ADDRESS) && isMobile;
  const styles = useStyles(showRightHideLeft);

  return (
    <Box sx={styles.container}>
      <Box sx={styles.centerContainer}>
        <Box sx={styles.leftPanel}>
          {!isMobile && (
            <Breadcrumbs
              steps={CheckoutBreadcrumbsSteps}
              firstStepAsBackButton
            />
          )}
          <Box sx={styles.leftPanelContent}>{leftPanel}</Box>
        </Box>
        <Box sx={styles.rightPanel}>{rightPanel}</Box>
      </Box>
    </Box>
  );
};
