import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useTimeSince } from "../../utils/dateUtils";
import { getLanguage } from "../../utils/LocaleUtil";
import YourContactBox from "../ContactBox/ContactBox";
import styles from "./adminPreorderPriceAndShippingDetails.module.scss";
import { EditableInputAddressField } from "./EditableInputAddressField";
import EditableInputInvoiceField from "./EditableInputInvoiceField";
import EditableInputSelectField from "./EditableInputSelectField";
import EditableInputTextField from "./EditableInputTextField";

const AdminPreOrderPriceAndShippingDetails = (props) => {
  const { t } = useTranslation();
  const {
    preorderDetail,
    onUpdateProjectNumber,
    onUpdateInvoiceNumber,
    onUpdateShippingAddress,
    onUpdateBillingAddress,
    onUpdateDiscounts,
    onUpdateStatus,
    onUpdateCurrency,
  } = props;

  const ORDER_STATUS_ENUM = Object.freeze({
    INTENT: "intent",
    REVIEW_BY_DESIGNER: "inreviewbydesigner",
    REVIEW_BY_CUSTOMER: "inreviewbycustomer",
    PURCHASED: "purchased",
    IN_PRODUCTION: "inproduction",
    SHIPPED: "shipped",
    DELIVERED: "delivered",
    //FAILED: "failed",
    CANCELLED: "cancelled",
  });

  const CURRENCY_ENUM = Object.freeze({
    CAD: "cad",
    USD: "usd",
  });

  const statusOptions = useMemo(
    () =>
      Object.values(ORDER_STATUS_ENUM).map((status) => {
        return {
          value: status,
          label: t(`preorder.status.options.${status}`),
          disabled: false,
        };
      }),
    [ORDER_STATUS_ENUM, t]
  );

  const currencyOptions = useMemo(
    () =>
      Object.values(CURRENCY_ENUM).map((currency) => {
        return {
          value: currency,
          label: t(`preorder.currency.options.${currency}`),
          disabled: false,
        };
      }),
    [CURRENCY_ENUM, t]
  );

  const { dateFormatter } = useTimeSince();

  const dateTimeOptions = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    timeZoneName: "short",
  };

  return (
    <div className={styles.container}>
      <div className={styles.orderColumn}>
        <div className={styles.orderDate}>
          <span>
            <strong>{t("order.preordered_on")} </strong>
          </span>

          <span>
            {dateFormatter(
              preorderDetail.preorder.created_at
            ).toLocaleDateString(getLanguage(), dateTimeOptions)}
          </span>
        </div>
        <div className={styles.yourContact}>
          <span>
            <strong>{t("order.contact")}</strong>
          </span>
          <YourContactBox userDetails={preorderDetail.user} />
        </div>
      </div>
      <div className={styles.invoice}>
        <EditableInputTextField
          initialValue={preorderDetail?.invoice?.invoice_number}
          onUpdate={onUpdateInvoiceNumber}
          titleLabel={t("preorder.invoice_number")}
          editLabel={t("preorder.edit_invoice_number")}
          saveLabel={t("preorder.save")}
          cancelLabel={t("preorder.cancel_edit")}
          emptyLabel={t("order.not_yet_assigned")}
        />
      </div>

      <div className={styles.project}>
        <EditableInputTextField
          initialValue={preorderDetail?.techpack?.project_number}
          onUpdate={onUpdateProjectNumber}
          titleLabel={t("preorder.project_number")}
          editLabel={t("preorder.edit_project_number")}
          saveLabel={t("preorder.save")}
          cancelLabel={t("preorder.cancel_edit")}
          emptyLabel={t("order.not_yet_assigned")}
        />
      </div>
      <div className={styles.status}>
        <EditableInputSelectField
          initialValue={preorderDetail.preorder.status}
          options={statusOptions}
          onUpdate={onUpdateStatus}
          titleLabel={t("preorder.status.title")}
          editLabel={t("preorder.edit_status")}
          saveLabel={t("preorder.save")}
          cancelLabel={t("preorder.cancel_edit")}
          emptyLabel={t("order.not_yet_assigned")}
        />
      </div>

      <div className={styles.shipping}>
        <EditableInputAddressField
          initialValue={preorderDetail.shipping}
          onUpdate={onUpdateShippingAddress}
          titleLabel={t("order.delivery")}
          editLabel={t("preorder.edit_address")}
          saveLabel={t("preorder.save")}
          cancelLabel={t("preorder.cancel_edit")}
          emptyLabel={t("order.not_yet_assigned")}
        />
      </div>

      <div className={styles.shipping}>
        <EditableInputAddressField
          initialValue={preorderDetail.invoice.customer}
          onUpdate={onUpdateBillingAddress}
          titleLabel={t("order.billing")}
          editLabel={t("preorder.edit_address")}
          saveLabel={t("preorder.save")}
          cancelLabel={t("preorder.cancel_edit")}
          emptyLabel={t("order.not_yet_assigned")}
        />
      </div>

      {preorderDetail.promoCode && (
        <div className={styles.promoCode}>
          <b>Promo code</b> {preorderDetail.promoCode}
        </div>
      )}

      <div className={styles.orderTotal}>
        <EditableInputInvoiceField
          initialValue={preorderDetail.invoice}
          onUpdate={onUpdateDiscounts}
          titleLabel={t("order.order_summary")}
          editLabel={t("preorder.edit_discount")}
          saveLabel={t("preorder.save")}
          cancelLabel={t("preorder.cancel_edit")}
          emptyLabel={t("order.not_yet_assigned")}
        />
      </div>
      <div className={styles.currency}>
        <EditableInputSelectField
          initialValue={preorderDetail.invoice.currency}
          options={currencyOptions}
          onUpdate={onUpdateCurrency}
          titleLabel={t("preorder.currency.title")}
          editLabel={t("preorder.edit_currency")}
          saveLabel={t("preorder.save")}
          cancelLabel={t("preorder.cancel_edit")}
          emptyLabel={t("order.not_yet_assigned")}
        />
      </div>
    </div>
  );
};

export default AdminPreOrderPriceAndShippingDetails;
