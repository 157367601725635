import { boolean, object, string } from "yup";
import { testEmail } from "../../utils/FormValidationsUtils";

export const addressInitialValue = {
  organization: "",
  firstName: "",
  lastName: "",
  address: "",
  appartment: "",
  city: "",
  country: "CA",
  state: "QC",
  zipcode: "",
  phone: "",
  email: "",
};

const addressSchema = object().shape({
  organization: string().required(),
  firstName: string().required(),
  lastName: string().required(),
  address: string().required(),
  appartment: string(),
  city: string().required(),
  country: string().required(),
  state: string().required(),
  zipcode: string().required(),
  phone: string().required(),
  email: string().required().test(testEmail),
});

export const CartSchema = object().shape({
  shippingAddress: addressSchema,
  billingAddress: addressSchema,
  termsAndConditionsAccepted: boolean()
    .required()
    .oneOf([true], "The terms and conditions must be accepted"),
  finalDesignValidated: boolean()
    .required()
    .oneOf([true], "The final design must be validated"),
});
