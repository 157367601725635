import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import {
  FormLabel,
  Select as MuiSelect,
  SelectProps,
  SxProps,
} from "@mui/material";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { FormContext } from "../../../context";
import { getErrorMessage } from "../../../utils/FormValidationsUtils";
import { Box } from "../Box";
import { Label } from "../Typography";

interface Props extends Omit<SelectProps, "error"> {
  label?: string;
  link?: JSX.Element;
  error?: string;
  name?: SelectProps["name"];
  containerSx?: SxProps;
  noMaxWidth?: boolean;
  controlled?: boolean;
  "data-test"?: string;
}

export const Select = ({
  label,
  link,
  name = "",
  containerSx,
  noMaxWidth,
  onChange,
  error,
  controlled,
  ...props
}: Props) => {
  const { t } = useTranslation();
  const { formik } = useContext(FormContext);
  const isTouched = formik?.touched[name];

  return (
    <Box
      width="100%"
      maxWidth={noMaxWidth ? undefined : "400px"}
      display="flex"
      justifyItems="center"
      flexDirection="column"
      minWidth={props.fullWidth ? "100%" : undefined}
      sx={containerSx}
    >
      {link ? (
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={0.5}
        >
          {!!label && <Label htmlFor={props.id}>{label}</Label>}
          {link}
        </Box>
      ) : (
        !!label && (
          <Label htmlFor={props.id} sx={{ mb: 0.5, textAlign: "start" }}>
            {label}
          </Label>
        )
      )}

      <MuiSelect
        {...props}
        IconComponent={KeyboardArrowDown}
        onChange={
          formik && !controlled
            ? (e) => formik?.setFieldValue(name, e.target.value)
            : onChange
        }
      />
      {!!formik?.errors[name] && !!isTouched && (
        <FormLabel sx={{ textAlign: "start" }} color="error" error>
          {getErrorMessage(t, formik?.errors[name])}
        </FormLabel>
      )}
      {!!error && (
        <FormLabel sx={{ textAlign: "start" }} color="error" error>
          {getErrorMessage(t, error)}
        </FormLabel>
      )}
    </Box>
  );
};
