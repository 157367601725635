import { memo, useRef, useState } from "react";
import { palette } from "../../../styles/theme";
import { getRotationStyle } from "../../../utils/themeUtils";
import { Icon } from "../Icon";
import MenuControlled from "./MenuControlled";

export interface MenuOption {
  labelKey?: string;
  disabled?: boolean;
  isDivider?: boolean;
  onClick?: () => void;
  isChecked?: () => boolean;
  hideIf?: (() => boolean) | boolean;
  adminOnly?: boolean;
}

interface Props {
  iconSize?: number;
  options: MenuOption[];
  disableArrowRotation?: boolean;
}

const Menu = ({ options, iconSize = 16, disableArrowRotation }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLSpanElement>(null);

  return (
    <>
      <Icon
        ref={ref}
        icon={"expand_more"}
        size={iconSize}
        color={palette.grey.nearBlack}
        style={!disableArrowRotation ? getRotationStyle(isOpen) : undefined}
        onClick={() => setIsOpen(true)}
      />
      <MenuControlled
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        options={options}
        target={ref.current}
      />
    </>
  );
};

export default memo(Menu);
