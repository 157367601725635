import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Menu from "../../../common/components/Menu/Menu";

import { useTranslation } from "react-i18next";
import TeamService from "../../../service/TeamService";

import { Skeleton, Tooltip, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { object, string } from "yup";
import { Box, Form, InlineInput } from "../../../common/components";
import { useForm } from "../../../common/hooks/useForm";
import DeleteModal from "../../../components/DeleteModal/DeleteModal";
import { Styles, spacing } from "../../../styles/theme";
import { Team } from "../../../types";
import { useCart } from "../../../utils/CartHooks";
import { ROUTES } from "../../../utils/Constant";
import { useGlobalLoading } from "../../../utils/ContextHooks";
import { useCurrency } from "../../../utils/Currencies";
import { routeFormatter } from "../../../utils/Formatter";
import { useProjectFetcher } from "../../../utils/ProjectsFetcherHooks";
import { useTeamFetcher } from "../../../utils/TeamsFetcherHooks";
import {
  getScreenWidthMinusSpacing,
  useThemeBreakpoints,
} from "../../../utils/themeUtils";

const styles: Styles = {
  container: {
    display: "flex",
    gap: { xs: spacing.xsMobile, lg: spacing.xsDesktop },
    alignItems: "center",
    maxWidth: {
      xs: getScreenWidthMinusSpacing(
        spacing.wrapperMobile,
        spacing.wrapperMobile
      ),
      lg: getScreenWidthMinusSpacing("300px", "96px", "300px"),
    },
    overflowX: "auto",
    overflowY: "hidden !important",
  },
  title: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    maxWidth: {
      xs: getScreenWidthMinusSpacing(
        spacing.wrapperMobile,
        spacing.wrapperMobile,
        "32px"
      ),
      lg: getScreenWidthMinusSpacing("300px", "96px", "300px", "32px", "4px"),
    },
    marginBottom: "1px",
  },
};

const validationSchema = object({
  name: string().required("general.errors.required.input"),
});

interface Props {
  canDeleteTeam: boolean;
  canRenameTeam: boolean;
  team?: Team;
}

const TeamTopSection = ({ canDeleteTeam, canRenameTeam, team }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [editMode, setEditMode] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { isGloballyLoading } = useGlobalLoading();
  const { isMobile } = useThemeBreakpoints();
  const titleRef = useRef<HTMLParagraphElement>(null);

  const { fetchCartItemsAndUpdateStore } = useCart();
  const { fetchMyProjectsAndUpdateStore } = useProjectFetcher();
  const { fetchMyTeamsAndUpdateStore } = useTeamFetcher();
  const { getCurrency } = useCurrency();

  const formik = useForm({
    initialValues: { name: team?.name },
    onSubmit: async (values) => {
      if (team) {
        const trimmedValue = values.name.trim();
        const res = await TeamService.renameTeam({
          id: team.id,
          name: trimmedValue,
        });

        if (res.status === 200) {
          await fetchMyTeamsAndUpdateStore();
          setEditMode(false);
          formik.setFieldValue("name", trimmedValue);
        } else {
          formik.setFieldError("name", t("team.error_occured"));
        }
      }
    },
    validationSchema,
  });

  const onDeleteClickClose = () => {
    setShowDeleteModal(false);
  };

  const onDelete = useCallback(async () => {
    try {
      team && (await TeamService.deleteTeam({ id: team?.id }));
      //When a team is deleted, Route to home page
      navigate(routeFormatter(ROUTES.HOME));

      await fetchMyTeamsAndUpdateStore();
      await fetchMyProjectsAndUpdateStore();
      await fetchCartItemsAndUpdateStore(getCurrency()); //Because deleting a Project and it's designs can impact the cart
    } catch (e) {
      console.error(e);
      toast("Internal Issue deleting team, error is '" + String(e) + "'");
    } finally {
      setShowDeleteModal(false);
    }
  }, [
    team,
    fetchCartItemsAndUpdateStore,
    fetchMyProjectsAndUpdateStore,
    fetchMyTeamsAndUpdateStore,
    navigate,
    getCurrency,
  ]);

  const options = useMemo(
    () => [
      {
        labelKey: "team.edit_team_name",
        icon: "edit",
        disabled: !canRenameTeam,
        onClick: () => setEditMode(true),
      },
      {
        labelKey: "team.delete_menu",
        icon: "delete",
        disabled: !canDeleteTeam,
        onClick: () => setShowDeleteModal(true),
      },
    ],
    [canDeleteTeam, canRenameTeam]
  );

  const handleSubmit = async () => {
    const isValid = !!formik.values.name;
    if (isValid) {
      await formik.submitForm();
    } else {
      await formik.resetForm();
    }
    setEditMode(false);
  };

  useEffect(() => {
    formik.resetForm({ values: { name: team?.name } });
    // eslint-disable-next-line
  }, [team?.name]);

  const titleOverflowing = useMemo(() => {
    if (titleRef.current) {
      if (titleRef.current?.offsetWidth < titleRef.current?.scrollWidth) {
        return true;
      }
    }
    return false;
    // eslint-disable-next-line
  }, [titleRef.current?.offsetWidth]);

  if (isGloballyLoading || !team) {
    return <Skeleton variant="text" sx={{ width: 200, height: 32 }} />;
  }

  return (
    <Box sx={styles.container}>
      {editMode ? (
        <Form formik={formik} formProps={{ style: { width: "100%" } }}>
          <InlineInput
            name="name"
            autoFocus
            onBlur={handleSubmit}
            defaultValue={team.name}
            onKeyUp={(e) => e.key === "Enter" && handleSubmit()}
            inputStyle={{
              fontSize: isMobile ? 20 : 32,
              maxHeight: "100%",
              lineHeight: "normal",
            }}
            maxWidth={
              isMobile
                ? getScreenWidthMinusSpacing("16px", "16px")
                : getScreenWidthMinusSpacing("300px", "96px", "300px")
            }
            data-test="team.name.input"
          />
        </Form>
      ) : (
        <>
          <Tooltip
            title={team.name}
            disableHoverListener={!titleOverflowing}
            placement="top"
            arrow
          >
            <Typography
              variant="h1"
              sx={styles.title}
              onClick={() => setEditMode(true)}
              data-test={"team.name.title"}
              ref={titleRef}
            >
              {team.name}
            </Typography>
          </Tooltip>
          <Menu
            options={options}
            iconSize={isMobile ? 22 : 32}
            disableArrowRotation
          />
        </>
      )}
      <DeleteModal
        open={showDeleteModal}
        onDelete={onDelete}
        title={t("team.delete_modal_title")}
        message={t("team.delete_modal_message")}
        onClose={onDeleteClickClose}
      />
    </Box>
  );
};

export default TeamTopSection;
