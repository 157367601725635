import { Typography } from "@mui/material";
import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { NavLink } from "../../../../common/components";
import { AppToolbarContext } from "../../../../context/AppToolbar";

interface Props {
  label: string;
  labelKey?: string;
  url: string;
}

export const ProjectNavLink = (props: Props) => {
  const { t } = useTranslation();
  const { label, labelKey, url } = props;
  const navigate = useNavigate();
  const { toggleNavigationDrawerOpen } = useContext(AppToolbarContext);

  const content = useMemo(() => {
    if (label) {
      return label;
    }

    if (labelKey) {
      return t(labelKey);
    }

    return "";
  }, [label, labelKey, t]);

  return (
    <NavLink
      content={
        <Typography
          variant="actionSmallIcon"
          fontSize={12}
          maxWidth={{ xs: "230px", lg: "202px" }}
          textOverflow={"ellipsis"}
          whiteSpace={"nowrap"}
          overflow={"hidden"}
        >
          {content}
        </Typography>
      }
      to={url}
      onClick={() => {
        navigate(url);
        toggleNavigationDrawerOpen?.();
      }}
      clickableLink
    />
  );
};
