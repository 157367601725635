import { Typography } from "@mui/material";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useMoment } from "../../../common/hooks/useMoment";

interface Props {
  minWeeks: number;
  maxWeeks: number;
  fontSize?: string;
}

const DeliveryInfoMessage = ({
  minWeeks = 3,
  maxWeeks = 10,
  fontSize,
}: Props) => {
  const { t } = useTranslation();
  const { moment } = useMoment();

  const weeksFromNow = useCallback(
    (weeks: number) => {
      const weeksFromNow = new Date();
      weeksFromNow.setDate(weeksFromNow.getDate() + 7 * weeks + 5); //+5 is for the validation
      return moment(weeksFromNow).format("LL");
    },
    [moment]
  );

  const from = useMemo(() => {
    return weeksFromNow(minWeeks);
  }, [minWeeks, weeksFromNow]);

  const to = useMemo(() => {
    return weeksFromNow(maxWeeks);
  }, [maxWeeks, weeksFromNow]);

  return (
    <Typography variant="h2" fontSize={fontSize}>
      {t("cart.info.delivery_estimate.message", { from, to })}
    </Typography>
  );
};

export default DeliveryInfoMessage;
