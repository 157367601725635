import { Divider, Drawer, IconButton } from "@mui/material";
import { memo, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Box, Icon, NavLink } from "../../common/components";
import { OverlayScrollbarWrapper } from "../../common/components/OverlayScrollbar";
import { useSelector } from "../../common/hooks/useSelector";
import { JameoLogo } from "../../components/JameoLogo";
import { AppToolbarContext } from "../../context/AppToolbar";
import { palette, spacing } from "../../styles/theme";
import { EXTERNAL_ROUTE, ROUTES } from "../../utils/Constant";
import { AddTeam } from "./CreateTeam";
import styles from "./LeftNavigation.module.scss";
import { SearchTeams } from "./SearchTeams";
import { TeamProjects } from "./TeamProjects";
import { UserProfileDrawerContent, UserProfileNavLink } from "./UserProfile";

const LeftNavigationMobile = () => {
  const userInfos = useSelector((state) => state.userInfo);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [view, setView] = useState<"base" | "userProfile">("base");
  const { isNavigationDrawerOpen, toggleNavigationDrawerOpen } =
    useContext(AppToolbarContext);

  const onLinkClick = (link: string) => () => {
    navigate(link);
    toggleNavigationDrawerOpen?.();
  };

  return (
    <Drawer
      open={isNavigationDrawerOpen}
      onClose={toggleNavigationDrawerOpen}
      classes={{ paper: styles.drawer }}
    >
      {view === "base" ? (
        <OverlayScrollbarWrapper>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems={"center"}
            px={spacing.wrapperMobile}
            py={1.5}
            mb={spacing.wrapperMobile}
          >
            <JameoLogo />
            <IconButton onClick={toggleNavigationDrawerOpen}>
              <Icon icon="close" size={24} color={palette.grey.nearBlack} />
            </IconButton>
          </Box>
          <Box className={styles.container} px={spacing.wrapperMobile}>
            <UserProfileNavLink onClick={() => setView("userProfile")} />
            <NavLink
              to={ROUTES.HOME}
              startIcon={"home"}
              content={t("navigation.home")}
              onClick={onLinkClick(ROUTES.HOME)}
              clickableLink
            />

            <NavLink
              to={t("navigation.work_with_designer.linkUrl")}
              startIcon="palette"
              content={t("navigation.work_with_designer.linkText")}
              target="_blank"
              onClick={onLinkClick(t("navigation.work_with_designer.linkUrl"))}
              clickableLink
            />

            <NavLink
              to={EXTERNAL_ROUTE.SUPPORT}
              content={t("navigation.support")}
              startIcon="help"
              target="_blank"
              onClick={onLinkClick(EXTERNAL_ROUTE.SUPPORT)}
              clickableLink
            />

            {userInfos.canAddTeam && <AddTeam />}

            {userInfos && (userInfos.isAdmin || userInfos.isDesigner) && (
              <SearchTeams />
            )}
            <Divider />

            <div className={styles.teams} data-test="leftNavigation.teams">
              <TeamProjects canAddProject={userInfos.canAddProject} />
            </div>
          </Box>
        </OverlayScrollbarWrapper>
      ) : (
        <UserProfileDrawerContent onBackClick={() => setView("base")} />
      )}
    </Drawer>
  );
};

export default memo(LeftNavigationMobile);
