import React, { useCallback, useContext, useState } from "react";

import { useTranslation } from "react-i18next";
import { generatePath, useNavigate } from "react-router-dom";
import Menu from "../../../common/components/Menu/Menu";
import { AppLoadingContext } from "../../../context";
import DesignService from "../../../service/DesignService";
import DesignStateService from "../../../service/DesignStateService";
import { useCart } from "../../../utils/CartHooks";
import { ROUTES } from "../../../utils/Constant";
import { useCurrency } from "../../../utils/Currencies";
import { useDesignFetcher } from "../../../utils/DesignsFetcherHooks";
import CreatingCanvaModal from "../../CreatingCanvaModal";
import DeleteModal from "../../DeleteModal/DeleteModal";
import Modal from "../../Modal";
import MoveProjectModal from "../../MoveProjectModal/MoveProjectModal";
import RenameModal from "../../RenameModal/RenameModal";

interface Props {
  designId: string;
  designName: string;
  projectId: string;
  canDeleteDesign: boolean;
  canRenameDesign: boolean;
  canMoveDesign: boolean;
  canAddToCart: boolean;
  canDuplicateDesign: boolean;
}

const DesignMenu = (props: Props) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const {
    designId,
    designName,
    projectId,
    canDeleteDesign = true,
    canRenameDesign = true,
    canMoveDesign = true,
    canAddToCart = true,
    canDuplicateDesign = true,
  } = props;

  const [showRenameModal, setShowRenameModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [duplicating, setDuplicating] = useState(false);
  const [showMoveProjectModal, setShowMoveProjectModal] = useState(false);

  const { fetchMyDesignsAndUpdateStore } = useDesignFetcher();
  const { addToCart, fetchCartItemsAndUpdateStore } = useCart();
  const { getCurrency } = useCurrency();

  const onRenameCloseClick = () => {
    setShowRenameModal(false);
  };

  const onDeleteClickClose = () => {
    setShowDeleteModal(false);
  };
  const onMoveClickClose = () => {
    setShowMoveProjectModal(false);
  };
  const onRename = useCallback(
    async (name: string) => {
      try {
        await DesignService.renameDesign({ name, designId: designId });
        fetchMyDesignsAndUpdateStore(projectId);
      } catch (e) {
        console.error(e);
      } finally {
        setShowRenameModal(false);
      }
    },
    [designId, fetchMyDesignsAndUpdateStore, projectId]
  );

  const onDelete = useCallback(async () => {
    try {
      await DesignService.deleteDesign({ id: designId });
      await fetchMyDesignsAndUpdateStore(projectId);
      fetchCartItemsAndUpdateStore(getCurrency()); //Because deleting a design can impact the cart
    } catch (e) {
      console.error(e);
    } finally {
      setShowDeleteModal(false);

      navigate(generatePath(ROUTES.DESIGNS, { projectId }));
    }
  }, [
    projectId,
    designId,
    fetchMyDesignsAndUpdateStore,
    fetchCartItemsAndUpdateStore,
    navigate,
    getCurrency,
  ]);

  const { fetchProductDefinition } = useDesignFetcher();
  const { setIsAppLoading } = useContext(AppLoadingContext);

  const onAddToCart = async () => {
    //First get the Design State info of the design, then add to cart the design
    const {
      data: { design },
    } = await DesignStateService.getDesignState(designId);

    const {
      productDefinition: { sizes },
    } = await fetchProductDefinition(design.family, design.product);

    addToCart(designId, sizes, getCurrency(), design.product.name);
  };

  const onDuplicate = async () => {
    setIsAppLoading(true);
    setDuplicating(true);
    const res = await DesignStateService.duplicateDesignState({
      designId: designId,
    });

    const duplicatedDesignState = res.data;
    //setDuplicating(false);

    // const designId= firstPart.data.designId
    //TODO: add design name
    //      name: `${t("design.design")} (${designs.length + 1})`,
    try {
      await DesignService.addDesign(
        {
          projectId,
          designId: duplicatedDesignState.designId,
          name: `${designName + " 1"}`,
        },
        duplicatedDesignState.design.product
      );

      await fetchMyDesignsAndUpdateStore(projectId);
      setDuplicating(false);
      setIsAppLoading(false);
    } catch (e) {
      console.error(e);
    } finally {
      navigate(generatePath(ROUTES.DESIGNS, { projectId }));
    }
  };

  return (
    <React.Fragment>
      <RenameModal
        open={showRenameModal}
        onRename={onRename}
        oldName={designName || t("design.untitled_design")}
        onClose={onRenameCloseClick}
        title={t("design.rename_title")}
      />
      <Modal show={duplicating} key="creating">
        <CreatingCanvaModal />
      </Modal>
      <DeleteModal
        open={showDeleteModal}
        onDelete={onDelete}
        title={t("design.delete_modal_title")}
        message={t("design.delete_modal_message")}
        onClose={onDeleteClickClose}
      />
      <MoveProjectModal
        open={showMoveProjectModal}
        onClose={onMoveClickClose}
        designId={designId}
        projectId={projectId}
      />

      <Menu
        options={[
          {
            labelKey: "general.rename",
            onClick: () => setShowRenameModal(true),
            disabled: !canRenameDesign,
          },
          {
            labelKey: "design.delete_menu",
            onClick: () => setShowDeleteModal(true),
            disabled: !canDeleteDesign,
          },
          {
            labelKey: "design.add_to_cart",
            onClick: onAddToCart,
            disabled: !canAddToCart,
          },
          {
            labelKey: "design.duplicate",
            onClick: onDuplicate,
            disabled: !canDuplicateDesign,
          },
          {
            labelKey: "design.move_project",
            onClick: () => setShowMoveProjectModal(true),
            disabled: !canMoveDesign,
          },
        ]}
      />
    </React.Fragment>
  );
};

export default DesignMenu;
