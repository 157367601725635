import type { t as TranslationType } from "i18next";
import { Dictionary, isObject } from "lodash";
import { isValidPhoneNumber } from "react-phone-number-input";
import { AnyObject, TestConfig } from "yup";

export const testEmail: TestConfig<string | undefined, AnyObject> = {
  message: "general.errors.required.email",
  test: (email) => /^\S+@\S+(\.\S+)+$/i.test(email ?? ""),
};

export const testPassword: TestConfig<string | undefined, AnyObject> = {
  message: "general.errors.invalid.password",
  test: (password) => /^(?=.{8,}$).*$/i.test(password ?? ""),
};

export const testPhoneNumber: TestConfig<string, AnyObject> = {
  message: "general.errors.invalid.phoneNumber",
  test: (phoneNumber) => isValidPhoneNumber(phoneNumber ?? ""),
};

export const getErrorMessage = (
  t: typeof TranslationType,
  error?:
    | string
    | {
        key: string;
        values: Dictionary<string>;
      }
) => {
  if (!error) {
    return "";
  }
  if (typeof error === "string") {
    return t(error);
  }
  if (isObject(error)) {
    return t(error.key, error.values);
  }
};
