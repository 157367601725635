import { useCallback, useRef, useState } from "react";

import { useNavigate } from "react-router-dom";
import { Box, Icon, MenuControlled } from "../../../common/components";
import Avatar from "../../../common/components/Avatar/Avatar";
import { MenuOption } from "../../../common/components/Menu/Menu";
import { useAuth } from "../../../common/hooks/useAuth";
import { useSelector } from "../../../common/hooks/useSelector";
import { Styles, palette } from "../../../styles/theme";
import { ROUTES } from "../../../utils/Constant";

const styles: Styles = {
  container: {
    cursor: "pointer",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "4px",
    px: 1,
    "&:hover": {
      background: palette.blue.paleEffect,
    },
  },
  selectableMenuItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
};

const ProfileMenu = () => {
  const profileRef = useRef<HTMLDivElement>();
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);

  const userInfos = useSelector((state) => state.userInfo);

  const navigate = useNavigate();

  const { logout } = useAuth();

  const goToOrders = useCallback(() => {
    navigate(ROUTES.ORDERS);
  }, [navigate]);

  const goToAllOrders = useCallback(() => {
    navigate(ROUTES.ADMIN_PURCHASED_ORDERS);
  }, [navigate]);

  const goToReferralCodeValidation = useCallback(() => {
    navigate(ROUTES.ADMIN_VALIDATE_REFERRAL_CODE);
  }, [navigate]);

  const options: MenuOption[] = [
    {
      labelKey: "profile.all_orders",
      onClick: goToAllOrders,
      adminOnly: true,
    },
    {
      labelKey: "profile.validate_referral_code",
      onClick: goToReferralCodeValidation,
      adminOnly: true,
    },
    { isDivider: true, adminOnly: true },
    {
      labelKey: "profile.my_orders",
      onClick: goToOrders,
    },
    { isDivider: true },
    {
      labelKey: "general.logout",
      onClick: logout,
    },
  ];

  return (
    <>
      <Box
        ref={profileRef}
        onClick={() => setIsProfileMenuOpen((prev) => !prev)}
        sx={styles.container}
        data-test="userProfile.toggle"
      >
        <Avatar
          userId={userInfos.id}
          imageUrl={userInfos.imageUrl}
          initials={
            userInfos.firstName[0]?.toLocaleUpperCase() +
            userInfos.lastName[0]?.toLocaleUpperCase()
          }
          size="md"
        />
        <Icon icon={"expand_more"} size={16} color={palette.grey.nearBlack} />
      </Box>
      <MenuControlled
        options={options}
        isOpen={isProfileMenuOpen}
        onClose={() => setIsProfileMenuOpen(false)}
        target={profileRef.current}
      />
    </>
  );
};

export default ProfileMenu;
