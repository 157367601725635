import axios from "axios";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { matchPath, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import AuthService from "../service/authService";
import { ROUTES } from "../utils/Constant";

export function AxiosInterceptors() {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  useEffect(() => {
    setupInterceptors(navigate, location, t);
  }, [navigate, location, t]);

  // not rendering anything
  return null;
}

function setupInterceptors(navigate, location, t) {
  // Add a response interceptor
  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      console.log(error);

      //When the server is not responding (net::ERR_CONNECTION_REFUSED)
      if (!error.response) {
        console.error("Please check your internet connection.");
        toast.error(t("toast.connection_refused"), {
          toastId: "connection_refused",
        });
        return Promise.reject(error);
      }

      //catches if the session ended!
      const { status } = error.response;

      //When we reset password, it's ok if the use is not valid or password expired
      if (
        status === 401 &&
        !matchPath({ path: ROUTES.RESET_PASSWORD }, location.pathname) &&
        !matchPath({ path: ROUTES.UPDATE_PASSWORD }, location.pathname)
      ) {
        console.warn("EXPIRED TOKEN!");
        // toast.warn(t("toast.expired_token"), {
        //   toastId: "expired_token",
        // });
        navigate(ROUTES.LOGIN);
        AuthService.clearLocalUser();
      }

      if (status === 404) {
        console.error("Not found!");
        //TODO: navigate(ROUTES.NOT_FOUND);
      }

      if (status === 500) {
        toast.error(t("toast.server_error"), {
          toastId: "server_error",
        });
        console.error("Server error!");
      }

      return Promise.reject(error);
    }
  );
}
