import { useTranslation } from "react-i18next";

import { Divider, Typography } from "@mui/material";
import { Accordion, Box, FlexColBox } from "../../common/components";
import { useMoment } from "../../common/hooks/useMoment";
import { palette, spacing } from "../../styles/theme";
import { OrderDetail, OrderType } from "../../types";
import { useThemeBreakpoints } from "../../utils/themeUtils";
import { AddressBox } from "../AddressBox";
import YourContactBox from "../ContactBox/ContactBox";
import OrderItem from "./OrderItem";

interface Props {
  orderDetail: OrderDetail;
  shouldStartExpanded?: boolean;
  type: OrderType;
}

const Order = ({ orderDetail, shouldStartExpanded, type }: Props) => {
  const { t } = useTranslation();
  const { moment } = useMoment();
  const { isMobile } = useThemeBreakpoints();
  const data = type === "order" ? orderDetail.order : orderDetail.preorder;

  return (
    <Accordion
      renderContentOnExpanded={true}
      summaryText={
        <Typography variant="h2" color={palette.grey.nearBlack}>
          {moment(data?.createdAt).format("LL")}
        </Typography>
      }
      shouldStartExpand={shouldStartExpanded}
      accordionSxProps={{
        gap: 0,
      }}
      accordionSummarySxProps={{
        background: palette.grey.lightBackground,
        p: "16px",
        border: `1px solid ${palette.grey.lightGrey}`,
      }}
    >
      <FlexColBox
        gap={{ xs: spacing.regularMobile, lg: spacing.regularDesktop }}
      >
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          sx={{ "&>div": { flex: "1 0 0" } }}
          flexDirection={isMobile ? "column" : "row"}
          gap={isMobile ? "24px" : "0"}
        >
          {type === "preorder" && (
            <YourContactBox userDetails={orderDetail.user} />
          )}
          <AddressBox
            address={orderDetail.shipping}
            title={t("order.delivery")}
          />
          <AddressBox
            address={orderDetail.invoice.customer}
            title={t("order.billing")}
          />
        </Box>
        <Divider />
        {orderDetail.cartItems.map((item, index) => (
          <OrderItem key={`${item.id}-${index}`} item={item} />
        ))}
      </FlexColBox>
    </Accordion>
  );
};

export default Order;
