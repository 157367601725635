import { useCallback, useContext, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";

import { useTeamFetcher } from "../../../utils/TeamsFetcherHooks";

import { Typography } from "@mui/material";
import { generatePath, useNavigate } from "react-router-dom";
import { AdminIcon, Box, NavLink } from "../../../common/components";
import { useSelector } from "../../../common/hooks/useSelector";
import SearchTeamsModal from "../../../components/SearchTeamsModal/SearchTeamsModal";
import { AppToolbarContext } from "../../../context/AppToolbar";
import { spacing } from "../../../styles/theme";
import { ROUTES } from "../../../utils/Constant";

export const SearchTeams = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { fetchMyTeamsAndUpdateStore } = useTeamFetcher();
  const { toggleNavigationDrawerOpen } = useContext(AppToolbarContext);

  const { addToTeam } = useTeamFetcher();
  const teamsState = useSelector((state) => state.myTeams);
  const myTeams = useMemo(() => {
    return teamsState?.teams;
  }, [teamsState]);

  const [showSearchModal, setShowSearchModal] = useState(false);

  const onSearchCloseClick = () => {
    toggleNavigationDrawerOpen?.();
    setShowSearchModal(false);
  };

  const onClick = useCallback(() => {
    setShowSearchModal(true);
  }, [setShowSearchModal]);

  const onAddToTeam = useCallback(
    async (teamId: string) => {
      const result = await addToTeam(teamId);
      await fetchMyTeamsAndUpdateStore();
      navigate(generatePath(ROUTES.TEAM_PROJECTS, { teamId }));
      return result;
    },
    [addToTeam, fetchMyTeamsAndUpdateStore, navigate]
  );

  return (
    <Box display={"flex"} alignItems={"center"} gap={"4px"}>
      <NavLink
        onClick={onClick}
        to=""
        content={
          <Box
            display={"flex"}
            alignItems={"center"}
            gap={"4px"}
            width={"100%"}
          >
            <Typography variant="actionSmallIcon">
              {t("navigation.search_team")}
            </Typography>
            <AdminIcon />
          </Box>
        }
        startIcon="search"
        style={{ marginRight: spacing.mediumDesktop, width: "100%" }}
      />
      <SearchTeamsModal
        open={showSearchModal}
        myTeams={myTeams}
        onClickClose={onSearchCloseClick}
        onAddToTeam={onAddToTeam}
      />
    </Box>
  );
};
