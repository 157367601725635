import { useEffect, useState } from "react";
import { useSelector } from "../../common/hooks/useSelector";
import DesignStateService from "../../service/DesignStateService";
import { Specs } from "../../types";
import { SpecsContext } from "./SpecsContext";

interface Props {
  children: JSX.Element;
}

export const SpecsProvider = ({ children }: Props) => {
  const [specs, setSpecs] = useState<Specs | undefined>();
  const [loading, setLoading] = useState(true);

  const userInfo = useSelector((state) => state.userInfo);

  useEffect(() => {
    const getSpecs = async () => {
      setLoading(true);
      const result = await DesignStateService.getSpecs();
      setSpecs(result);
      setLoading(false);
    };
    if (userInfo.id) {
      getSpecs();
    }
  }, [userInfo.id]);

  return (
    <SpecsContext.Provider value={{ specs, loading }}>
      {children}
    </SpecsContext.Provider>
  );
};
