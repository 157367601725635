import { useTranslation } from "react-i18next";

import { Modal, Typography, Zoom } from "@mui/material";
import { memo } from "react";
import { Box, Icon } from "../../common/components";
import { palette, spacing, Styles } from "../../styles/theme";
import { DesignFamily, DesignProduct } from "../../types";
import { ProductList } from "../../utils/Constant";
import { ProductModalItem } from "./ProductModalItem";

const styles: Styles = {
  container: {
    p: { xs: spacing.wrapperMobile, lg: spacing.largeDesktop },
    pb: { xs: spacing.xlMobile, lg: spacing.largeDesktop },
    background: palette.white,
    minHeight: "100vh",
    maxHeight: "100vh",
    overflowY: "auto",
  },
  productListContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
};

interface Props {
  open: boolean;
  onCloseModal: () => void;
  createDesign: (
    family: DesignFamily,
    product: DesignProduct,
    labelTag: string
  ) => void;
}

const ProductModal = ({ createDesign, onCloseModal, open }: Props) => {
  const { t } = useTranslation();
  return (
    <Modal open={open} onClose={onCloseModal}>
      <Zoom in={open} style={{ transitionDelay: open ? "200ms" : "0ms" }}>
        <Box sx={styles.container}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            fullWidth
            mb={{ xs: spacing.wrapperMobile, lg: spacing.regularDesktop }}
          >
            <Typography variant="title" lineHeight="36px" fontWeight={600}>
              {t("products.modal.select_canvas")}
            </Typography>
            <Icon
              onClick={() => {
                onCloseModal();
              }}
              icon="close"
              color={palette.grey.nearBlack}
              size={28}
            />
          </Box>
          <Box
            sx={styles.productListContainer}
            gap={{ xs: spacing.smallMobile, lg: spacing.smallDesktop }}
          >
            {ProductList.map((product) => (
              <ProductModalItem
                {...product}
                onCreateDesign={createDesign}
                key={product.labelTag}
                dataTest={`selectCanvas.${product.product.name}`}
              />
            ))}
          </Box>
        </Box>
      </Zoom>
    </Modal>
  );
};

export default memo(ProductModal);
