import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { object, string } from "yup";
import {
  Button,
  CenteredColBox,
  ErrorLabel,
  Form,
  Link,
} from "../../../common/components";
import { Box } from "../../../common/components/Box/Box";
import { useForm } from "../../../common/hooks/useForm";
import { spacing } from "../../../styles/theme";
import { UserOnboardingInfo } from "../../../types";
import { PROJECT_TYPES } from "../../../utils/Constant";
import { OnboardingTitle } from "../Component/OnboardingTitle";
import { ProjectTypeListItem } from "./ProjectTypeListItem";

interface Props {
  onPreviousStep: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  setOnboardingInfo: React.Dispatch<React.SetStateAction<UserOnboardingInfo>>;
  onNextStep: () => void;
  onboardingInfo: UserOnboardingInfo;
}

const validationSchema = object({
  forWho: string().required(t("general.errors.required.listItem")),
});

export const ProjectTypeList = ({
  onPreviousStep,
  setOnboardingInfo,
  onNextStep,
  onboardingInfo,
}: Props) => {
  const { t } = useTranslation();
  const formik = useForm({
    initialValues: onboardingInfo,
    onSubmit: (values) => {
      setOnboardingInfo((prev) => ({ ...prev, forWho: values.forWho }));
      onNextStep();
    },
    validationSchema: validationSchema,
  });

  return (
    <Form formik={formik}>
      <Box fullWidth mt={{ xs: spacing.xlMobile, lg: 0 }}>
        <OnboardingTitle title={t("onboard.project_type.title")} />
        <CenteredColBox
          gap={{ xs: spacing.smallMobile, lg: spacing.smallDesktop }}
          fullWidth
        >
          {Object.values(PROJECT_TYPES).map((type) => {
            return (
              <ProjectTypeListItem
                key={type}
                active={formik.values.forWho === type}
                type={type}
                onClick={() => formik.setFieldValue("forWho", type)}
              />
            );
          })}
          <ErrorLabel error={formik.errors.forWho} />
        </CenteredColBox>
        <CenteredColBox
          gap={{ xs: spacing.ctaSpacingMobile, lg: spacing.ctaSpacingDesktop }}
          mt={{ xs: spacing.regularMobile, lg: spacing.regularDesktop }}
          fullWidth
          data-test="onboard.projectType.submitButton"
        >
          <Button type="submit" fullWidth>
            {t("sign_in.continue")}
          </Button>
          <Link to="" onClick={(e) => onPreviousStep(e)}>
            {t("onboard.back")}
          </Link>
        </CenteredColBox>
      </Box>
    </Form>
  );
};
