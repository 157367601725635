import axios from "axios";
import fileDownload from "js-file-download";
import i18n from "../app/i18n";
import AuthService from "./authService";
const USER_API_BASE_URL = process.env.REACT_APP_BACKEND_URL;

const BASE_ROUTE = "/api/v1/designs";

class DesignService {
  static async getDesigns() {
    return axios.get(
      USER_API_BASE_URL + BASE_ROUTE + "/",
      this.getAuthHeader()
    );
  }

  static async getProjectIdFromDesignId(designId) {
    return axios.get(
      USER_API_BASE_URL + BASE_ROUTE + `/${designId}/projectId`,
      this.getAuthHeader()
    );
  }

  static async addDesign(data, product) {
    const res = await axios.post(
      USER_API_BASE_URL + BASE_ROUTE + "/design",
      data,
      this.getAuthHeader()
    );

    const {
      data: {
        data: { id },
        status,
      },
    } = res;

    if (status === "success" && id) {
      window.analytics.track("Design Created", {
        product: product.name, // Hoodie, Hoodie Zip, etc. from Specs, not the name given by the user
      });
    }

    return res;
  }

  static async moveDesign(data) {
    return axios.post(
      USER_API_BASE_URL + BASE_ROUTE + "/movedesign",
      data,
      this.getAuthHeader()
    );
  }

  static async deleteDesign(data) {
    return axios.delete(
      USER_API_BASE_URL + BASE_ROUTE + "/design/" + data.id,
      this.getAuthHeader()
    );
  }

  static async renameDesign(data) {
    return axios.put(
      `${USER_API_BASE_URL}${BASE_ROUTE}/design/name`,
      data,
      this.getAuthHeader()
    );
  }

  static async postCollectSize(data) {
    return axios.post(`${USER_API_BASE_URL}${BASE_ROUTE}/collectSize`, data);
  }

  static async changeDescription(data) {
    return axios.put(
      `${USER_API_BASE_URL}${BASE_ROUTE}/design/description`,
      data,
      this.getAuthHeader()
    );
  }

  static async updateCollectable(data) {
    return axios.put(
      USER_API_BASE_URL + BASE_ROUTE + "/design/collectable",
      data,
      this.getAuthHeader()
    );
  }

  static getAuthHeader() {
    return { headers: { "x-access-token": AuthService.getJWT() } };
  }

  static async newsLetterSubscribe(email, lang) {
    const data = { email: `${email}`, lang: `${lang} ` };
    try {
      await axios.post(
        `${USER_API_BASE_URL}${BASE_ROUTE}/newsletter`,
        data,
        this.getAuthHeader()
      );
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }

  static async getDesignAsAdmin(designId, userIdToValidate) {
    const data = {
      designId: designId,
      userId: userIdToValidate,
    };
    return axios.post(
      `${USER_API_BASE_URL}${BASE_ROUTE}/design/search`,
      data,
      this.getAuthHeader()
    );
  }

  static async getRecentDesigns() {
    return axios.get(
      `${USER_API_BASE_URL}${BASE_ROUTE}/recent`,
      this.getAuthHeader()
    );
  }

  static async setFavoriteDesign(designId, isFavorite) {
    return axios.put(
      `${USER_API_BASE_URL}${BASE_ROUTE}/design/favorite`,
      {
        designId,
        isFavorite,
      },
      this.getAuthHeader()
    );
  }

  static async createMockup(designId, formValues) {
    const resS3Url = await axios.post(
      `${USER_API_BASE_URL}${BASE_ROUTE}/design/${designId}/generateMockupPdf/${i18n.language}`,
      {
        ...formValues,
      },
      this.getAuthHeader()
    );
    const res = await axios.get(resS3Url.data.data, {
      responseType: "blob",
    });
    fileDownload(res.data, `${formValues.company}-${formValues.mockupId}.pdf`);
  }
}

export default DesignService;
