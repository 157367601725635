import { Typography } from "@mui/material";
import { createStyles } from "@mui/styles";
import { palette } from "../../../styles/theme";
import { Box } from "../Box";
import { BoxProps } from "../Box/Box";
import { Icon } from "../Icon";
import { MaterialSymbolType } from "../Icon/IconConstant";

interface Props {
  type: "success" | "error" | "warning";
  message: string;
  boxProps?: BoxProps;
  icon?: MaterialSymbolType;
}

const useStyles = (type: Props["type"]) =>
  createStyles({
    container: {
      padding: 2,
      borderRadius: 1,
      display: "flex",
      alignItems: "center",
      background: palette.misc[`${type}Bg`],
      border: `1px solid ${palette.misc[`${type}Border`]}`,
    },
  });

export const Alert = ({ type, message, boxProps, icon }: Props) => {
  const styles = useStyles(type);
  return (
    <Box sx={styles.container} gap={1.5} {...boxProps}>
      {icon && <Icon icon={icon} size={24} color={palette.grey.primary} />}
      <Typography color={palette.misc[`${type}Text`]} variant="body2">
        {message}
      </Typography>
    </Box>
  );
};
