import { Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, FlexColBox } from "../../../common/components";
import { CalculatePriceModal } from "../../../components/CalculatePriceModal/CalculatePriceModal";
import { palette, spacing } from "../../../styles/theme";
import { CartItemPricing, DesignPricing } from "../../../types";
import { useCurrency } from "../../../utils/Currencies";
import { useDesignState } from "../../../utils/ReactHooks";

interface Props {
  deprecatedDesign: boolean;
  pricing: CartItemPricing;
  designId: string;
  productName: string;
  designPricing: DesignPricing;
  forCartItem?: boolean;
}

const CartItemPricingInfo = ({
  deprecatedDesign,
  pricing,
  forCartItem,
  productName,
  designId,
  designPricing,
}: Props) => {
  const { NarrowCurrencyFormatter } = useCurrency();
  const { t } = useTranslation();
  const [isPriceModalOpen, setIsPriceModalOpen] = useState(false);

  const { pricingDef, sizesOption } = useDesignState(undefined, designId);

  const isSocks = productName.includes("Socks");

  return (
    <FlexColBox gap={{ xs: spacing.xxsMobile, lg: spacing.xxsDesktop }}>
      {!deprecatedDesign &&
        pricing.hasMinimum &&
        pricing.hasMinimumPerSize &&
        !pricing.hasReachedMaximum && (
          <Box display={"flex"} alignItems={"center"}>
            <Typography
              variant="textSm"
              color={palette.grey.primary}
            >{`${pricing.qty} x`}</Typography>
            <Typography
              variant="textSm"
              color={
                forCartItem ? palette.blue.secondary : palette.grey.primary
              }
              onClick={
                forCartItem ? () => setIsPriceModalOpen(true) : undefined
              }
            >
              &nbsp;{`${NarrowCurrencyFormatter?.format(pricing.pricePerUnit)}`}
            </Typography>
          </Box>
        )}
      {!deprecatedDesign &&
        pricing.hasMinimum &&
        pricing.hasMinimumPerSize &&
        pricing.hasReachedMaximum && (
          <Typography variant="textSm" color={palette.grey.primary}>
            {t(`checkout.quantityOnly.${isSocks ? "socks" : "others"}`, {
              count: pricing.qty,
            })}
          </Typography>
        )}
      {!deprecatedDesign && !pricing.hasMinimum && (
        <Typography variant="textSm" color={palette.red.error}>
          {t(`checkout.minimum_qty.${isSocks ? "socks" : "others"}`, {
            minQty: pricing.minQty,
          })}
        </Typography>
      )}
      {!deprecatedDesign && !pricing.hasMinimumPerSize && (
        <Typography variant="textSm" color={palette.red.error}>
          {t(`checkout.minimum_qty_per_size.${isSocks ? "socks" : "others"}`, {
            minQtyPerSize: pricing.minQtyPerSize,
          })}
        </Typography>
      )}
      {!!pricingDef && !!sizesOption && forCartItem && (
        <CalculatePriceModal
          open={isPriceModalOpen}
          onClose={() => setIsPriceModalOpen(false)}
          designId={designId}
          productName={productName}
          designPricing={designPricing}
          pricingDef={pricingDef}
          sizesOption={sizesOption}
        />
      )}
    </FlexColBox>
  );
};

export default CartItemPricingInfo;
