import {
  Divider,
  IconButton,
  SwipeableDrawer,
  Typography,
} from "@mui/material";
import { memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { object, string } from "yup";
import { Styles, palette, spacing } from "../../../styles/theme";
import { getScreenWidthMinusSpacing } from "../../../utils/themeUtils";
import { useForm } from "../../hooks/useForm";
import { useTimeSince } from "../../hooks/useTimeSince";
import { Box } from "../Box";
import { Form } from "../Form";
import { Icon } from "../Icon";
import { InlineInput } from "../Input";
import { MenuOption } from "./Menu";

const styles: Styles = {
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    px: spacing.wrapperMobile,
  },
  name: {
    color: palette.grey.nearBlack,
    fontFeatureSettings: "'clig' off, 'liga' off",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "24px",
  },
  date: {
    px: spacing.wrapperMobile,
    color: palette.grey.primary,
    fontFeatureSettings: "'clig' off, 'liga' off",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "24px",
  },
  item: {
    px: spacing.wrapperMobile,
    py: 1,
    ":hover": {
      background: palette.blue.pale,
    },
  },
};

const validationSchema = object({
  name: string().required("general.errors.required.input"),
});

interface Props {
  isOpen: boolean;
  onClose: () => void;
  options: MenuOption[];
  name: string;
  updatedAt: Date;
  onSave: (name: string) => void;
}

const EntityMenuMobile = ({
  options,
  isOpen,
  onClose,
  name,
  updatedAt,
  onSave,
}: Props) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const { timeSince } = useTimeSince(updatedAt);
  const formik = useForm({
    initialValues: { name },
    onSubmit: (values) => {
      const trimmedValue = values.name.trim();
      onSave(trimmedValue);
      formik.setFieldValue("name", trimmedValue);
    },
    validationSchema,
  });

  const { t } = useTranslation();

  const submit = async () => {
    const isValid = !!formik.values.name;
    if (isValid) {
      await formik.submitForm();
    } else {
      await formik.resetForm();
    }
  };

  const handleSubmitAndClose = () => {
    formik.dirty && submit();
    setIsEditMode(false);
    onClose();
  };

  // eslint-disable-next-line
  useEffect(() => formik.setErrors({}), [isOpen]);

  return (
    <SwipeableDrawer
      anchor={"bottom"}
      disableSwipeToOpen
      open={isOpen}
      onClose={handleSubmitAndClose}
      onOpen={() => undefined}
      sx={styles.container}
      data-test={`entityMenuMobile.${name}`}
    >
      <Form formik={formik} data-test="entityMenuMobile.form">
        <Box
          sx={styles.header}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          data-test="entityMenuMobile.header"
        >
          {isEditMode ? (
            <InlineInput
              name="name"
              autoFocus
              onBlur={handleSubmitAndClose}
              onKeyUp={(e) => e.key === "Enter" && handleSubmitAndClose()}
              maxWidth={getScreenWidthMinusSpacing(
                spacing.wrapperMobile,
                spacing.wrapperMobile,
                "8px", //to create a gap between the icon and the input
                "28px" // size of the icon
              )}
            />
          ) : (
            <Box
              display="flex"
              alignItems="center"
              data-test="entityMenuMobile.readOnly.name"
            >
              <Typography sx={styles.name}>{formik.values.name}</Typography>
              <IconButton
                onClick={() => setIsEditMode(true)}
                data-test="entityMenuMobile.editIcon"
              >
                <Icon icon="edit" size={24} color={palette.grey.nearBlack} />
              </IconButton>
            </Box>
          )}
          <IconButton onClick={handleSubmitAndClose}>
            <Icon icon="close" size={24} color={palette.grey.nearBlack} />
          </IconButton>
        </Box>
        <Typography sx={styles.date}>{timeSince}</Typography>
        <Divider sx={{ my: spacing.mediumMobile }} />
        <Box display={"flex"} flexDirection={"column"}>
          {options.map((option, index) => {
            if (
              (typeof option.hideIf === "function" && option.hideIf?.()) ||
              option.hideIf === true
            ) {
              return null;
            }
            if (option.isDivider) {
              return <Divider key={index} />;
            }

            return (
              <Typography
                sx={styles.item}
                onClick={option.onClick}
                data-test={option.labelKey}
                variant="actionSmallIcon"
                key={option.labelKey}
              >
                {option.labelKey && t(option.labelKey)}
              </Typography>
            );
          })}
        </Box>
      </Form>
    </SwipeableDrawer>
  );
};

export default memo(EntityMenuMobile);
