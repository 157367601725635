import { Typography } from "@mui/material";
import { ChangeEvent, useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import "react-phone-number-input/style.css";
import { Box, FlexColBox } from "../../common/components";
import Checkbox from "../../common/components/Checkbox/Checkbox";
import { usePageTitle } from "../../common/hooks/usePageTitle";
import { FormContext } from "../../context";
import { spacing } from "../../styles/theme";
import { AddressFormType } from "../../types";
import { useCurrency } from "../../utils/Currencies";
import { useThemeBreakpoints } from "../../utils/themeUtils";
import { CheckoutForm } from "../Cart/Cart";
import AddressForm from "./Address/AddressForm";
import CAProvinces from "./provinces.json";
import styles from "./shippingAdressContainer.module.scss";
import USStates from "./states.json";

export const ShippingAddressContainer = () => {
  const { t } = useTranslation();
  const { isMobile } = useThemeBreakpoints();
  usePageTitle(t("pages.cartAddress.title"));
  const { formik } = useContext(FormContext);

  const { sameAsShippingAddress, billingAddress, shippingAddress } =
    formik?.values as CheckoutForm;

  const { setUSD, setCAD } = useCurrency();

  const onBillingFieldChange = useCallback(
    (e: any, field: keyof AddressFormType) => {
      formik?.setFieldValue(`billingAddress.${field}`, e.target.value);

      //If country changes, the province/state must change also (using the first entry in the list)
      if (field === "country") {
        const stateList = e.target.value === "US" ? USStates : CAProvinces;
        const newCountryState = stateList[0];
        formik?.setFieldValue(`billingAddress.state`, newCountryState);
      }
    },
    [formik]
  );

  const onShippingFieldChange = useCallback(
    (e: any, field: keyof AddressFormType) => {
      formik?.setFieldValue(`shippingAddress.${field}`, e.target.value);

      //Propagate the field change to Billing if same as shipping
      if (sameAsShippingAddress) {
        onBillingFieldChange(e, field);
      }
    },
    [formik, onBillingFieldChange, sameAsShippingAddress]
  );

  const onShippingCountryChange = useCallback(
    (e: any) => {
      const newCountry = e.target.value;
      formik?.setFieldValue(`shippingAddress.country`, newCountry);

      //Propagate the field change to Billing if same as shipping
      if (sameAsShippingAddress) {
        onBillingFieldChange(e, "country");
      }

      // Update the states and select the first one
      const stateList = newCountry === "US" ? USStates : CAProvinces;
      const newCountryState = stateList[0];
      formik?.setFieldValue(`shippingAddress.state`, newCountryState);

      //Propagate the field change to Billing if same as shipping
      if (sameAsShippingAddress) {
        onBillingFieldChange(e, "country");
        onBillingFieldChange({ target: { value: newCountryState } }, "state");
      }

      if (newCountry === "US") {
        setUSD();
      } else {
        setCAD();
      }
    },
    [formik, onBillingFieldChange, sameAsShippingAddress, setCAD, setUSD]
  );

  const onBillingCountryChange = useCallback(
    (e: any) => {
      const newCountry = e.target.value;

      formik?.setFieldValue(`billingAddress.country`, newCountry);

      // Update the states and select the first one
      const stateList = newCountry === "US" ? USStates : CAProvinces;
      const newCountryState = stateList[0];
      formik?.setFieldValue(`billingAddress.state`, newCountryState);

      if (newCountry === "US") {
        setUSD();
      } else {
        setCAD();
      }
    },
    [formik, setCAD, setUSD]
  );

  const onSameAddressAsShipping = useCallback(
    (event: ChangeEvent<HTMLInputElement>, sameAsShippingAddress: boolean) => {
      formik?.setFieldValue(`billingAddress`, shippingAddress);
      formik?.setFieldValue(`sameAsShippingAddress`, sameAsShippingAddress);
    },
    [formik, shippingAddress]
  );

  return (
    <FlexColBox alignItems={!isMobile ? "end" : "center"}>
      <FlexColBox maxWidth={!isMobile ? 640 : undefined} width={"100%"}>
        <FlexColBox
          display="flex"
          flexDirection={"column"}
          gap={{ xs: spacing.largeMobile, lg: spacing.largeDesktop }}
        >
          <Box
            display="flex"
            flexDirection={"column"}
            gap={{ xs: spacing.groupingMobile, lg: spacing.groupingDesktop }}
          >
            <Typography variant="h2">
              {t("checkout.delivery_address")}
            </Typography>
            <AddressForm
              onAddressFieldChange={onShippingFieldChange}
              onAddressCountryChange={onShippingCountryChange}
              address={shippingAddress}
            />
          </Box>
          <FlexColBox
            gap={{ xs: spacing.groupingMobile, lg: spacing.groupingDesktop }}
          >
            <FlexColBox
              gap={{ xs: spacing.groupingMobile, lg: spacing.groupingDesktop }}
            >
              <Typography variant="h2">
                {t("checkout.billing_address")}
              </Typography>
              <Checkbox
                className={styles.sameAsShippingCheckbox}
                onChange={onSameAddressAsShipping}
                label={t("checkout.same_as_shipping")}
                defaultChecked={sameAsShippingAddress}
              />
            </FlexColBox>
            {!sameAsShippingAddress && (
              <AddressForm
                onAddressCountryChange={onBillingCountryChange}
                onAddressFieldChange={onBillingFieldChange}
                address={billingAddress}
              />
            )}
          </FlexColBox>
        </FlexColBox>
      </FlexColBox>
    </FlexColBox>
  );
};
