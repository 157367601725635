import { Typography } from "@mui/material";
import { memo, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Box, FlexColBox } from "../../common/components";
import { usePageTitle } from "../../common/hooks/usePageTitle";
import { useSelector } from "../../common/hooks/useSelector";
import { Message, MessageTypes } from "../../components/Message/Message";
import Order from "../../components/Order/Order";
import { spacing, Styles } from "../../styles/theme";
import { OrderType } from "../../types";
import { useGlobalLoading } from "../../utils/ContextHooks";
import { useOrderFetcher } from "../../utils/OrdersFetcherHooks";
import { usePreOrderFetcher } from "../../utils/PreOrdersFetcherHooks";
import OrdersSkeleton from "./OrdersSkeleton";

const styles: Styles = {
  centerContainer: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
    flex: "1",
    padding: {
      xs: `16px 16px ${spacing.xlMobile}`,
      lg: `${spacing.regularDesktop} ${spacing.largeDesktop} ${spacing.xlDesktop}`,
    },
    overflow: "auto",
    gap: { xs: spacing.regularMobile, lg: spacing.regularDesktop },
  },
};

const Orders = () => {
  const { t } = useTranslation();
  const params = useParams();
  const { isGloballyLoading } = useGlobalLoading();
  usePageTitle(t("pages.orders.title"));

  //In case the page comes from checkout success of Stripe, we get the successful sessionId
  const sessionId = params.sessionId;

  //In case the page comes from checkout using preorder
  const preorderId = params.preorderId;

  //At load, fetch all orders to get the latest information
  const { fetchMyOrdersAndUpdateStore } = useOrderFetcher();
  useEffect(() => {
    fetchMyOrdersAndUpdateStore();
  }, [fetchMyOrdersAndUpdateStore]);

  //At load, fetch all preorders to get the latest information
  const { fetchMyPreOrdersAndUpdateStore } = usePreOrderFetcher();
  useEffect(() => {
    fetchMyPreOrdersAndUpdateStore();
  }, [fetchMyPreOrdersAndUpdateStore]);

  const ordersState = useSelector((state) => state.myOrders);
  const preordersState = useSelector((state) => state.myPreOrders);

  const orders = useMemo(() => {
    return ordersState?.orders;
  }, [ordersState]);

  const ordersLoaded = useMemo(() => {
    return ordersState?.loaded;
  }, [ordersState]);

  const preorders = useMemo(() => {
    return preordersState?.preorders;
  }, [preordersState]);

  const preordersLoaded = useMemo(() => {
    return preordersState?.loaded;
  }, [preordersState]);

  //Merge both orders and pre-orders
  const orderAndPreorderList = useMemo(() => {
    if (!preordersLoaded || !ordersLoaded) {
      return [];
    }

    const ordersArray = Array.isArray(orders)
      ? orders.map((order) => {
          return {
            type: "order" as OrderType,
            details: order,
            createdAt: order.order?.created_at,
          };
        })
      : [];
    const preordersArray = Array.isArray(preorders)
      ? preorders.map((preorder) => {
          return {
            type: "preorder" as OrderType,
            details: preorder,
            createdAt: preorder.preorder?.created_at,
          };
        })
      : [];

    const mergedArray = [...ordersArray, ...preordersArray];

    const sortedMergedArray = mergedArray
      .filter((x) => !!x.createdAt)
      .sort((a, b) => (a.createdAt! < b.createdAt! ? 1 : -1));

    return sortedMergedArray;
  }, [orders, preorders, preordersLoaded, ordersLoaded]);

  //Analytics
  useEffect(() => {
    if (sessionId && ordersLoaded) {
      const orderFound = orders.find(
        (order) => order.invoice.checkoutSessionId === sessionId
      );
      if (orderFound) {
        const taxes = orderFound.invoice.taxes.reduce(
          (partialSum, taxItem) => partialSum + taxItem.amount,
          0
        );

        //Analytics
        window.analytics.track("Order Completed", {
          order_id: orderFound.order?.id, // Or do we want the Stripe id (checkoutSesssion.id)?
          value: orderFound.invoice.subtotal + orderFound.invoice.shipping, // subtotal including shipping
          total: orderFound.invoice.total,
          revenue: orderFound.invoice.subtotal, // i.e. subtotal before shipping
          shipping: orderFound.invoice.shipping,
          currency: orderFound.invoice.currency, // USD or CAD
          tax: taxes,
          products: orderFound.cartItems
            .map((cartItem) => {
              const productItem = {
                product_id: cartItem.productName,
                sku: cartItem.designId,
                name: cartItem.productName,
                price: cartItem.pricing.pricePerUnit,
                quantity: cartItem.pricing.qty,
              };

              if (cartItem.pricing.priceOfExtraSizes > 0) {
                const productExtraSizes = {
                  product_id: "Extra sizes",
                  sku: "Extra sizes",
                  name: "Extra sizes",
                  price: cartItem.pricing.priceOfExtraSizes,
                  quantity: 1,
                };
                return [productExtraSizes, productItem];
              } else {
                return productItem;
              }
            })
            .flat(),
        });
      }
    }
  }, [sessionId, ordersLoaded, orders]);
  // End of Analytics

  if (ordersState.loading || preordersState.loading || isGloballyLoading) {
    return <OrdersSkeleton />;
  }

  return (
    <Box sx={styles.centerContainer}>
      {sessionId && (
        <Message
          message={t("order.info.order_acknowledged.message")}
          messageIcon="check_circle"
          fullWidth={true}
          type={MessageTypes.SUCCESS}
        />
      )}
      {preorderId && (
        <Message
          message={t("order.info.request_acknowledged.message")}
          messageIcon="check_circle"
          fullWidth={true}
          type={MessageTypes.SUCCESS}
        />
      )}
      <Typography variant="h1">{t("order.title")}</Typography>
      <FlexColBox gap={{ xs: spacing.mediumMobile, lg: spacing.mediumDesktop }}>
        {orderAndPreorderList.length ? (
          orderAndPreorderList?.map((listItem, index) =>
            listItem.type === "order" ? (
              <Order
                type={listItem.type}
                orderDetail={listItem.details}
                key={`${index}-${listItem.details.order?.id}`}
                shouldStartExpanded={
                  !!(
                    sessionId &&
                    listItem.details.invoice.checkoutSessionId === sessionId
                  )
                }
              />
            ) : (
              <Order
                type={listItem.type}
                orderDetail={listItem.details}
                key={`${index}-${listItem.details.preorder?.id}`}
                shouldStartExpanded={
                  !!(preorderId && listItem.details.preorder?.id === preorderId)
                }
              />
            )
          )
        ) : (
          <div className="page-title-box">
            <p className="page-title">{t("order.no_orders")}</p>
          </div>
        )}
      </FlexColBox>
    </Box>
  );
};

export default memo(Orders);
