import { useTranslation } from "react-i18next";
import { components } from "react-select";
import { AdminIcon } from "../../../Icon";
import styles from "./Menu.module.scss";

const ColorMenu = ({ children, ...props }) => {
  const { enableCustomColorSelection, onSelectCustomColor } = props;
  const { t } = useTranslation();

  return (
    <components.Menu {...props}>
      {children}
      {enableCustomColorSelection && (
        <div>
          <button className={styles.adminButton} onClick={onSelectCustomColor}>
            {t(`colorSelect.customColor`)}
            <AdminIcon />
          </button>
        </div>
      )}
    </components.Menu>
  );
};

export default ColorMenu;
