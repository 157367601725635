import {
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  SxProps,
  Typography,
} from "@mui/material";
import {
  PropsWithChildren,
  isValidElement,
  memo,
  useEffect,
  useState,
} from "react";
import { Styles, palette, spacing } from "../../../styles/theme";
import {
  getConditionnalStyle,
  getRotationStyle,
  mergeSx,
  useThemeBreakpoints,
} from "../../../utils/themeUtils";
import { Icon } from "../../components";

const useStyles = (expanded: boolean): Styles => ({
  accordion: {
    boxShadow: "none",
    width: "100%",
  },
  accordionSummary: {
    minHeight: "unset",
    ...(expanded
      ? {
          borderTopLeftRadius: "8px !important",
          borderTopRightRadius: "8px !important",
          borderBottomLeftRadius: "0px !important",
          borderBottomRightRadius: "0px !important",
        }
      : { borderRadius: "8px !important" }),
    "&>div": {
      margin: 0,
    },
    width: "100%",
    padding: "8px",
    fontStyle: "normal",
    fontWeight: "normal",

    display: "flex",
    flexDirection: "row",
    cursor: "pointer",

    alignItems: "center",
    gap: "8px",
  },
  accordionDetails: {
    // padding: "0px",'
    borderBottomLeftRadius: "8px !important",
    borderBottomRightRadius: "8px !important",
    borderBottom: `1px solid ${palette.grey.lightGrey}`,
    borderLeft: `1px solid ${palette.grey.lightGrey}`,
    borderRight: `1px solid ${palette.grey.lightGrey}`,
    p: { xs: spacing.wrapperMobile, lg: spacing.wrapperDesktop },
    "& hr": {
      border: "none",
      borderTop: `1px solid ${palette.grey.lightGrey}`,
      p: 0,
      my: "0",
    },
    // "&>*:not(hr)": {
    //   p: "8px 28px !important",
    // },
    "&>a": {
      "&>div": {
        p: 0,
      },
    },
  },
});

interface Props extends PropsWithChildren {
  summaryText: string | JSX.Element;
  startIcon?: JSX.Element;
  renderContentOnExpanded?: boolean;
  accordionSxProps?: SxProps;
  accordionSummarySxProps?: SxProps;
  accordionDetailsSxProps?: SxProps;
  shouldStartExpand?: boolean;
}

const Accordion = ({
  summaryText,
  startIcon,
  renderContentOnExpanded = true,
  accordionSxProps,
  accordionSummarySxProps,
  accordionDetailsSxProps,
  shouldStartExpand,
  children,
}: Props) => {
  const [expanded, setExpanded] = useState(shouldStartExpand ?? false);
  const styles = useStyles(expanded);
  const [showContent, setShowContent] = useState(
    renderContentOnExpanded ? expanded : true
  );
  const { isMobile } = useThemeBreakpoints();

  useEffect(() => {
    if (expanded) {
      setShowContent(true);
    }
  }, [expanded]);

  return (
    <MuiAccordion
      sx={mergeSx(styles.accordion, accordionSxProps)}
      disableGutters
      expanded={expanded}
      onChange={() => undefined}
    >
      <MuiAccordionSummary
        onClick={() => {
          setExpanded((prev) => !prev);
        }}
        expandIcon={
          <Icon
            size={isMobile ? 18 : 22}
            icon="expand_more"
            className="accordionExpandButton"
            style={{ ...getRotationStyle(expanded) }}
          />
        }
        aria-controls="panel1-content"
        id="panel1-header"
        sx={[
          styles.accordionSummary,
          getConditionnalStyle(
            accordionSummarySxProps,
            accordionSummarySxProps
          ),
        ]}
      >
        {startIcon}
        {isValidElement(summaryText) ? (
          summaryText
        ) : (
          <Typography
            color={palette.grey.nearBlack}
            variant="body2"
            minWidth="100%"
            fontSize={{ xs: 12, lg: 14 }}
            lineHeight={{ xs: "18px", lg: "22px" }}
          >
            {summaryText}
          </Typography>
        )}
      </MuiAccordionSummary>
      <MuiAccordionDetails
        sx={[
          styles.accordionDetails,
          getConditionnalStyle(
            accordionDetailsSxProps,
            accordionDetailsSxProps
          ),
        ]}
      >
        {showContent ? children : null}
      </MuiAccordionDetails>
    </MuiAccordion>
  );
};

export default memo(Accordion);
