import { useEffect } from "react";

import { generatePath, useNavigate, useParams } from "react-router-dom";
import TeamService from "../../service/TeamService";
import { ROUTES } from "../../utils/Constant";
import { useDesignFetcher } from "../../utils/DesignsFetcherHooks";
import { useProjectFetcher } from "../../utils/ProjectsFetcherHooks";
import { useTeamFetcher } from "../../utils/TeamsFetcherHooks";
/**
 * We don't need to worry about the current user is logged in or not,
 * because this container should be as a LoggedInRoute, so if user is not
 * logged in, then the route will go to the login page and not this component
 */
export const JoinTeamWithTokenContainer = () => {
  const navigate = useNavigate();
  const { token } = useParams();

  const { fetchMyTeamsAndUpdateStore } = useTeamFetcher();
  const { fetchMyProjectsAndUpdateStore } = useProjectFetcher();
  const { fetchMyDesignsAndUpdateStore } = useDesignFetcher();

  useEffect(() => {
    const tokenAction = async (token: string) => {
      try {
        const {
          data: { data: teamId },
        } = await TeamService.joinUserToTeamWithInviteToken(token);

        //Since we added the user to the team, we must refresh the teams's store and projects's store
        await fetchMyTeamsAndUpdateStore();
        await fetchMyProjectsAndUpdateStore();

        navigate(generatePath(ROUTES.TEAM_PROJECTS, { teamId }));
      } catch (error) {
        navigate(ROUTES.LOGIN, { state: { inviteLinkExpired: true } });
      }
    };

    if (token) {
      tokenAction(token);
    }
  }, [
    token,
    navigate,
    fetchMyTeamsAndUpdateStore,
    fetchMyProjectsAndUpdateStore,
    fetchMyDesignsAndUpdateStore,
  ]);

  return <div>Redirecting...</div>;
};
