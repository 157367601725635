import { useTranslation } from "react-i18next";

import { Typography } from "@mui/material";
import { memo } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import { Box, Link } from "../../common/components";
import { useTimeSince } from "../../common/hooks/useTimeSince";
import { Styles, palette, spacing } from "../../styles/theme";
import { ProjectWithDesigns } from "../../types";
import { ROUTES } from "../../utils/Constant";
import { mergeSx } from "../../utils/themeUtils";
import ProjectCardMenu from "./ProjectCardMenu";

export const PROJECT_CARD_MOBILE_MIN_WIDTH = "276px";
export const PROJECT_CARD_MOBILE_MAX_WIDTH = "572px";
export const PROJECT_CARD_DESKTOP_MIN_WIDTH = "276px";
export const PROJECT_CARD_DESKTOP_MAX_WIDTH = "572px";

const styles: Styles = {
  container: {
    background: palette.grey.lightBackground,
    borderRadius: "12px",
    minWidth: {
      xs: PROJECT_CARD_MOBILE_MIN_WIDTH,
      lg: PROJECT_CARD_DESKTOP_MIN_WIDTH,
    },
    maxWidth: {
      xs: PROJECT_CARD_MOBILE_MAX_WIDTH,
      lg: PROJECT_CARD_DESKTOP_MAX_WIDTH,
    },
    p: 1.5,
  },
  designContainer: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    gap: "12px",
    mb: 1.5,
  },
  design: {
    flexBasis: `calc(50% - 6px)`, //6px represent the half of the gap value, without the calc the box is to big
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    minHeight: "90px",
    maxHeight: "90px",
    minWidth: "120px",
    padding: "8px",
    background: "white",
    borderRadius: "8px",
    userSelect: "none",
    cursor: "pointer",
    ":hover": {
      border: `1px solid ${palette.grey.midGrey}`,
      "& .designName": {
        display: "block",
      },
    },
    ":active": {
      border: `1px solid ${palette.blue.secondary}`,
      "& .designName": {
        display: "block",
      },
    },
  },
  blankDesign: {
    flexBasis: `calc(50% - 6px)`, //6px represent the half of the gap value, without the calc the box is to big
    minHeight: "90px",
    height: "90px",
    minWidth: "120px",
    padding: "8px",
    userSelect: "none",
  },
  viewProjectTile: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "white",
    ":hover": {
      border: `1px solid ${palette.grey.midGrey}`,
      "&>span": {
        textDecoration: "underline",
      },
    },
    ":active": {
      border: `1px solid ${palette.blue.secondary}`,
    },
  },
  img: {
    maxHeight: { xs: "49px", lg: "49px" },
    userSelect: "none",
  },
};

const NUMBER_OF_DESIGN_PER_CARD = 3;

interface Props {
  project: ProjectWithDesigns;
}

const ProjectCard = ({ project }: Props) => {
  const { t } = useTranslation();
  const { timeSince } = useTimeSince(project.updatedAt);
  const navigate = useNavigate();

  const numberOfBlankDesign =
    NUMBER_OF_DESIGN_PER_CARD - Math.min(project.designs.length, 3);

  return (
    <Box sx={styles.container} data-test={"projectCard"}>
      <Box sx={styles.designContainer}>
        {project.designs.map((design) => (
          <Box
            sx={styles.design}
            key={design.id}
            onClick={() =>
              navigate(
                generatePath(ROUTES.STUDIO, {
                  projectId: project.id,
                  designId: design.id,
                })
              )
            }
          >
            <Box
              sx={{
                flexGrow: 1,
                display: "flex",
                alignItems: "center",
                gap: spacing.smallDesktop,
                py: "5px",
              }}
            >
              {design.images.map((url) => (
                <Box
                  component={"img"}
                  sx={{
                    ...styles.img,
                    content: `url(${url})`,
                  }}
                  key={url}
                />
              ))}
            </Box>
            <Typography
              variant="textSm"
              className="designName"
              sx={{ display: "none" }}
            >
              {design.name}
            </Typography>
          </Box>
        ))}
        <Box
          sx={mergeSx(styles.design, styles.viewProjectTile)}
          data-test="projectCard.addDesign"
          onClick={() =>
            navigate(generatePath(ROUTES.DESIGNS, { projectId: project.id }))
          }
        >
          <Typography variant="textSm" color={palette.blue.secondary}>
            {t(
              project.designs.length >= 1
                ? "project.viewAll"
                : "project.openProject"
            )}
          </Typography>
        </Box>
        {Array(...new Array(numberOfBlankDesign)).map((_, index) => (
          <Box sx={styles.blankDesign} key={`${index}-blank`} />
        ))}
      </Box>
      <Box display="flex" flexDirection="column" gap="5px">
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box sx={{ maxWidth: "calc(100% - 24px)", width: "100%" }}>
            <Link
              to={generatePath(ROUTES.DESIGNS, { projectId: project.id })}
              style={{
                maxWidth: "min-content",
                display: "flex",
                color: palette.grey.nearBlack,
              }}
            >
              <Typography
                variant="textSm"
                maxWidth="fit-content"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
                overflow="hidden"
                display="block"
              >
                {project.name ? project.name : t("project.project")}
              </Typography>
            </Link>
          </Box>
          <ProjectCardMenu project={project} />
        </Box>
        <Typography variant="textXs">{timeSince}</Typography>
      </Box>
    </Box>
  );
};

export default memo(ProjectCard);
