import Close from "@mui/icons-material/Close";
import { CircularProgress, DialogActions } from "@mui/material";
import _ from "lodash";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import Modal from "../Modal";
//import Button from "../../common/components/Button";
import Button from "@mui/material/Button";
import { PreviewGlassMagnifier } from "../../containers/StudioSidebarContainer/StudioRightSidebar/DecorationSidebar/PreviewGlassMagnifier";
import Message, { MessageTypes } from "../Message/Message";
import styles from "./addDesignToPreorderModal.module.scss";

const AddDesignToPreorderModal = (props) => {
  const { open, onClose, searchDesignFunction, onAddNewDesign } = props;

  const { t } = useTranslation();

  const [designId, setDesignId] = useState("");
  const [sizes, setSizes] = useState([]);
  const [designState, setDesignState] = useState(null);
  const [designFromBackend, setDesignFromBackend] = useState(null);
  const [productDefinition, setProductDefinition] = useState(null);
  const [isSearching, setIsSearching] = useState(false);
  const [isAdding, setisAdding] = useState(false);
  const [errorCode, setErrorCode] = useState(null);

  const resetSearch = useCallback(() => {
    setDesignId("");
    setSizes([]);
    setDesignState(null);
    setDesignFromBackend(null);
    setProductDefinition(null);
    setIsSearching(false);
    setisAdding(false);
    setErrorCode(null);
  }, [
    setDesignId,
    setSizes,
    setDesignState,
    setDesignFromBackend,
    setProductDefinition,
    setIsSearching,
    setisAdding,
    setErrorCode,
  ]);

  const onSizeChange = useCallback(
    (sizeName, sizeQty) => {
      setSizes((oldSizes) => {
        const newSizes = _.cloneDeep(oldSizes);

        const sizeToUpdate = newSizes.find((size) => size.name === sizeName);
        sizeToUpdate.qty = parseInt(sizeQty);

        return newSizes;
      });
    },
    [setSizes]
  );

  const onClickSearch = useCallback(
    async (e) => {
      e.preventDefault();

      setIsSearching(true);

      const designInformation = await searchDesignFunction(designId);

      console.debug("!!!designInformation", designInformation);

      if (designInformation?.error?.status) {
        setErrorCode(designInformation?.error?.status);
        setDesignFromBackend(null);
        setDesignState(null);
        setProductDefinition(null);
        setSizes([]);
      } else {
        setErrorCode(null);
        setDesignFromBackend(designInformation.design);
        setDesignState(designInformation.designState);
        setProductDefinition(
          designInformation.productDefinition.productDefinition
        );
        setSizes(
          designInformation.productDefinition.productDefinition.sizes.map(
            (size) => {
              return { name: size.name, qty: 0 };
            }
          )
        );
      }

      setIsSearching(false);
    },
    [
      designId,
      searchDesignFunction,
      setSizes,
      setDesignState,
      setDesignFromBackend,
      setProductDefinition,
      setIsSearching,
      setErrorCode,
    ]
  );

  const onClickAdd = useCallback(
    async (e) => {
      e.preventDefault();

      setisAdding(true);
      try {
        // const res = await onAddNewDesign(designId, sizes);
        await onAddNewDesign(designId, sizes);
      } catch (e) {
        //TODO: On error setErrorCode
      } finally {
        setisAdding(false);
        onClose();
        resetSearch();
      }
    },
    [designId, sizes, onAddNewDesign, onClose, resetSearch, setisAdding]
  );

  //Check if designID is a UUID to enable the search
  const searchDisabled = useMemo(() => {
    if (!designId) {
      return true;
    }

    const regexExp =
      /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

    const isValidUuidV4 = regexExp.test(designId);
    //const isValidUuidV4 = rvalidateUUID(designId);

    return !isValidUuidV4;
  }, [designId]);

  //Get the product definition for that item
  // const getProductDefinition = useCallback(async (family, product)=> {
  //   const { productDefinition } = await fetchProductDefinition(
  //     family,
  //     product
  //   );
  //   return productDefinition;
  // }, [fetchProductDefinition]);

  const availableSizes = useMemo(() => {
    if (!productDefinition) {
      return [];
    }
    return productDefinition.sizes;
  }, [productDefinition]);

  //Check if designID is a UUID to enable the search
  const addDesignDisabled = useMemo(() => {
    if (isAdding) {
      return true;
    }

    if (!productDefinition) {
      return true;
    }

    const qty = sizes.reduce((cumul, size) => cumul + parseInt(size.qty), 0);

    if (qty >= productDefinition.pricing.minQty) {
      return false;
    }
    return true;
  }, [sizes, productDefinition, isAdding]);

  return (
    <Modal show={open} className={styles.modal}>
      <Close
        className={styles.closeIcon}
        onClick={() => {
          resetSearch();
          onClose();
        }}
      />
      <div className={styles.container}>
        <h4 className={styles.title}>{t("preorder.add_design")}</h4>
        <p>{t("preorder.add_design_description")}</p>

        <div className={styles.searchDiv}>
          <input
            type="text"
            value={designId}
            className={styles.input}
            onChange={(e) => setDesignId(e.target.value || "")}
            placeholder={t("preorder.designId")}
          />
          <Button
            variant="contained"
            className={styles.button}
            onClick={onClickSearch}
            disabled={searchDisabled}
          >
            {t("preorder.search_design")}
          </Button>
          {/* <Button className={styles.button} onClick={onClickSearch} disabled={searchDisabled}>
            {t("preorder.search_design")}
          </Button> */}
          {/* <button className={styles.button} onClick={onClickSearch} disabled={searchDisabled}>
            {t("preorder.search_design")}
          </button> */}
        </div>
        <div className={styles.tableRow}>
          {errorCode && (
            <Message
              title={t(`preorder.errors.${errorCode}.title`)}
              message={t(`preorder.errors.${errorCode}.message`)}
              type={MessageTypes.ALERT}
              //messageIcon={<WarningAmberOutlinedIcon />}
              fullWidth={true}
            />
          )}
        </div>
        <div className={styles.searchResuldDiv}>
          {isSearching ? (
            <div className={styles.searching}>
              <div className={styles.loadingProgress}>
                <CircularProgress />
              </div>
            </div>
          ) : (
            <div className={styles.designResultContainer}>
              {designState ? (
                <div className={styles.designFound}>
                  <div className={styles.imgContainer}>
                    {designState.images.map((image) => (
                      <PreviewGlassMagnifier
                        imageUrl={image.url}
                        title={image.side}
                        key={image.side}
                        noBackgroundColor={true}
                      />
                      /*   <img
                        src={image.url}
                        alt={image.side}
                        className={styles.img}
                        key={image.side}
                     /> */
                    ))}
                  </div>
                  <div className={styles.cardActions}>
                    <div className={styles.dataLeft}>
                      <span className={styles.title}>
                        {designFromBackend.name}
                      </span>
                      {/* <span className={styles.time}>{updatedTime}</span> */}
                    </div>
                  </div>
                </div>
              ) : (
                <div className={styles.tableRow}>
                  <div className={styles.designNotFound}>
                    <p className="title">{t("preorder.no_result_found")}</p>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
        <div className={styles.sizeSelection}>
          <div className={styles.tableRow}>
            {/* {sizes.map((size) => ( */}
            {availableSizes?.map((availableSize) => (
              <div className={styles.sizeField} key={availableSize.name}>
                <div className={styles.sizeName}>
                  {/* <b style={{ textTransform: "uppercase" }}>{size.name}</b> */}
                  <b>{t(`cart.${availableSize.displayLabel}`)}</b>
                </div>

                <div className={styles.sizeQty} key={availableSize.name}>
                  <input
                    type="number"
                    min="0"
                    style={styles.editableSize}
                    // defaultValue={availableSize.qty}
                    value={
                      sizes.find((size) => size.name === availableSize.name)
                        ?.qty || 0
                    }
                    onChange={(event) =>
                      onSizeChange(availableSize.name, event.target.value)
                    }
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className={styles.tableRow}>
          <DialogActions className={styles.dialogActions}>
            <Button
              variant="contained"
              className={styles.button}
              onClick={onClickAdd}
              disabled={addDesignDisabled}
            >
              {t("preorder.add_design")}
            </Button>
          </DialogActions>
        </div>
      </div>
    </Modal>
  );
};

export default AddDesignToPreorderModal;
