import { Typography } from "@mui/material";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import { Box, Breadcrumbs, FlexColBox } from "../../common/components";
import { spacing } from "../../styles/theme";
import { useThemeBreakpoints } from "../../utils/themeUtils";
import { CheckoutBreadcrumbsSteps } from "./CheckoutTemplate";

const EmptyCart = () => {
  const { t } = useTranslation();
  const { isMobile } = useThemeBreakpoints();
  return (
    <Box
      px={{ xs: spacing.wrapperMobile, lg: spacing.wrapperDesktop }}
      pt={{ xs: spacing.wrapperMobile, lg: spacing.wrapperDesktop }}
      pb={{ xs: spacing.xlMobile, lg: spacing.xlDesktop }}
    >
      {!isMobile && (
        <Breadcrumbs steps={CheckoutBreadcrumbsSteps} firstStepAsBackButton />
      )}
      <FlexColBox
        mt={{ xs: spacing.regularMobile, lg: spacing.regularDesktop }}
        ml={{ xs: 0, lg: "128px" }}
        gap={{ xs: spacing.regularMobile, lg: spacing.regularDesktop }}
      >
        <Typography variant="h1">{t("checkout.title")}</Typography>
        <Typography variant="textRegular">
          {t("checkout.cart_empty")}
        </Typography>
      </FlexColBox>
    </Box>
  );
};

export default memo(EmptyCart);
