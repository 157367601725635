import _ from "lodash";
import { useCallback, useEffect } from "react";
import { useStore } from "react-redux";
import {
  Navigate,
  Outlet,
  RouteProps,
  generatePath,
  useLocation,
  useOutlet,
  useParams,
} from "react-router-dom";
import { AppContainer } from "../common/components";
import { useSelector } from "../common/hooks/useSelector";
import { MY_TEAMS_ACTION_ENUM } from "../common/reducers/myTeams";
import AuthService from "../service/authService";
import { UserInfo } from "../types";
import { useCart } from "../utils/CartHooks";
import { ROUTES } from "../utils/Constant";
import { useCurrency } from "../utils/Currencies";
import { useDesignFetcher } from "../utils/DesignsFetcherHooks";
import { useProjectFetcher } from "../utils/ProjectsFetcherHooks";
import { useTeamFetcher } from "../utils/TeamsFetcherHooks";
import { useUserInfoFetcher } from "../utils/UserHooks";

type Props = RouteProps & {
  wrappedByAppContainer?: boolean;
};

export const PrivateRoutes = (props: Props) => {
  const { wrappedByAppContainer } = props;
  const location = useLocation();
  const locationState = location.state;
  const token = AuthService.getJWT();
  const outlet = useOutlet();
  const currentUser = AuthService.getUserInfo() as any as UserInfo;
  const { dispatch } = useStore();
  const params = useParams();

  //When user will be logged in, userInfos will be set and valid
  //If not, then no point in calling the other APIs
  const userInfos = useSelector((state) => state.userInfo);

  //If userInfos is not set it can be because we are not logged in yet
  //or that the user is using the url directly withhout going through the login page
  //If it's the latter, so we must check if the user is logged in and set the userInfos in the store
  const { initUserInfo } = useUserInfoFetcher();
  useEffect(() => {
    initUserInfo();
  }, [dispatch, initUserInfo]);

  const { initCurrency, getCurrency } = useCurrency();
  useEffect(() => {
    initCurrency();
  }, [initCurrency]);

  const { fetchMyDesignsAndUpdateStore } = useDesignFetcher();
  const initDesigns = useCallback(async () => {
    if (!userInfos || _.isEmpty(userInfos) || !params.projectId) {
      return;
    }

    return fetchMyDesignsAndUpdateStore(params.projectId);
  }, [fetchMyDesignsAndUpdateStore, params.projectId, userInfos]);

  const { fetchMyProjectsAndUpdateStore } = useProjectFetcher();
  const { fetchMyTeamsAndUpdateStore, fetchAllTeamsAndUpdateStore } =
    useTeamFetcher();

  const initTeamsAndProjects = useCallback(async () => {
    if (!userInfos || _.isEmpty(userInfos) || !userInfos.id) {
      return;
    }

    //loading is set to true before the call of project because the sidenav need both teams and projects,
    //and we need to know when both are completed to show to result.
    //if we just call them back to back the loading indicator flicker
    dispatch({
      type: MY_TEAMS_ACTION_ENUM.UPDATE_MY_TEAMS_LOADING,
      payload: { loading: true },
    });

    //Fetch all teams if designer
    if (userInfos.isDesigner || userInfos.isAdmin) {
      await fetchAllTeamsAndUpdateStore();
    }
    fetchMyProjectsAndUpdateStore();
    fetchMyTeamsAndUpdateStore();
  }, [
    dispatch,
    fetchAllTeamsAndUpdateStore,
    fetchMyProjectsAndUpdateStore,
    fetchMyTeamsAndUpdateStore,
    userInfos,
  ]);

  const { fetchCartItemsAndUpdateStore } = useCart();
  const initCart = useCallback(async () => {
    if (!userInfos || _.isEmpty(userInfos) || !userInfos.id) {
      return;
    }

    return fetchCartItemsAndUpdateStore(getCurrency());
  }, [fetchCartItemsAndUpdateStore, userInfos, getCurrency]);

  useEffect(() => {
    initDesigns();
    initTeamsAndProjects();
    initCart();
  }, [initDesigns, initTeamsAndProjects, initCart]);

  const dateNow = new Date();
  //If there is no user, it should enter and token should be false so it will redirect to the login
  //If expired, send to login page
  if (!currentUser || (currentUser.exp ?? 0) * 1000 < dateNow.getTime()) {
    if (token) {
      if (outlet?.props) {
        return wrappedByAppContainer ? (
          <AppContainer>{outlet}</AppContainer>
        ) : (
          outlet
        );
      }
      return <Navigate to={ROUTES.HOME} />;
    } else {
      return (
        <Navigate
          to={{
            pathname: generatePath(
              params.token && !locationState?.inviteLinkExpired
                ? ROUTES.LOGIN_WITH_TOKEN
                : ROUTES.LOGIN,
              {
                token:
                  locationState?.inviteLinkExpired || !params.token
                    ? undefined
                    : params.token,
              }
            ),
          }}
        />
      );
    }
  }

  if (!outlet?.props) {
    return <Navigate to={ROUTES.HOME} />;
  }

  return wrappedByAppContainer ? (
    <AppContainer>
      <Outlet />
    </AppContainer>
  ) : (
    <Outlet />
  );
};
