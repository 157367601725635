import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import DarkTooltip from "../../../../common/components/DarkTooltip";
import styles from "../StudioLeftSidebarMenu.module.scss";

const SubMenuOption = (props) => {
  const { t } = useTranslation();
  const {
    onClick,
    display,
    label,
    labelKey,
    isSelected,
    canDelete,
    canHide,
    active,
    enabled = true,
    productName,
    reasonForBeingDisabled,
    onShow,
    onHide,
    onDelete,
    deprecated,
    deprecatedDesignItems,
    colorBleedingItems,
  } = props;

  const optionClassName = useMemo(() => {
    if (isSelected) {
      return classNames(
        styles.blankBoxSub,
        styles.blankBoxSelectable,
        styles.blankBoxSelected
      );
    } else if (!enabled) {
      return classNames(styles.blankBoxSub, styles.blankBoxDisabled);
    } else {
      return classNames(styles.blankBoxSub, styles.blankBoxSelectable);
    }
  }, [isSelected, enabled]);

  const labelDisplay = useMemo(() => {
    if (label) {
      return label;
    }

    if (labelKey) {
      return t(labelKey);
    }

    return "";
  }, [labelKey, label, t]);

  const useStyles = makeStyles({
    actionIcon: {
      marginLeft: 8,
      fill: "#CCCCCC",
      "&:hover": {
        fill: "#333333",
      },
    },
  });
  const classes = useStyles();

  return (
    <li className={styles.optionLine}>
      <div
        className={`${styles.optionDiv} ${optionClassName}`}
        onClick={onClick}
      >
        <div className={styles.optionTextWithIconContainer}>
          {display?.displayMethod === "icon" && display?.menuIconUrl && (
            <div className={styles.iconContainer}>
              <img alt="iconUrl" src={display.menuIconUrl} />
            </div>
          )}
          <div className={styles.optionTextContainer}>
            <span className={styles.optionText}> {labelDisplay}</span>
            {deprecatedDesignItems && deprecatedDesignItems.length !== 0 && (
              <span className={styles.deprecatedValueDot}></span>
            )}
            {colorBleedingItems && colorBleedingItems.length !== 0 && (
              <span className={styles.deprecatedValueDot}></span>
            )}
            {deprecated && <span className={styles.deprecatedValueDot} />}
            {!enabled && (
              <DarkTooltip
                title={t("specs.element_not_available", {
                  affectedElement: labelDisplay,
                  style: t(
                    `specs.${productName}.${reasonForBeingDisabled.disabledByStyle}`
                  ),
                  part: t(
                    `specs.${productName}.${reasonForBeingDisabled.disabledByPart}`
                  ),
                })}
                followCursor
              >
                <InfoOutlinedIcon fontSize="inherit" color="primary" />
              </DarkTooltip>
            )}
          </div>
        </div>
        <div className={styles.optionIcons}>
          {canDelete && (
            <DeleteOutlineIcon
              className={classes.actionIcon}
              onClick={onDelete}
            />
          )}
          {canHide && active && (
            <VisibilityIcon className={classes.actionIcon} onClick={onHide} />
          )}
          {canHide && !active && (
            <VisibilityOffIcon
              className={classes.actionIcon}
              onClick={onShow}
            />
          )}
        </div>
      </div>
    </li>
  );
};

export default SubMenuOption;
