import { Skeleton } from "@mui/material";
import { memo } from "react";
import { Box, ContentRepeater, FlexColBox } from "../../common/components";
import { spacing, Styles } from "../../styles/theme";

const styles: Styles = {
  centerContainer: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
    flex: "1",
    padding: {
      xs: `16px 16px ${spacing.xlMobile}`,
      lg: `${spacing.regularDesktop} ${spacing.largeDesktop} ${spacing.xlDesktop}`,
    },
    overflow: "auto",
    gap: { xs: spacing.regularMobile, lg: spacing.regularDesktop },
  },
};

const OrdersSkeleton = () => {
  return (
    <Box sx={styles.centerContainer}>
      <Skeleton height={40} width={300} variant="rounded" />
      <FlexColBox gap={"12px"}>
        <ContentRepeater repeatCount={7}>
          <Skeleton width={"100%"} height={"59px"} variant="rounded" />
        </ContentRepeater>
      </FlexColBox>
    </Box>
  );
};

export default memo(OrdersSkeleton);
