import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DesignService from "../../../service/DesignService";
import { useDesignFetcher } from "../../../utils/DesignsFetcherHooks";

import { DesignState } from "../../../types";
import styles from "./SelectedDesign.module.scss";

interface Props {
  design: DesignState;
}

export const SelectedDesign = (props: Props) => {
  const { t } = useTranslation();
  const {
    id,
    name,
    images,
    projectId,
    description: descriptionProps = "",
    design,
  } = props.design;
  const [description, setDescription] = useState("");

  useEffect(() => {
    setDescription(descriptionProps);
    // We only want to fetch it at the beginning

    // eslint-disable-next-line
  }, []);

  const { fetchMyDesignsAndUpdateStore } = useDesignFetcher();

  const updateDesigns = useCallback(async () => {
    fetchMyDesignsAndUpdateStore(projectId);
  }, [fetchMyDesignsAndUpdateStore, projectId]);

  const onRemoveFromPage = async () => {
    await DesignService.updateCollectable({
      collectable: false,
      designId: id,
    });
    updateDesigns();
  };

  const updateDescriptionInServer = _.debounce(
    (description) => {
      DesignService.changeDescription({ description, designId: id });
    },
    1000,
    { leading: false }
  );

  const onDescriptionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(e.target.value);
    updateDescriptionInServer(e.target.value);
  };

  return (
    <div className={styles.container}>
      <div className={styles.preview}>
        {images.map((image) => (
          <img
            src={image.url}
            alt={image.side}
            className={styles.img}
            key={image.side}
          />
        ))}
      </div>
      <div className={styles.designContent}>
        <div className={styles.topSection}>
          <span className={styles.designName}>
            {name || t("design.untitled_design")}
          </span>
          <div className={styles.removeDiv} onClick={onRemoveFromPage}>
            <span>{t("design.remove")}</span>
          </div>
        </div>
        <div className={styles.nameAndDescription}>
          <span className={styles.designDescription}>
            {t("products." + design.product.name + ".name")}
          </span>
          <span> - </span>
          <span className={styles.designDescription}>
            {t("products." + design.product.name + ".description")}
          </span>
        </div>
        <textarea
          value={description}
          onChange={onDescriptionChange}
          className={styles.descriptionInput}
          placeholder={t("design.enter_description")}
        />
      </div>
    </div>
  );
};
