import { memo } from "react";
import { useTranslation } from "react-i18next";
import teamBackgroundEN from "../../../../assets/images/next_steps_info_english.png";
import teamBackgroundFR from "../../../../assets/images/next_steps_info_french.png";
import { Box } from "../../../../common/components";
import { Styles } from "../../../../styles/theme";

const styles: Styles = {
  container: {
    borderRadius: "4px",
    maxWidth: 400,
    minHeight: 201,
    "&>img": {
      maxWidth: 400,
      width: "100%",
    },
  },
};

const NextStepInfo = () => {
  const { i18n } = useTranslation();
  const image = i18n.language === "fr" ? teamBackgroundFR : teamBackgroundEN;
  return (
    <Box sx={styles.container}>
      <img src={image} />
    </Box>
  );
};

export default memo(NextStepInfo);
