import { Skeleton, Typography } from "@mui/material";
import { Box, ContentRepeater, FlexColBox } from "../../../common/components";
import { palette, spacing, Styles } from "../../../styles/theme";
import { mergeSx } from "../../../utils/themeUtils";

const styles: Styles = {
  leftPanelSkeleton: {
    backgroundColor: { xs: undefined, lg: palette.lightGray },
    width: "100%",
  },
  input: {
    minHeight: "40px",
  },
};

export const ShippingAddressSkeleton = () => {
  const InputSkeleton = (
    <FlexColBox gap={"4px"} fullWidth>
      <Skeleton variant="text" sx={styles.leftPanelSkeleton} width={"100px"} />
      <Skeleton
        variant="text"
        sx={mergeSx(styles.leftPanelSkeleton, styles.input)}
        width={"100%"}
      />
    </FlexColBox>
  );

  return (
    <Box fullWidth display={"flex"} justifyContent={"flex-end"}>
      <FlexColBox
        gap={{ xs: spacing.groupingMobile, lg: spacing.groupingDesktop }}
        fullWidth
        maxWidth={{ xs: undefined, lg: 640 }}
      >
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          mb={"20px"}
          width={"100%"}
          alignItems={"center"}
        >
          <Typography variant="h2">
            <Skeleton
              variant="text"
              sx={styles.leftPanelSkeleton}
              width={200}
            />
          </Typography>
        </Box>

        <ContentRepeater repeatCount={2}>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            width={"100%"}
            alignItems={"center"}
            gap={"12px"}
          >
            <ContentRepeater repeatCount={2}>{InputSkeleton}</ContentRepeater>
          </Box>
        </ContentRepeater>

        <ContentRepeater repeatCount={5}>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            width={"100%"}
            alignItems={"center"}
            gap={"12px"}
          >
            {InputSkeleton}
          </Box>
        </ContentRepeater>

        <Box
          display={"flex"}
          justifyContent={"space-between"}
          width={"100%"}
          alignItems={"center"}
          gap={"12px"}
        >
          <ContentRepeater repeatCount={2}>{InputSkeleton}</ContentRepeater>
        </Box>
      </FlexColBox>
    </Box>
  );
};
